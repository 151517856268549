import React from 'react';
import { graphql } from 'react-relay';
import Query from '../Query';
import {
  populateChildren
} from './toolbox';

const QAllDiscussionsQuery = graphql`
  query QAllDiscussionsQuery($filter: DiscussionFilterInput, $paginate: PaginateInput!) {
    allDiscussions(filter: $filter, paginate: $paginate) {
      id
      data {
        id
        status
        title
        recipients
        dates {
          creation
          lastUpdate
        }
        allMessages {
          data {
            attachments {
              id
            }
          }
        }
        sender
        assignedTo
        priority
        collaborator {
          id
          firstName
          lastName
        }
      }
      pagination {
        total
        limit
        page
        pages      
      }
    } 
  }
`;

const QAllDiscussions = ({
  args,
  children,
  childDataProp,
  mockData
}) => {
  return (
    <Query
      query={QAllDiscussionsQuery}
      args={args}
      fetchPolicy="network"
      mockData={mockData}
    >
      {populateChildren(['allDiscussions'])(children, childDataProp)}
    </Query>
  );
};

export default QAllDiscussions;
