import { graphql } from 'react-relay';

const QLastActionQuery = graphql`
  query QLastActionQuery($lastActionFilter: LastActionFilterInput) {
    lastAction(filter: $lastActionFilter) {      
      id
      type
      label
      text
      author
      createdBy {
        id
        firstName
        lastName
      }
      createdAt
    }
  }
`;

export {
  QLastActionQuery
};
