import React from 'react';
import PropTypes from 'prop-types';
import { Loading } from '@@components';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

const Establishment = ({
  professionalInformation,
  hospitalDetail
}) => {
  return <>
    {
      professionalInformation
        ? <>
          {
            RA.isNotNilOrEmpty(hospitalDetail.name) && RA.isNotNilOrEmpty(hospitalDetail.city) && <>
              {`${hospitalDetail.city} 
        - 
        ${hospitalDetail.name}`} <br/>
            </>
          }
          {`${R.prop('specialty', professionalInformation)}`}
        </>
        : <Loading />
    }
  </>;
};

Establishment.propTypes = {
  professionalInformation: PropTypes.shape({
    Specialty: PropTypes.string
  }),
  hospitalDetail: PropTypes.shape({
    name: PropTypes.string,
    city: PropTypes.string
  })
};

export default Establishment;
