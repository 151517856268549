import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import dateToHumanFull from '@@lib/dateToHumanFull';
import { discussionBaseFilter, Roles } from '@@enum';
import QAdherent from '@@queries/QAdherent';
import * as R from 'ramda';
import { SelectOneOption } from '@@components/input';
import { iconFilterClose, iconFilterOpen, paperclipIcon } from '@@images';
import PriorityToggleFilter from '@@components/PriorityToggleFilter';
import CheckboxDropdown, {
  inputDataOptionsPropTypes
} from '@@components/CheckboxDropdown';
import { Pagination } from '@@components';
import {
  getAssignedSubscriberId,
  hasAssignedSubscriberId,
  hasAnyAttachment
} from '@@pages/message/components/utils';

const DiscussionList = ({
  userRole,
  items,
  pagination,
  onSelect,
  current,
  setPage,
  page,
  discussionTitle,
  allDiscussions,
  getAdherentNames,
  isOpenFilter,
  setIsOpenFilter,
  status,
  priorities,
  themes,
  groups,
  collaborators,
  updateFilterByName,
  filter,
  setFilter,
  messageNotified
}) => {
  const isManager = R.equals(userRole, Roles.MANAGER);
  const isGestionnaire = R.equals(userRole, Roles.GESTIONNAIRE);

  // Pill visible <=> at least 1 checkbox active
  const isActive = R.propEq('active', true);
  const isThemePillVisible = R.any(isActive)(themes);
  const isGroupPillVisible = isGestionnaire && R.any(isActive)(groups);
  const isCollaboratorPillVisible = isManager &&
    (R.any(isActive)(groups) || R.any(isActive)(collaborators));
  const isPillVisible = R.any(R.equals(true))([
    isThemePillVisible,
    isGroupPillVisible,
    isCollaboratorPillVisible
  ]);

  const $onSelect = (discussionId) => (e) => {
    e.preventDefault();
    onSelect(discussionId);
  };

  const handleInputs = (input) => (e) => {
    R.isEmpty(e.target.value) ? setFilter(R.dissoc(input, filter))
      : setFilter(R.mergeRight(filter, { [input]: e.target.value }));
  };

  const numberMine = pagination.total; // TODO
  const numberOpened = pagination.total; // TODO
  const numberClosed = pagination.total; // TODO
  // const labelMine = `Mes Demandes (${numberMine})`;
  // const labelOpened = `En Cours (${numberOpened})`;
  // const labelClosed = `Clos (${numberClosed})`;
  const labelMine = 'Mes Demandes';
  const labelOpened = 'En Cours';
  const labelClosed = 'Clos';
  const selectOptions = [
    { value: discussionBaseFilter.MINE, label: labelMine },
    { value: discussionBaseFilter.OPENED, label: labelOpened },
    { value: discussionBaseFilter.CLOSED, label: labelClosed }
  ];
  const isHighlightedOpened = R.equals(status, discussionBaseFilter.OPENED);
  const isHighlightedClosed = R.equals(status, discussionBaseFilter.CLOSED);

  const updateStatus = updateFilterByName('status');
  const updatePriorities = updateFilterByName('priority');
  const updateThemes = updateFilterByName('themes');
  const updateGroups = updateFilterByName('groups');
  const updateCollaborators = updateFilterByName('collaborators');

  const baseFilter = (
    <div className={'f-discussion-list-base-filter'}>
      {isGestionnaire && (
        <SelectOneOption
          onChange={ e => updateStatus(e.value) }
          options={ selectOptions }
          value={ R.find(R.propEq('value', status), selectOptions) }
          sortedByLabel={ false }
        />
      )}
      {!isGestionnaire && (
        <>
          <button
            onClick={() => updateStatus(discussionBaseFilter.OPENED)}
            className={classNames({
              'f-button-discussions-status-underlined': isHighlightedOpened,
              'f-button-discussions-status': !isHighlightedOpened
            })}
          >
            {labelOpened}
          </button>
          <button
            onClick={() => updateStatus(discussionBaseFilter.CLOSED)}
            className={classNames({
              'f-button-discussions-status-underlined': isHighlightedClosed,
              'f-button-discussions-status': !isHighlightedClosed
            })}
          >
            {labelClosed}
          </button>
        </>
      )}
    </div>
  );

  const iconFilter = isOpenFilter ? iconFilterOpen : iconFilterClose;
  const buttonToggleAdvancedFilters = (
    <button
      className={classNames('f-button-filter', {
        'f-button-filter-open': isOpenFilter,
        'f-button-filter-close': !isOpenFilter
      })}
      onClick={() => setIsOpenFilter(!isOpenFilter)}
    >
      <div className='f-discussion-list-filter-icon'
        style={{ backgroundImage: `url(${iconFilter})` }}
      >
        {isPillVisible && (
          <div className='f-discussion-list-filter-pill'/>
        )}
      </div>
      <div>
        {'Filtres'}
      </div>
    </button>
  );

  const advancedFilters = isOpenFilter && (
    <div className='f-discussion-list-advanced-filter-block'>
      {isGestionnaire && (
        <CheckboxDropdown
          title={'Toutes les Demandes'}
          inputData={ [{ options: groups, onChange: updateGroups }] }
          showPill={ isGroupPillVisible }
        />
      )}
      {isManager && (
        <CheckboxDropdown
          title={'Collaborateurs'}
          inputData={[
            { options: groups, onChange: updateGroups },
            { options: collaborators, onChange: updateCollaborators }
          ]}
          showPill={ isCollaboratorPillVisible }
        />
      )}
      <CheckboxDropdown
        title={'Thématiques'}
        inputData={ [{ options: themes, onChange: updateThemes }] }
        showPill={ isThemePillVisible }
      />
      <PriorityToggleFilter
        priorities={ priorities }
        onChange={ updatePriorities }
      />
      <div className='f-discussion-list-divider'/>
    </div>
  );

  const discussionToJSX = discussion => {
    const hasAttachments = hasAnyAttachment(discussion);
    const unRead = R.find(R.propEq('discussionId', discussion.id), messageNotified);
    const classes = classNames('f-messaging-discussions-item', {
      'f-messaging-discussions-item-current': R.equals(discussion.id, current)
    });
    return (
      <div key={discussion.id} className={classes}
        onClick={$onSelect(discussion.id)}
      >
        {hasAttachments && (
          <div className='f-discussion-list-icon-paperclip'
            style={{ backgroundImage: `url(${paperclipIcon})` }}/>
        )}
        {hasAssignedSubscriberId(discussion) && (
          <QAdherent args={{ id: getAssignedSubscriberId(discussion) }}>
            {({ adherentInfo }) => (
              <>
                {unRead && <div className="f-discussion-list-unread-pill"/>}
                <div className="f-messaging-discussions-item-title">
                  {getAdherentNames(adherentInfo.lastName, adherentInfo.firstName)}
                </div>
              </>
            )}
          </QAdherent>
        )}
        <div className="f-messaging-discussions-item-date">
          <div className={`font-bold ${discussion.id === current ? 'text-white' : 'text-navy-blue'}`}>
            {discussionTitle(discussion.title)}
          </div>
          <div className="my-1">
            {discussion.collaborator && (
              <>
                {'Géré par'} <span className="font-bold">{R.propOr('', 'firstName', discussion.collaborator)}</span>
              </>)
            }
          </div>
          {dateToHumanFull(discussion.dates.creation)}
        </div>
      </div>
    );
  };

  return (
    <div>

      <div className='f-discussion-list-base-filter-block'>
        { baseFilter }
        { buttonToggleAdvancedFilters }
      </div>

      { advancedFilters }

      <div className='flex flex-col gap-4 my-2'>
        { R.map(discussionToJSX)(items) }
      </div>

      {
        R.gt(pagination.total, pagination.limit) &&
        <Pagination
          onChange={setPage}
          current={pagination.page}
          total={pagination.total}
          pageSize={pagination.limit}
        />
      }
    </div>
  );
};

DiscussionList.propTypes = {
  userRole: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      dates: PropTypes.shape({
        creation: PropTypes.string.isRequired,
        lastUpdate: PropTypes.string.isRequired
      })
    })
  ),
  pagination: PropTypes.shape({
    total: PropTypes.number,
    limit: PropTypes.number,
    page: PropTypes.number,
    pages: PropTypes.number
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
  current: PropTypes.string,
  getAdherentNames: PropTypes.func.isRequired,
  isOpenFilter: PropTypes.bool.isRequired,
  setIsOpenFilter: PropTypes.func.isRequired,
  status: PropTypes.oneOf([
    discussionBaseFilter.OPENED,
    discussionBaseFilter.CLOSED,
    discussionBaseFilter.MINE]).isRequired,
  updateFilterByName: PropTypes.func.isRequired,
  priorities: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number.isRequired,
    tag: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired
  })).isRequired,
  themes: inputDataOptionsPropTypes,
  groups: inputDataOptionsPropTypes,
  collaborators: inputDataOptionsPropTypes
};

export default DiscussionList;
