import React from 'react';
import { graphql } from 'react-relay';
import Query from '../Query';
import {
  populateChildren
} from './toolbox';

export const QAdherentsWithPaginationQuery = graphql`
  query QAdherentsWithPaginationQuery($paginate: PaginateInput, $filter: AdherentFilterInput){
    adherentsWithPagination(paginate: $paginate, filter: $filter)  {
      data {
        id
        firstName
        lastName
        maidenName
        onBoarded
        familyStatus
        birthDate
        insee
        contract
      }
      pagination {
        total
        limit
        page
        pages      
      }
    } 
  }
`;

const QAdherentsWithPagination = ({
  args,
  children,
  childDataProp
}) => {
  return (
    <Query
      query={QAdherentsWithPaginationQuery}
      args={args}
      fetchPolicy="network"
    >
      {populateChildren(['adherentsWithPagination'])(children, childDataProp)}
    </Query>
  );
};

export default QAdherentsWithPagination;
