import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { Page } from '@@components';
import Home from './Home';
import { logoMessaging } from '@@images';
const R = require('ramda');
const paths = (root) => (k) => {
  const config = {
    discussion: {
      viewPath: (discussionId) => {
        if (R.isNil(discussionId)) return `${root}`;
        return `${root}/${discussionId}`;
      },
      newMessagePath: `${root}/ouvrir`,
      cancelPath: root
    }
  };

  if (R.isNil(k)) return config;
  return R.prop(k, config);
};

const index = ({
  history,
  match
}) => {
  const path = v => `${match.url}${R.when(R.isNil, R.always(''), v)}`;
  const pathConfig = paths(match.url);
  return (
    <Page className="flex flex-col" svgLogo={logoMessaging}>
      <Switch>
        <Route exact path={pathConfig('discussion').viewPath('')} render={({ match }) => <Home pathConfig={pathConfig('discussion')} match={match} history={history} />} />
        <Route exact path={pathConfig('discussion').viewPath(':discussionId')} render={({ match }) => <Home pathConfig={pathConfig('discussion')} match={match} history={history} />} />
        < Redirect to={path()} />
      </Switch>
    </Page>
  );
};

export default index;
