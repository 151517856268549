const discussionThemesInputValue = [
  {
    value: 'BOACTION-05',
    label: 'Adhésion / modification',
    keys: [
      'BOACTION-05',
      'BOACTION-05-01',
      'BOACTION-05-02',
      'BOACTION-05-03'
    ],
    active: false,
    group: 'GroupeGestion'
  },
  {
    value: 'ACTION-10',
    label: 'Autres',
    keys: [
      'ACTION-10'
    ],
    active: false,
    group: 'GroupeGestion'
  },
  {
    value: 'ACTION-06',
    label: 'Cotisations',
    keys: [
      'ACTION-06',
      'ACTION-06-01',
      'ACTION-06-02',
      'ACTION-06-03',
      'ACTION-06-04',
      'ACTION-06-05'
    ],
    active: false,
    group: 'GroupeCotisations'
  },
  {
    value: 'ACTION-05',
    label: 'Demande de documents / attestations',
    keys: [
      'ACTION-05'
    ],
    active: false,
    group: 'GroupeGestion'
  },
  {
    value: 'ACTION-01',
    label: 'Modification des informations personnelles',
    keys: [
      'ACTION-01',
      'ACTION-01-01',
      'ACTION-01-02',
      'ACTION-01-03',
      'ACTION-01-04',
      'BOACTION-02'
    ],
    active: false,
    group: 'GroupeGestion'
  },
  {
    value: 'ACTION-02',
    label: 'Modification des informations professionnelles',
    keys: [
      'ACTION-02',
      'ACTION-02-01',
      'ACTION-02-02',
      'ACTION-02-03',
      'ACTION-02-04',
      'ACTION-02-05'
    ],
    active: false,
    group: 'GroupeGestion'
  },
  {
    value: 'ACTION-03',
    label: 'Modification du contrat',
    keys: [
      'ACTION-03',
      'ACTION-03-01',
      'ACTION-03-02',
      'ACTION-03-03',
      'ACTION-03-04',
      'ACTION-03-05',
      'ACTION-03-06',
      'ACTION-03-07'
    ],
    active: false,
    group: 'GroupeGestion'
  },
  {
    value: 'BOACTION-04',
    label: 'Nouvelle proposition',
    keys: [
      'BOACTION-04',
      'BOACTION-04-01',
      'BOACTION-04-02'
    ],
    active: false,
    group: 'GroupeGestion'
  },
  {
    value: 'BOACTION-01-02',
    label: 'N° d\'adhérent oublié',
    keys: [
      'BOACTION-01-02'
    ],
    active: false,
    group: 'GroupeGestion'
  },
  {
    value: 'BOACTION-01-01',
    label: 'Problème de connexion',
    keys: [
      'BOACTION-01-01'
    ],
    active: false,
    group: 'GroupeGestion'
  },
  {
    value: 'ACTION-07',
    label: 'Réclamations',
    keys: [
      'BOACTION-03-01',
      'BOACTION-03-02',
      'ACTION-07',
      'ACTION-07-01',
      'ACTION-07-02',
      'ACTION-07-03',
      'ACTION-07-04',
      'ACTION-07-05'
    ],
    active: false,
    group: 'GroupeReclamations'
  },
  {
    value: 'BOACTION-07',
    label: 'Sinistres',
    keys: [
      'BOACTION-07',
      'BOACTION-07-01',
      'BOACTION-07-02',
      'BOACTION-07-03',
      'BOACTION-07-04',
      'BOACTION-07-05',
      'BOACTION-07-06'
    ],
    active: false,
    group: 'GroupeSinistres'
  },
  {
    value: 'ACTION-04',
    label: 'Sinistres prévoyance',
    keys: [
      'ACTION-04',
      'ACTION-04-01',
      'ACTION-04-02',
      'ACTION-04-03',
      'ACTION-04-04',
      'ACTION-04-05',
      'ACTION-04-06'
    ],
    active: false,
    group: 'GroupeSinistres'
  },
  {
    value: 'BOACTION-06',
    label: 'Vie du contrat',
    keys: [
      'BOACTION-06',
      'BOACTION-06-01',
      'BOACTION-06-02',
      'BOACTION-06-03',
      'BOACTION-06-04',
      'BOACTION-06-05',
      'BOACTION-06-06'
    ],
    active: false,
    group: 'GroupeGestion'
  }
];

export default discussionThemesInputValue;
