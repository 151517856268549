import React from 'react';
import { } from '@@queries/QAdherentCivilStatus';
import { QDiscussionQuery } from '@@queries/QDiscussion';
import { CloseDiscussioMutation } from '@@formsMutations';
import useForms from '@@formsHook';

const R = require('ramda');
const { fetchQuery } = require('react-relay');
const { useRelayEnvironment } = require('react-relay');

export const useDiscussion = (id, data) => {
  const environment = useRelayEnvironment();
  const [discussion, setDiscussion] = React.useState({});
  const closeDiscussion = useForms('closeDiscussion');
  const idRef = React.useRef();
  const dataRef = React.useRef();

  React.useEffect(() => {
    if (R.equals(idRef.current, id)) return;
    idRef.current = id;
    if (R.equals(dataRef.current, data)) return;
    dataRef.current = data;
    if (R.contains('discussion', data), getDiscussion({ id })); // fixme
  }, [id, data]);

  const getDiscussion = async () => {
    const { allDiscussions } = await fetchQuery(environment, QDiscussionQuery, { discussionFilter: { id } }).toPromise();
    setDiscussion(R.path(['data', 0], allDiscussions));
  };

  const onCloseDiscussion = () => {
    closeDiscussion.onFormsSubmit({ discussionId: id });
  };

  return {
    discussion,
    onCloseDiscussion
  };
};

export default useDiscussion;
