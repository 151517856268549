/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QAdherentQueryVariables = {|
  id: string
|};
export type QAdherentQueryResponse = {|
  +adherentInfo: ?{|
    +id: ?string,
    +firstName: ?string,
    +lastName: ?string,
    +maidenName: ?string,
    +onBoarded: ?boolean,
    +familyStatus: ?string,
    +birthDate: ?string,
    +insee: ?string,
  |}
|};
export type QAdherentQuery = {|
  variables: QAdherentQueryVariables,
  response: QAdherentQueryResponse,
|};
*/


/*
query QAdherentQuery(
  $id: ID!
) {
  adherentInfo(id: $id) {
    id
    firstName
    lastName
    maidenName
    onBoarded
    familyStatus
    birthDate
    insee
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "Adherent",
    "kind": "LinkedField",
    "name": "adherentInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "maidenName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "onBoarded",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "familyStatus",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "birthDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "insee",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QAdherentQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QAdherentQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a4a83afea8c2355d7519a8365500588c",
    "id": null,
    "metadata": {},
    "name": "QAdherentQuery",
    "operationKind": "query",
    "text": "query QAdherentQuery(\n  $id: ID!\n) {\n  adherentInfo(id: $id) {\n    id\n    firstName\n    lastName\n    maidenName\n    onBoarded\n    familyStatus\n    birthDate\n    insee\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c2d52ea3f846fb91bd340cd5c49e0fee';

module.exports = node;
