import Address from './Address';
import Text from './Text';
import TextArea from './TextArea';
import Email from './Email';
import EmailsWithType from './EmailsWithType';
import File from './File';
import Files from './Files';
import Hidden from './Hidden';
import Nic from './Nic';
import Number from './Number';
import Radio from './Radio';
import Rib from './Rib';
import Switch from './Switch';
import Date from './Date';
import PhoneNumbersWithType from './PhoneNumbersWithType';
import Select from './Select';
import Password from './Password';
import UserContactList from './UserContactList';

export default {
  address: Address,
  text: Text,
  textarea: TextArea,
  email: Email,
  emailsWithType: EmailsWithType,
  file: File,
  files: Files,
  hidden: Hidden,
  nic: Nic,
  number: Number,
  radio: Radio,
  password: Password,
  phoneNumbersWithType: PhoneNumbersWithType,
  rib: Rib,
  select: Select,
  date: Date,
  switch: Switch,
  userContactList: UserContactList
};
