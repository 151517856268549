import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { adherentCheck, editLogo } from '@@images';
import { SubThemeBlock } from './';
import {
  Address,
  Contact,
  Establishment,
  ExerciceMode,
  Family,
  Identity,
  LiberalActivity,
  SocialNumber,
  Specialty,
  Status
} from './subTheme';
import { Link } from 'react-router-dom';
import { useAdherent } from '@@hooks';
import { getLabel, typologyEq } from '../utils';
import QAdherentDocumentInfo from '@@queries/QAdherentDocumentInfo';
import { DocumentLink, Pagination, Loading } from '@@components';
import { typologies } from '@@enum';
import * as RA from 'ramda-adjunct';
import * as R from 'ramda';
import { useFetchQuery } from '@@hooks/useReferences';
import { QLastActionQuery } from '@@queries/QLastAction';
import LastEditedHeader from '@@pages/adherent/components/LastEditedHeader';
import { filterActiveContracts } from '@@lib/contracts';

const AdherentFrame = ({
  subscriberId,
  formatSocialNumber,
  adherentInfo,
  pathConfig
}) => {
  const [adherentTypologies, setAdherentTypologies] = React.useState({});
  const [page, setPage] = React.useState(1);

  const { adherent } = useAdherent(subscriberId, [
    'civilStatus',
    'contact',
    'professionalInformation',
    'contracts'
  ]);
  const activeContracts = R.compose(
    filterActiveContracts,
    R.propOr([], 'contracts')
  )(adherent);

  useEffect(() => {
    if (RA.isNotNil(activeContracts)) {
      setAdherentTypologies({
        isPHHUTypology: typologyEq(typologies.PHHU, activeContracts),
        isLiberalTypology: typologyEq(typologies.LIBERAL, activeContracts),
        isInternTypology: typologyEq(typologies.INTERNE, activeContracts)
      });
    }
  }, [activeContracts]);

  const hospitalDetail = R.pathOr({}, ['professionalInformation', 'hospital', 'detail'], adherent);

  const { data: dataLastAction } = useFetchQuery(QLastActionQuery, {
    lastActionFilter: {
      customerId: subscriberId,
      type: 'BO_UPDATE_SITUATION'
    }
  });

  const personalSubThemes = [
    {
      title: 'identité',
      children: <Identity civilStatus={adherent.civilStatus}/>
    },
    {
      title: 'coordonnées',
      children: <Contact contact={adherent.contact} />
    },
    {
      title: 'adresse',
      children: <Address contact={adherent.contact} />
    },
    {
      title: 'situation familiale',
      children: <Family
        familyStatus={R.propOr('', 'familyStatus', adherentInfo)} />
    },
    {
      title: 'n° sécurité sociale',
      children: <SocialNumber
        formatedSocialNumber={formatSocialNumber(R.propOr('', 'insee', adherentInfo))} />
    }
  ];

  const phhuProfessionalSubThemes = [
    {
      title: 'statut',
      children: <Status
        contracts={activeContracts}
        isPHHUTypology={adherentTypologies.isPHHUTypology} />
    },
    {
      title: 'établissement',
      children: <Establishment
        professionalInformation={adherent.professionalInformation}
        hospitalDetail={hospitalDetail} />
    },
    {
      title: 'Mode d\'exercice',
      children: <ExerciceMode contracts={activeContracts} />
    },
    {
      title: 'Activité libérale',
      children: <LiberalActivity
        contracts={activeContracts}
        isPHHUTypology={adherentTypologies.isPHHUTypology}
        isLiberalTypology={adherentTypologies.isLiberalTypology} />
    }
  ];

  const liberalProfessionalSubThemes = [
    {
      title: 'statut',
      children: <Status
        contracts={activeContracts}
        isPHHUTypology={adherentTypologies.isPHHUTypology} />
    },
    {
      title: 'spécialité',
      children: <Specialty
        professionalInformation={adherent.professionalInformation} />
    }
  ];

  const internProfessionalSubThemes = [
    {
      title: 'statut',
      children: <Status
        contracts={activeContracts}
        isPHHUTypology={adherentTypologies.isPHHUTypology} />
    },
    {
      title: 'Mode d\'exercice',
      children: <ExerciceMode contracts={activeContracts} />
    }
  ];

  const getDocumentDownloadUrl = ({
    fileId,
    fileSource,
    fileName
  }) => (
    `/documents/file/download/${encodeURIComponent(fileId)}?` +
    `source=${encodeURIComponent(fileSource)}` +
    `&fileName=${encodeURIComponent(fileName)}`
  );
  const documentPaginationLimit = 10;

  const adherentFullName = `${R.pathOr('', ['civilStatus', 'lastName'], adherent)} ${R.pathOr('', ['civilStatus', 'maidenName'], adherent)} ${R.pathOr('', ['civilStatus', 'firstName'], adherent)}`;

  return (
    <div className="w-2/3 p-6 bg-bg gap-4 flex flex-col">
      {
        R.isNil(adherent.civilStatus) ? <Loading />
          : <div className="flex justify-between">
            <div>
              <div className="flex">
                <div className="font-bold text-xl text-navy-blue mr-2">
                  {`${R.path(['civilStatus', 'firstName'], adherent)} ${R.path(['civilStatus', 'lastName'], adherent)} `}
                </div>
                {adherentInfo.onBoarded && <img src={adherentCheck} alt="adherent-check" />}
              </div>
              <LastEditedHeader lastActionData={dataLastAction} />
            </div>
            <Link
              to={`/messagerie/ouvrir?subscriberId=${subscriberId}&fullName=${adherentFullName}`}
              className="f-button-sm f-button-coral-reverse-bg"
            >
              {'Envoyer un message'}
            </Link>
          </div>
      }
      <div>
        <div className="f-block flex-col p-6">
          <div className="flex justify-between w-full">
            <h3>{'situation personnelle'}</h3>
            <Link
              className="cursor-pointer f-block-edit-btn"
              to={pathConfig.editPersonalPath(subscriberId)}
            >
              <img src={editLogo} alt={'Edit icon'}/>
            </Link>
          </div>
          {
            personalSubThemes.map(({ title, children }) => (
              <SubThemeBlock key={title} title={title}>
                {children}
              </SubThemeBlock>
            ))
          }
        </div>
        <div className="f-block flex-col p-6 mt-4">
          <div className="flex justify-between w-full">
            <h3>{'situation professionnelle'}</h3>
            <Link
              className="cursor-pointer f-block-edit-btn"
              to={pathConfig.editProfessionalPath(subscriberId)}
            >
              <img src={editLogo} alt={'Edit icon'}/>
            </Link>
          </div>
          {
            adherentTypologies.isPHHUTypology &&
              phhuProfessionalSubThemes.map(({ title, children }) => (
                <SubThemeBlock key={title} title={title}>
                  {children}
                </SubThemeBlock>
              ))
          }
          {
            !adherentTypologies.isPHHUTypology && adherentTypologies.isLiberalTypology &&
            liberalProfessionalSubThemes.map(({ title, children }) => (
              <SubThemeBlock key={title} title={title}>
                {children}
              </SubThemeBlock>
            ))
          }
          {
            !adherentTypologies.isPHHUTypology && adherentTypologies.isInternTypology &&
            internProfessionalSubThemes.map(({ title, children }) => (
              <SubThemeBlock key={title} title={title}>
                {children}
              </SubThemeBlock>
            ))
          }
        </div>
        <div className="f-block flex-col p-6 mt-4">
          <div className="flex justify-between w-full">
            <h3>{'Documents Envoyés'}</h3>
          </div>
          <div className="mt-3">
            <QAdherentDocumentInfo args={{
              subscriberId,
              paginate: { page, limit: documentPaginationLimit }
            }}>
              {({ documentsByAdherent }) => {
                const documents = R.propOr([], 'data', documentsByAdherent);
                const pagination = R.propOr([], 'pagination', documentsByAdherent);
                const showPagination = R.gt(R.propOr(0, 'total', pagination), documentPaginationLimit);
                return <>
                  {
                    documents && (
                      documents.map(document => {
                        const documentTypeLabel = R.compose(
                          getLabel('documentType'),
                          R.propOr('', 'type')
                        )(document);
                        return (
                          <div key={R.propOr('', 'fileId', document)}>
                            <div className={'mb-1'}>{documentTypeLabel}</div>
                            <DocumentLink
                              title={R.propOr('', 'title', document)}
                              link={getDocumentDownloadUrl(document)}
                            />
                          </div>
                        );
                      })
                    )}
                  {/* TODO styliser le composant de pagination dès livraison des assets */}
                  {
                    showPagination && (
                      <Pagination
                        onChange={setPage}
                        current={R.propOr(0, 'page', pagination)}
                        total={R.propOr(0, 'total', pagination)}
                        pageSize={R.propOr(0, 'limit', pagination)}
                      />
                    )
                  }
                </>;
              }}
            </QAdherentDocumentInfo>
          </div>
        </div>
      </div>
    </div>
  );
};

AdherentFrame.propTypes = {
  subscriberId: PropTypes.string.isRequired,
  formatSocialNumber: PropTypes.func.isRequired,
  adherentInfo: PropTypes.shape({
    onBoarded: PropTypes.bool.isRequired,
    familystatus: PropTypes.string,
    insee: PropTypes.string
  }).isRequired,
  pathConfig: PropTypes.object.isRequired
};

export default AdherentFrame;
