import React from 'react';
import Accounts from './accounts';
import { Menu } from '@@components/elements';
import Me from '../account';
import Status from './status';

const menuAdminItems = [
  {
  //   label: 'Comptes', path: '/accounts', component: Accounts, icon: 'menu-dashboard.svg', showInMenu: true
  // }, {
    label: 'Statut', path: '/status', component: Status, icon: 'menu-dashboard.svg', showInMenu: true
  }, {
    label: 'Mon profil', path: '/monprofil', component: Me
  }];

const MenuAdmin = () => (
  <Menu items={menuAdminItems}/>
);

export default MenuAdmin;

export {
  menuAdminItems
};
