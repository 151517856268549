import React from 'react';
import { cornerYellowUl, cornerTurquoiseDr } from '@@images';
import { Link } from 'react-router-dom';
import * as R from 'ramda';

const SearchAdherentBlock = () => {
  const [adherentinputValue, setAdherentinputValue] = React.useState({});

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    const mapValue = R.cond([
      [R.equals('firstName'), R.always({ lastName: R.propOr('', 'lastName', adherentinputValue), firstName: value })],
      [R.equals('lastName'), R.always({ firstName: R.propOr('', 'firstName', adherentinputValue), lastName: value })],
      [R.equals('id'), R.always({ id: value })]
    ])(name);
    setAdherentinputValue(mapValue);
  };

  const mapQueryParams = R.compose(
    R.join('&'),
    R.map(val => `${R.head(val)}=${R.last(val)}`),
    R.toPairs
  );

  return <>
    <div className="f-block-filter mt-8">
      <img className="f-filter-corner f-filter-corner-downRight" src={cornerTurquoiseDr}/>
      <img className="f-filter-corner f-filter-corner-upLeft" src={cornerYellowUl}/>
      <h3 className="mt-8">
        {'Rechercher un adhérent'}
      </h3>
      <div className="f-block-filter-input">
        <div className="w-3/10">
          <input
            placeholder="Nom"
            className="f-input"
            name="lastName"
            onChange={handleInputChange}
          />
        </div>
        <div className="w-3/10">
          <input
            placeholder="Prénom"
            className="f-input"
            name="firstName"
            onChange={handleInputChange}
          />
        </div>
        <div className="text-steel font-semibold text-xl">
          {'ou'}
        </div>
        <div className="flex w-3/10">
          <input
            placeholder="N°d'adhérent"
            className="f-input"
            name="id"
            onChange={handleInputChange}
          />
        </div>
      </div>
      <Link
        className="f-button-sm w-1/4 mt-5"
        to={`/adherent?${mapQueryParams(adherentinputValue)}`}
      >
        {'Rechercher'}
      </Link>
    </div>
  </>;
};

export default SearchAdherentBlock;
