import { DatePicker, ToggleButtons, SearchInputWithLogo } from '@@components';
import ColoredIcon from '@@components/ColoredIcon';
import { arrowDown, arrowUp, stealSearchLogo } from '@@images';
import classNames from 'classnames';
import { debounce } from 'lodash';
import * as R from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';

const SearchBlock = ({
  setAdherentFilter,
  totalAdherent,
  adherentFilter
}) => {
  const [isOpenFilter, setIsOpenFilter] = React.useState(false);

  const delayedHandleChange = debounce((eventData, prop) => {
    // si la recherche multicritère est renseigné, on remet à zéro les 3 props qui sont géré par l'input multicritère
    if (R.equals('multiProps', prop)) {
      setAdherentFilter(R.mergeRight(adherentFilter, {
        firstName: '',
        lastName: '',
        id: '',
        multiProps: eventData
      }));
      // si non on envoi toute les props
    } else {
      setAdherentFilter(R.mergeRight(adherentFilter, { [prop]: eventData }));
    }
  }, 1000);

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    delayedHandleChange(value, name);
  };

  const handleBirthDateInputChange = (val) => {
    setAdherentFilter(R.mergeRight(adherentFilter, { birthDate: val }));
  };

  const handleOnBoardedInputChange = (val) => {
    setAdherentFilter(R.mergeRight(adherentFilter, { onBoarded: val }));
  };

  const onResetMultiPropInput = () => setAdherentFilter(R.mergeRight(adherentFilter, { multiProps: '' }));

  const togglerOptions = [{
    value: 'false',
    label: 'non'
  }, {
    value: 'true',
    label: 'oui'
  }];

  return (
    <div className="mt-4">
      <SearchInputWithLogo
        onDelete={onResetMultiPropInput}
        onChange={handleInputChange}
        placeholder="Recherche par nom ou n°adhérent"
        name="multiProps"
        logo={stealSearchLogo}
        stateValue={adherentFilter.multiProps}
      />
      <div className="flex items-center justify-between my-2">
        <div className="flex flex-col">
          <div className='mb-1'>
            {`${totalAdherent} résultats`}
          </div>
          <button
            className="flex items-center text-xs uppercase font-bold text-navy-blue mt-1"
            onClick={() => {
              if (R.equals('ASC', adherentFilter.sort)) {
                setAdherentFilter(R.mergeRight(adherentFilter, { sort: 'DESC' }));
              } else {
                setAdherentFilter(R.mergeRight(adherentFilter, { sort: 'ASC' }));
              }
            }}
          >
            {'noms'} <ColoredIcon className='ml-1' icon={R.equals('ASC', adherentFilter.sort) ? arrowDown : arrowUp} height={10} width={10} />
          </button>
        </div>
        <button
          className={classNames('f-button-filter', {
            'f-button-filter-open': isOpenFilter,
            'f-button-filter-close': !isOpenFilter
          })}
          onClick={() => setIsOpenFilter(!isOpenFilter)}
        >
          <div className={classNames('p-2 font-semibold', {
            'f-button-filter-open text-dark-grey': isOpenFilter,
            'f-button-filter-close text-steel': !isOpenFilter
          })} >
            {'Recherche avancée'}
          </div>
        </button>
      </div>
      {
        isOpenFilter && <div className="border-b-2 mb-4">
          <SearchInputWithLogo
            onChange={handleInputChange}
            placeholder="Recherche par numéro de SS"
            name="insee"
            logo={stealSearchLogo}
          />
          <div className="my-4">
            <SearchInputWithLogo
              onChange={handleInputChange}
              placeholder="Recherche par numéro de contrat"
              name="contract"
              logo={stealSearchLogo}
            />
            <div className="flex mt-4">
              <div className="w-1/2 pr-1">
                <div className="f-block-field-label mb-1">
                  {'Date de naissance'}
                </div>
                <DatePicker
                  onChange={handleBirthDateInputChange}
                  value={adherentFilter.birthDate}
                  format={'dd/MM/yyyy'}
                />
              </div>
              <div className="w-1/2 pl-1">
                <div className="f-block-field-label mb-1">
                  {'Adhérent onboardé'}
                </div>
                <ToggleButtons
                  itemClassName={'w-toggle-adherent-btn'}
                  onClick={handleOnBoardedInputChange}
                  options={togglerOptions}
                  value={adherentFilter.onBoarded}
                />
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

SearchBlock.propTypes = {
  totalAdherent: PropTypes.number,
  adherentFilter: PropTypes.shape({
    multiProps: PropTypes.string,
    insee: PropTypes.string,
    contract: PropTypes.string,
    birthDate: PropTypes.string,
    onBoarded: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    sort: PropTypes.string
  }).isRequired,
  setAdherentFilter: PropTypes.func.isRequired
};

export default SearchBlock;
