import React from 'react';
import { graphql } from 'react-relay';
import Query from '../Query';
import {
  populateChildren
} from './toolbox';

const QStatusQuery = graphql`
  query QStatusQuery {
    status {
        ok
        error
        resume {
            allServicesAvailables
            details {
                service
                ok
                reports
            }
        }
    }
  }
`;

const QStatus = ({
  args,
  children,
  childDataProp,
  mockData
}) => {
  return (
    <Query
      query={QStatusQuery}
      args={args}
      fetchPolicy="network"
      mockKey="QStatus"
      mockData={mockData}
    >
      {populateChildren(['status'])(children, childDataProp)}
    </Query>
  );
};

export default QStatus;
