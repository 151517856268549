/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QAdherentProfessionalInformationQueryVariables = {|
  id: string
|};
export type QAdherentProfessionalInformationQueryResponse = {|
  +adherent: ?{|
    +id: ?string,
    +professionalInformation: ?{|
      +specialty: ?string,
      +hospital: ?{|
        +id: string,
        +startDate: ?string,
        +endDate: ?string,
        +details: ?{|
          +name: ?string,
          +address1: ?string,
          +address2: ?string,
          +address3: ?string,
          +zipCode: ?string,
          +city: ?string,
          +country: ?string,
          +adherent: ?boolean,
          +subscriber: ?boolean,
          +payer: ?boolean,
        |},
      |},
    |},
  |}
|};
export type QAdherentProfessionalInformationQuery = {|
  variables: QAdherentProfessionalInformationQueryVariables,
  response: QAdherentProfessionalInformationQueryResponse,
|};
*/


/*
query QAdherentProfessionalInformationQuery(
  $id: ID!
) {
  adherent(id: $id) {
    id
    professionalInformation {
      specialty
      hospital {
        id
        startDate
        endDate
        details {
          name
          address1
          address2
          address3
          zipCode
          city
          country
          adherent
          subscriber
          payer
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "Adherent",
    "kind": "LinkedField",
    "name": "adherent",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "AdherentProfessionalInformation",
        "kind": "LinkedField",
        "name": "professionalInformation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "specialty",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AdherentProfessionalInformationHospital",
            "kind": "LinkedField",
            "name": "hospital",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "HospitalDetails",
                "kind": "LinkedField",
                "name": "details",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "address1",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "address2",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "address3",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "zipCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "city",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "country",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "adherent",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "subscriber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "payer",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QAdherentProfessionalInformationQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QAdherentProfessionalInformationQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "3946e29edf0fea54cf920ebe0cc3d24e",
    "id": null,
    "metadata": {},
    "name": "QAdherentProfessionalInformationQuery",
    "operationKind": "query",
    "text": "query QAdherentProfessionalInformationQuery(\n  $id: ID!\n) {\n  adherent(id: $id) {\n    id\n    professionalInformation {\n      specialty\n      hospital {\n        id\n        startDate\n        endDate\n        details {\n          name\n          address1\n          address2\n          address3\n          zipCode\n          city\n          country\n          adherent\n          subscriber\n          payer\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '04afc56ed02011e5883103f684530f3c';

module.exports = node;
