import React from 'react';
import { Field } from '@@components';
import PropTypes from 'prop-types';
import EditSelect from '@@pages/adherent/components/EditSelect';

const EditHospital = ({ hospitalName }) => {
  return (
    <div className={'f-edit-adherent-subform-container'}>
      <Field label={'Centre Hospitalier'}>
        <EditSelect
          selected={{ label: hospitalName, value: hospitalName }}
          isDisabled={true}
        />
      </Field>
    </div>
  );
};

EditHospital.propTypes = {
  hospitalName: PropTypes.string.isRequired
};

export default EditHospital;
