import React from 'react';
import { graphql } from 'react-relay';
import Query from '../Query';
import {
  populateChildren
} from './toolbox';

const QDiscussionQuery = graphql`
  query QDiscussionQuery($id: ID, $messageFilter: MessageFilterInput, $paginate: PaginateInput) {
    getDiscussion(id: $id) {
      id
      title
      recipients
      assignedTo
      sender
      status
      priority
      collaborator {
        id
        firstName
        lastName
      }
      allMessages(filter: $messageFilter, paginate: $paginate) {
        data {
          id
          title
          recipients
          sender
          text
          order
          dates {
            creation
            lastUpdate
          }
          attachments {
            id
            documentType
            fileName
          }
        }
        pagination {
          total
          limit
          page
          pages      
        }
      }
    }
  }
`;

const QDiscussion = ({
  args,
  children,
  childDataProp,
  mockData
}) => {
  return (
    <Query
      query={QDiscussionQuery}
      args={args}
      fetchPolicy="network"
      mockData={mockData}
    >
      {populateChildren(['getDiscussion'])(children, childDataProp)}
    </Query>
  );
};

export default QDiscussion;

export {
  QDiscussionQuery
};
