/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QExtranetMessageThemesQueryVariables = {||};
export type QExtranetMessageThemesQueryResponse = {|
  +references: ?{|
    +messageThemesExtranet: ?$ReadOnlyArray<?{|
      +id: ?string,
      +title: ?string,
      +actionId: ?string,
      +isFilterable: ?boolean,
      +subThemes: ?$ReadOnlyArray<?{|
        +id: ?string,
        +isFilterable: ?boolean,
        +actionId: ?string,
        +title: ?string,
        +attachments: ?{|
          +mandatory: ?$ReadOnlyArray<?$ReadOnlyArray<?string>>
        |},
      |}>,
    |}>
  |}
|};
export type QExtranetMessageThemesQuery = {|
  variables: QExtranetMessageThemesQueryVariables,
  response: QExtranetMessageThemesQueryResponse,
|};
*/


/*
query QExtranetMessageThemesQuery {
  references {
    messageThemesExtranet {
      id
      title
      actionId
      isFilterable
      subThemes {
        id
        isFilterable
        actionId
        title
        attachments {
          mandatory
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actionId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isFilterable",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "References",
    "kind": "LinkedField",
    "name": "references",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MessageThemeReferences",
        "kind": "LinkedField",
        "name": "messageThemesExtranet",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MessageSubThemeReferences",
            "kind": "LinkedField",
            "name": "subThemes",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v3/*: any*/),
              (v2/*: any*/),
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "MessageAttachments",
                "kind": "LinkedField",
                "name": "attachments",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mandatory",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QExtranetMessageThemesQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QExtranetMessageThemesQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "0e8a76d1d6faaf30fa8a38f90aba5c6b",
    "id": null,
    "metadata": {},
    "name": "QExtranetMessageThemesQuery",
    "operationKind": "query",
    "text": "query QExtranetMessageThemesQuery {\n  references {\n    messageThemesExtranet {\n      id\n      title\n      actionId\n      isFilterable\n      subThemes {\n        id\n        isFilterable\n        actionId\n        title\n        attachments {\n          mandatory\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1cf2ef9a30d2c264d57f8578dc7669f0';

module.exports = node;
