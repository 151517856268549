import React from 'react';
import useForms from '@@formsHook';
import Forms from '@@components/forms';
import { useAdherent } from '@@hooks';
import withSession from '@@root/withSession';
import { Link } from 'react-router-dom';
import { phoneNumberOptions } from '@@data';
import { useKeycloakSession } from '../../hooks';
const R = require('ramda');

const EditPhoneNumbers = ({
  pathConfig
}) => {
  const { user } = useKeycloakSession();
  const {
    fetching,
    onFormsSubmit,
    formsData
  } = useForms('adherentPhoneNumbers');

  const { adherent } = useAdherent(user.customerId, ['contact']);

  if (R.isNil(adherent.contact)) return null;
  return (
    <Forms
      formsData={formsData}
      columns={1}
      defaultValues={{
        phoneNumbers: adherent.contact.phoneNumbers
      }}
      options={{
        phoneNumbers: phoneNumberOptions
      }}
      onChange={() => fetching.reset()}
      onSubmit={onFormsSubmit}
      submitSection={() => (
        <div className="flex items-center mt-4">
          <button
            className="f-button f-button-blue mr-4"
            disabled={fetching.isFetching()}
            type="submit"
          >
            {'Enregistrer'}
          </button>
          <Link
            className="f-button f-button-blue mr-4"
            to={pathConfig.cancelPath}
          >
            {'Annuler'}
          </Link>
        </div >
      )}
    />
  );
};

export default EditPhoneNumbers;
