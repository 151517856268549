import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { bellIcon } from '@@images';
const R = require('ramda');

const Notifications = ({ notificationsText, nbMessagesNotReaded }) => {
  const [openNotifications, setOpenNotifications] = React.useState(false);

  const handleCloseDropDown = () => setOpenNotifications(false);

  return <div className="relative">
    <button
      className="ml-4 h-bell-icon w-bell-icon"
      onClick={() => setOpenNotifications(!openNotifications)}
    >
      <img className="h-full" src={bellIcon} />
      {
        R.gt(nbMessagesNotReaded, 0) && <div className="f-chip-blue f-chip-blue-absolute">{nbMessagesNotReaded}</div>
      }
    </button>
    {
      openNotifications && <div className="f-notification-dropdown">
        {
          R.isEmpty(notificationsText) ? <>
            <div
              className="text-center f-notification-dropdown-item"
            >
              {'Pas de notification'}
            </div>
          </>
            : <>
              {
                notificationsText.map(notification => (
                  <Link
                    onClick={handleCloseDropDown}
                    to={`/messagerie/${notification.discussionId}`}
                    key={notification.discussionId}
                  >
                    <div
                      className="f-notification-dropdown-item"
                    >
                      {notification.text}
                    </div>
                  </Link>
                ))
              }
            </>
        }
      </div>
    }
  </div>;
};

Notifications.propTypes = {
  notificationsText: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      discussionId: PropTypes.string
    })
  ).isRequired,
  nbMessagesNotReaded: PropTypes.number
};

export default Notifications;
