import { graphql } from 'react-relay';

const QAdherentContracts = graphql`
  query QAdherentContractsQuery($id: ID!) {
    adherent(id: $id) {
      id
      contracts {
        id
        endDate
        contract
        category
        activityRate
        pricingOptions {
          type
          index
        }
        SEPAMandate {
          account {
            IBAN
            holder
          }
        }
        directDebit
        periodicity
        detail {
          label
          category
          contract
          typology
          function
          activity
          health {
            option
            guarantee {
              value
            }
          }
          pension {
            guarantee {
              value
            }
          }
          assistance {
            guarantee {
              value
            }
          }
        }
      }
    }
  }
`;

export {
  QAdherentContracts
};
