const documentTypeOptions = [
  {
    value: 'DOCUMENT_ADHESION',
    label: 'Document d\'adhésion'
  }, {
    value: 'ATTESTATION',
    label: 'Attestation'
  }, {
    value: 'ECHEANCIER',
    label: 'Echéancier'
  }, {
    value: 'BULLETIN_ADHESION',
    label: 'Signature électronique : Bulletin d\'adhésion'
  },
  // TODO à voir avec Bessé
  // {
  //   value: 'DOSSIER_ADHESION',
  //   label: 'Signature électronique : Dossier d\'adhésion'
  // }
  {
    value: 'MANDAT_SEPA',
    label: 'Signature électronique : Mandat SEPA'
  }, {
    value: 'DESIGNATION_DE_BENEFICIAIRE',
    label: 'Signature électronique : Designation de bénéficiaire'
  }
  // {
  //   value: 'QUESTIONNAIRE_MEDICAL',
  //   label: 'Signature électronique : Questionnaire médical'
  // }
];

export default documentTypeOptions;
