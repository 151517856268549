import { useQueryUrl } from '@@hooks';
import QAdherent from '@@queries/QAdherent';
import QAdherentsWithPagination, {
  QAdherentsWithPaginationQuery
} from '@@queries/QAdherentsWithPagination';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { useEffect, useState } from 'react';
import { AdherentFrame, AdherentList, SearchBlock } from './components';
import {
  formatSocialNumber, getExportCSVFileName,
  getSubscriberIdFromMatch,
  SUBSCRIBER_CSV_HEADER,
  subscriberToDataArray
} from './utils';
import { useFetchQuery } from '../../hooks/useReferences';
import FileSaver from 'file-saver';
import * as Papa from 'papaparse';

// TODO - this could probably be imported from 'graphql'
//  at some point / see https://github.com/graphql/graphql-js/pull/3355/files
// TODO - there is probably a more elegant way to retrieve all subscribers
//  (with filters only, and no pagination involved)
const GRAPHQL_MAX_INT = 50000000; // 2147483647;

const Home = ({
  match,
  pathConfig,
  history
}) => {
  const queryParams = useQueryUrl();
  const firstNameParam = R.pathOr('', [0, 'firstName'], queryParams);
  const lastNameParam = R.pathOr('', [0, 'lastName'], queryParams);
  const idParam = R.pathOr('', [0, 'id'], queryParams);

  const [page, setPage] = useState(1);
  const [adherentFilter, setAdherentFilter] = useState({
    multiProps: '',
    insee: '',
    contract: '',
    birthDate: null,
    onBoarded: '',
    firstName: firstNameParam,
    lastName: lastNameParam,
    id: idParam,
    name: '',
    sort: 'ASC'
  });

  const [totalAdherent, setTotalAdherent] = useState(0);

  const [isExporting, setIsExporting] = useState(false);
  const {
    isLoading: isLoadingExport,
    isLoaded: isLoadedExport,
    data: dataExport
  } = useFetchQuery(QAdherentsWithPaginationQuery, {
    filter: adherentFilter,
    paginate: {
      page: 1,
      limit: GRAPHQL_MAX_INT
    }
  }, R.not(isExporting));

  // Whenever the search request ends, isLoading switches to false
  useEffect(() => {
    if (RA.isFalse(isLoadingExport)) {
      setIsExporting(false);
    }
    if (RA.isTrue(isLoadedExport) && RA.isFalse(isLoadingExport)) {
      // fixme (?) sometimes the search query fails, and dataExport is empty
      const data = R.pathOr([], ['adherentsWithPagination', 'data'])(dataExport);
      const dataJSON = ({
        fields: SUBSCRIBER_CSV_HEADER,
        data: R.map(subscriberToDataArray, data)
      });
      const dataCSV = Papa.unparse(dataJSON);
      const blob = new Blob([dataCSV], { type: 'text/csv;charset=utf-8' });
      FileSaver.saveAs(blob, getExportCSVFileName());
    }
  }, [isLoadingExport, isLoadedExport, dataExport]);

  const subscriberId = getSubscriberIdFromMatch(match);

  const onSelectAdherent = (subscriberId) => {
    history.push(pathConfig.viewPath(subscriberId));
  };

  const handleExportClick = () => {
    setIsExporting(true);
  };

  const exportButton = (
    <button
      className={'f-button f-button-coral cursor-pointer'}
      onClick={handleExportClick}
      disabled={isExporting}
    >
      {'Exporter'}
    </button>
  );

  return (
    <div className="w-full flex flex-wrap justify-between mt-8">
      <div className="f-block">
        <div className="w-1/3 p-6 border-r-2">
          <div className="flex items-center justify-between">
            <h3>{'Liste d’adhérents'}</h3>
            {exportButton}
          </div>
          <SearchBlock
            totalAdherent={R.propOr(0, 'total', totalAdherent)}
            adherentFilter={adherentFilter}
            setAdherentFilter={setAdherentFilter}
          />
          <QAdherentsWithPagination args={{
            filter: adherentFilter,
            paginate: { page, limit: 20 }
          }} >
            {({ adherentsWithPagination }) => {
              React.useEffect(() => {
                setTotalAdherent(adherentsWithPagination.pagination);
              }, [adherentsWithPagination]);

              return (
                <AdherentList
                  adherents={R.propOr([], 'data', adherentsWithPagination)}
                  onSelect={onSelectAdherent}
                  current={subscriberId}
                  pagination={adherentsWithPagination.pagination}
                  setPage={setPage}
                  formatSocialNumber={formatSocialNumber}
                />
              );
            }}
          </QAdherentsWithPagination>
        </div>
        {
          subscriberId &&
          <QAdherent args={{ id: subscriberId }} >
            {({ adherentInfo }) => (
              <AdherentFrame
                subscriberId={subscriberId}
                formatSocialNumber={formatSocialNumber}
                adherentInfo={adherentInfo}
                pathConfig={pathConfig}
              />
            )}
          </QAdherent>
        }
      </div>
    </div>
  );
};

Home.propTypes = {
  match: PropTypes.shape({
    isExact: PropTypes.bool.isRequired,
    params: PropTypes.shape({
      subscriberId: PropTypes.string
    }).isRequired,
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }).isRequired,
  pathConfig: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default Home;
