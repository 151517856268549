import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useForms from '@@formsHook';
import Forms from '@@components/forms';
import QAdherent from '@@queries/QAdherent';
import * as R from 'ramda';
import { MessageRecord } from '@@graphql/records/MessagesRecord';
import { ErrorMessage } from '@@components';
import { documentTypeOptions } from '@@data';
import * as RA from 'ramda-adjunct';

const ReplyTo = ({
  title,
  discussionId,
  subscriberId,
  getAdherentNames
}) => {
  const [errorMessage, setErrorMessage] = React.useState(null);

  const {
    fetching,
    onFormsSubmit,
    formsData
  } = useForms('sendMessage', {
    updater: (input, store, response) => {
      if (R.isNil(R.prop('sendMessage', response))) return;
      if (R.pathSatisfies(RA.isFalse, ['sendMessage', 'ok'], response)) {
        setErrorMessage(response.error);
        return;
      }

      const rec = MessageRecord(store);

      const message = {
        ...R.path(['sendMessage', 'message'], response),
        dates: R.path(['sendMessage', 'dates'], response),
        attachments: R.path(['sendMessage', 'attachments'], response)
      };

      rec.addMessage(
        R.path(['sendMessage', 'messageId'], response),
        discussionId,
        message
      );
      setClotureDisabled(false);
    }
  });
  const [clotureDisabled, setClotureDisabled] = useState(false);
  const handleSubmit = (data) => {
    setClotureDisabled(true);
    onFormsSubmit(data);
  };

  const closeDiscussion = useForms('closeDiscussion');

  React.useEffect(() => {
    if (fetching.getError()) {
      setErrorMessage(fetching.getError());
    }
    if (fetching.isDone()) {
      setErrorMessage(null);
    }
  }, [fetching.getError(), fetching.isDone()]);

  React.useEffect(() => {
    if (closeDiscussion.fetching.getError()) {
      setErrorMessage(closeDiscussion.fetching.getError());
    }
    if (closeDiscussion.fetching.isDone()) {
      setErrorMessage(null);
    }
  }, [closeDiscussion.fetching.getError(), closeDiscussion.fetching.isDone()]);

  const getDefaultValue = React.useCallback(() => {
    const defaultValue = { discussionId };
    if (!R.isNil(discussionId)) {
      return R.compose(
        R.assoc('title', title),
        R.assoc('actionId', title),
        R.assoc('text', ''),
        R.assoc('attachments', []),
        R.assoc('subscriberId', subscriberId)
      )(defaultValue);
    }
    return defaultValue;
  }, [title, discussionId]);

  const getHiddenValue = React.useCallback(() => {
    const hiddenFields = { discussionId };
    if (!R.isNil(discussionId)) {
      return R.compose(
        R.assoc('title', title),
        R.assoc('actionId', title)
      )(hiddenFields);
    }
    return hiddenFields;
  }, [title, discussionId]);

  const handleCloseDisscussion = async () => {
    closeDiscussion.onFormsSubmit({ discussionId });
  };

  return (
    <div className="p-4 flex flex-col">
      <QAdherent args={{ id: subscriberId }}>
        {({ adherentInfo }) => <div className="text-sm font-bold text-dark uppercase">
          {`Répondre à ${getAdherentNames(adherentInfo.firstName, adherentInfo.lastName)}`}
        </div>
        }
      </QAdherent>
      <Forms
        formsData={formsData}
        columns={1}
        defaultValues={getDefaultValue()}
        hiddenFields={getHiddenValue()}
        alignedLabels={{ signRequestAttachments: 'Demander une signature électronique pour les documents envoyés' }}
        onSubmit={handleSubmit}
        resetOnSubmit
        options={{
          documentTypeOptions
        }}
        submitSection={() => <>
          <div className="flex justify-between mt-4">
            <button
              className="f-button f-button-coral w-min"
              type="submit"
              disabled={fetching.isFetching()}
            >
              {'Envoyer'}
            </button>
            <button onClick={handleCloseDisscussion} className="f-button-sm f-button-coral-reverse" type="button"
              disabled={clotureDisabled}>
              {'Clôturer la demande'}
            </button>
          </div>
          <div className="flex justify-end">
            {errorMessage && <ErrorMessage value={errorMessage}/>}
          </div>
        </>}
      />
    </div>);
};

ReplyTo.propTypes = {
  title: PropTypes.string.isRequired,
  discussionId: PropTypes.string.isRequired,
  subscriberId: PropTypes.string.isRequired,
  getAdherentNames: PropTypes.func.isRequired
};

ReplyTo.defaultProps = {};

export default ReplyTo;
