import React, { useState } from 'react';
import { hot } from 'react-hot-loader/root';
import Home from '@@pages/home';
import MenuAdmin, { menuAdminItems } from '@@pages/admin';
import Session from '@@root/Session';
import { SessionContext } from '@@root/withSession';
import { withSize } from 'react-sizeme';
import { QueryParamProvider } from 'use-query-params';

import { WithSizeContext } from './withSize';

import {
  BrowserRouter as Router,
  Link,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';

import UserSessionRoutes from './pages/userSession';
import ProtectedRoutes from './ProtectedRoutes';

const R = require('ramda');

const Routes = ({
  size
}) => {
  const [setDimensions] = useState({ height: -1, width: -1 });
  const onResize = (contentRect) => {
    setDimensions(contentRect.bounds);
  };

  return (
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>
        <WithSizeContext.Provider value={size}>
          <Switch>
            <Route exact path="/compte" component={UserSessionRoutes} />
            <Route path="/" component={ProtectedRoutes} />
          </Switch>
        </WithSizeContext.Provider>
      </QueryParamProvider>
    </Router>
  );
  // return (
  //   <WithSizeContext.Provider value={size}>
  //     <Session refreshInterval={300000}>
  //       {({ userSession, onSignedIn, onSignOut }) => {
  //         console.log('$$ ', userSession);
  //         return (
  //           <SessionContext.Provider value={{ userSession, onSignedIn, onSignOut }}>
  //             <Router>
  //               <QueryParamProvider ReactRouterRoute={Route}>
  //                 <Switch>
  //                   <Route path="/compte" component={UserSessionRoutes} />
  //                   {R.isNil(R.propOr(null, 'username', userSession)) && <Redirect to="/compte/connexion" />}
  //                   <Route path="/" component={ProtectedRoutes} />
  //                 </Switch>
  //               </QueryParamProvider>
  //             </Router>
  //           </SessionContext.Provider>
  //         );
  //       }}
  //     </Session>
  //   </WithSizeContext.Provider>
  //
  // );
};

export default hot(withSize()(Routes));
