import React from 'react';
import { graphql } from 'react-relay';
import Query from '../Query';
import {
  populateChildren
} from './toolbox';

const QAdherentQuery = graphql`
  query QAdherentQuery($id: ID!) {
    adherentInfo(id: $id) {
      id
      firstName
      lastName
      maidenName
      onBoarded
      familyStatus
      birthDate
      insee
    } 
  }
`;

const QAdherent = ({
  args,
  children,
  childDataProp
}) => {
  return (
    <Query
      query={QAdherentQuery}
      args={args}
      fetchPolicy="network"
    >
      {populateChildren(['adherentInfo'])(children, childDataProp)}
    </Query>
  );
};

export default QAdherent;
