import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Page } from '@@components';
import Home from './Home';
const R = require('ramda');
const paths = (root) => (k) => {
  const config = {
  };

  if (R.isNil(k)) return config;
  return R.prop(k, config);
};

const index = ({
  history,
  match
}) => {
  const path = v => `${match.url}${R.when(R.isNil, R.always(''), v)}`;
  const pathConfig = paths(match.url);

  return (
    <Page title="Actions">
      <Route exact path={path()} render={() => <Home pathConfig={pathConfig()} history={history} />} />
      < Redirect to={path()} />
    </Page>
  );
};

export default index;
