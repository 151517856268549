import { typologies } from '@@enum';

const typologyOptions = [
  { value: typologies.PHHU, label: 'PHHU' },
  { value: typologies.LIBERAL, label: 'Libéral' },
  { value: typologies.INTERNE, label: 'Interne' },
  { value: typologies.RETRAITE, label: 'Retraité' }
];

export default typologyOptions;
