import React from 'react';
import PropTypes from 'prop-types';
import { checkLogo } from '@@images';
import { Loading } from '@@components';
import * as R from 'ramda';

const Contact = ({
  contact
}) => {
  const getFormatContactByType = (adherent, phoneOrEmail, contactType) => {
    const isPhoneNumbers = R.equals(phoneOrEmail, 'phoneNumbers');
    const formatNotCertifiedContacts = R.compose(
      R.join(' - '),
      R.when(
        R.always(isPhoneNumbers),
        R.map(R.pipe(R.splitEvery(2), R.join(' ')))
      ),
      R.map(R.prop('value')),
      R.reject(R.propEq('type', contactType))
    )(R.propOr([], phoneOrEmail, adherent));

    const certifiedContact = R.compose(
      R.when(
        R.always(isPhoneNumbers),
        R.pipe(R.splitEvery(2), R.join(' '))
      ),
      R.prop('value'),
      R.find(R.propEq('type', contactType))
    )(R.propOr([], phoneOrEmail, adherent));

    return (
      <div className="flex">
        {certifiedContact && (
          <>
            <div className="mr-1">{certifiedContact}</div>
            <img className="mr-1" src={checkLogo} alt={'checked'}/>
            {'-'}
          </>
        )}
        {formatNotCertifiedContacts}
      </div>
    );
  };

  return <>
    {
      contact
        ? <>
          {getFormatContactByType(contact, 'phoneNumbers', 'mobile_certified')}
          {getFormatContactByType(contact, 'emails', 'certified')}
        </>
        : <Loading />
    }
  </>;
};

Contact.propTypes = {
  contact: PropTypes.shape({
    phoneNumbers: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      type: PropTypes.string
    })),
    emails: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      type: PropTypes.string
    })),
    address: PropTypes.shape({
      address1: PropTypes.string,
      address2: PropTypes.string,
      address3: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.string,
      pnd: PropTypes.bool,
      zipcode: PropTypes.string
    })
  })
};

export default Contact;
