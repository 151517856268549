import React from 'react';
import { graphql } from 'react-relay';
import Query from '../Query';
import {
  populateChildren
} from './toolbox';

const QAllActionsQuery = graphql`
  query QAllActionsQuery($actionFilter: ActionFilterInput, $paginate: PaginateInput) {
    allActions(filter: $actionFilter, paginate: $paginate) {      
      data {
        id
        status
        type
        label
        text
      } 
      pagination {
        total
        limit
        page
        pages      
      } 
    }
  }
`;

const QAllActions = ({
  args,
  children,
  childDataProp,
  mockData
}) => {
  return (
    <Query
      query={QAllActionsQuery}
      args={args}
      fetchPolicy="network"
      mockData={mockData}
    >
      {populateChildren(['allActions'])(children, childDataProp)}
    </Query>
  );
};

export default QAllActions;

export {
  QAllActionsQuery
};
