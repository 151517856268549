const messageNotificationText = {
  NOTIFY_GESTIONNAIRE_AFFECTATION: 'vous a affecté la demande',
  NOTIFY_GESTIONNAIRE_NEW_MESSAGE_ADH: 'a apporté une réponse à la demande',
  NOTIFY_GESTIONNAIRE_DISCUSSION_REOPENED: 'a rouvert la demande',
  NOTIFY_GESTIONNAIRE_NEW_MESSAGE_GST: 'a apporté une réponse à la demande',
  NOTIFY_MANAGER_GESTIONNAIRE_NEW_DISCUSSION: 'a créé une nouvelle demande',
  NOTIFY_MANAGER_NEW_MESSAGE_ADH: 'a apporté une réponse à la demande',
  NOTIFY_MANAGER_NEW_MESSAGE_GST: 'a apporté une réponse à la demande',
  NOTIFY_MANAGER_AFFECTATION: 'a affecté la demande'
};

export default messageNotificationText;
