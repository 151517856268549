import React, { useRef, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { QBackOfficeMessageThemesQuery } from '@@queries/QBackOfficeMessageThemes';
import { QExtranetMessageThemesQuery } from '@@queries/QExtranetMessageThemes';
import { QMessageAttachmentTypesQuery } from '@@queries/QMessageAttachmentTypes';

const R = require('ramda');
const { fetchQuery, useRelayEnvironment } = require('react-relay');

export const useReferences = (data) => {
  const environment = useRelayEnvironment();
  const [content, setContent] = React.useState({
    messageThemes: null,
    messageAttachmentTypes: null
  });

  const dataRef = React.useRef();
  React.useEffect(() => {
    if (R.equals(dataRef.current, data)) return;
    dataRef.current = data;
    if (R.includes('messageAttachmentTypes', data)) getMessageAttachmentTypes();
    if (R.includes('backOfficeMessageThemes', data)) getBackOfficeMessageThemes();
    if (R.includes('extranetMessageThemes', data)) getExtranetMessageThemes();
  }, [data]);

  const getBackOfficeMessageThemes = async () => {
    const { references } = await fetchQuery(environment, QBackOfficeMessageThemesQuery).toPromise();
    setContent(R.compose(
      R.assoc('backOfficeMessageThemes', R.path(['messageThemesBackOffice'], references))
    ));
  };

  const getExtranetMessageThemes = async () => {
    const { references } = await fetchQuery(environment, QExtranetMessageThemesQuery).toPromise();
    setContent(R.compose(
      R.assoc('extranetMessageThemes', R.path(['messageThemesExtranet'], references))
    ));
  };

  const getMessageAttachmentTypes = async () => {
    const { references } = await fetchQuery(environment, QMessageAttachmentTypesQuery).toPromise();
    setContent(R.compose(
      R.assoc('messageAttachmentTypes', references.messageAttachmentTypes)
    ));
  };

  return {
    ...content
  };
};

export const useFetchQuery = (gqlQuery, args, cancel) => {
  const environment = useRelayEnvironment();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const resultData = useRef({});
  // args is always treated as an object, so we can apply useDeepCompareEffect
  // on primitives or objects without errors
  const argsAsObj = { args, cancel };
  // const enableLog = R.path(['fragment', 'name'], gqlQuery) === 'QAdherentsWithPaginationQuery';
  useDeepCompareEffect(() => {
    if (cancel) return;
    const subscription = fetchQuery(environment, gqlQuery, args)
      .subscribe({
        start: () => {
          // if (enableLog) console.log('start fetchQuery...', gqlQuery, args);
          setIsLoaded(false);
          setIsLoading(true);
        },
        complete: () => {
          // if (enableLog) console.log('completed fetchQuery!');
          setIsLoaded(true);
          setIsLoading(false);
        },
        error: (error) => {
          // if (enableLog) console.error('oops, an error!', error);
          setIsLoaded(false);
          setIsLoading(false);
          setErrorMsg(JSON.stringify(error, null, 4));
        },
        next: (data) => {
          // if (enableLog) console.log(' next packet received...', data);
          resultData.current = data;
        }
      });
    return () => subscription.unsubscribe();
  }, [argsAsObj]);

  return {
    isLoaded: isLoaded,
    isLoading: isLoading,
    errorMsg: errorMsg,
    data: resultData.current
  };
};

export default useReferences;
