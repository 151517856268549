
import React from 'react';
import { Page } from '@@components';
import withSession from '@@root/withSession';

import {
  withRouter,
  Redirect,
  Route,
  Switch
} from 'react-router-dom';

import SignIn from './SignIn';
import FirstConnection from './FirstConnection';

const UserSessionRoutes = ({
  session,
  match
}) => {
  return (
    <Page block>
      <Switch>
        <Route exact path={`${match.url}/connexion`} component={SignIn} />
        <Route exact path={`${match.url}/firstconnection`} component={FirstConnection} />
        <Redirect to="/" />
      </Switch>
    </Page>
  );
};

export default withRouter(UserSessionRoutes);
