import React from 'react';
import classNames from 'classnames';
import { homeBg } from '@@images';
import PropTypes from 'prop-types';

const Page = ({
  title,
  block,
  withHeader,
  withBanner, // false pour les pages de modification infos adhérent
  children,
  svgLogo
}) => {
  if (React.Children.count(children) === 0) {
    return (
      <div className="f-page-placeholder">
        {title}
      </div>
    );
  }

  const getMainStyle = () => {
    if (withBanner) {
      return {
        backgroundImage: `url(${homeBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%'
      };
    } else {
      return {};
    }
  };

  const getHeader = () => withHeader && (
    <div
      className="f-page-top"
      style={{ backgroundImage: 'url(/images/fond-tunnel.png)' }}
    />
  );

  const getTitle = () => withHeader && withBanner && (
    <h1 className="f-page-top-title">
      {title}
    </h1>
  );

  const getLogo = () => withBanner && (
    <div className="mt-8">
      <img src={svgLogo} alt={'logo'}/>
    </div>
  );

  return (
    <div style={getMainStyle()}>
      <div className={classNames('f-page', { 'f-page-block': block })}>
        { getHeader() }
        <div className="f-page-body">
          { getTitle() }
          { getLogo() }
          {children}
        </div>
      </div>
    </div>
  );
};

Page.propTypes = {
  withBanner: PropTypes.bool.isRequired
};

Page.defaultProps = {
  withBanner: true,
  widthHeader: false // fixme - typo
};

export default Page;
