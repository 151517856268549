import { graphql } from 'react-relay';

const QExtranetMessageThemesQuery = graphql`
  query QExtranetMessageThemesQuery {
      references {
        messageThemesExtranet {
            id
            title
            actionId
            isFilterable
            subThemes {
              id
              isFilterable
              actionId
              title
              attachments {
                mandatory
              }
            }
        }
      }
    }
`;

export {
  QExtranetMessageThemesQuery
};
