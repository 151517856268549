import React from 'react';
import withSize from '@@root/withSize';
import Menu from './Menu';
import Layout, { Footer, Top } from '@@layout';
import Welcome from './Welcome';
import Actions from '@@pages/actions';
import Message from '@@pages/message';
import Adherent from '@@pages/adherent';
import { useCms, useMessageNotifier } from '@@hooks';
import { Switch, Redirect, Route } from 'react-router-dom';
import { messageNotificationText } from '@@enum';

const R = require('ramda');
const RA = require('ramda-adjunct');

const Home = () => {
  const { contact } = useCms(['contact']);
  const { nbMessagesNotReaded, messageNotified } = useMessageNotifier();

  const [notificationsText, setNotificationsText] = React.useState([]);

  React.useEffect(() => {
    const notifications = R.map(({ fullName, theme, type, optFullName, group, discussionId }) => {
      const maybeOptFullName = R.ifElse(
        RA.isNotNilOrEmpty,
        R.always(`initialement affectée à ${optFullName}`),
        R.always('')
      )(optFullName);
      const managerAffectationNotificationText = R.ifElse(
        R.equals('NOTIFY_MANAGER_AFFECTATION'),
        R.always(`au groupe ${group}`),
        R.always('')
      )(type);
      return {
        discussionId,
        text: `${fullName} ${R.prop(type, messageNotificationText)} ${theme} ${maybeOptFullName} ${managerAffectationNotificationText}`
      };
    }, messageNotified);
    setNotificationsText(notifications);
  }, [nbMessagesNotReaded]);

  if (R.isNil(contact)) return null;

  return (
    <>
      <Layout
        footer={<Footer content={{ contact }} />}
        top={<Top content={{ contact }} nbMessagesNotReaded={nbMessagesNotReaded} />}
        menu={<Menu notificationsText={notificationsText} nbMessagesNotReaded={nbMessagesNotReaded}/>}
      >
        <Switch>
          <Route exact path="/" component={Welcome} />
          <Route path="/actions" component={Actions} />
          <Route path="/messagerie" component={Message} />
          <Route path="/adherent" component={Adherent} />
          <Redirect to="/" />
        </Switch>
      </Layout>
    </>
  );
};

export default withSize(Home);
