import React from 'react';
import { QAdherentsWithPaginationQuery } from '@@queries/QAdherentsWithPagination';

const R = require('ramda');
const { fetchQuery } = require('react-relay');
const { useRelayEnvironment } = require('react-relay');

export const useAdherentWithpagination = () => {
  const environment = useRelayEnvironment();
  const [asyncSelectData, setAsyncSelectData] = React.useState({
    callback: () => [],
    filter: { multiProps: '' }
  });

  const filterRef = React.useRef();
  const callbackRef = React.useRef();

  React.useEffect(async () => {
    if (R.equals(callbackRef.current, asyncSelectData.callback)) return;
    if (R.equals(filterRef.current, asyncSelectData.filter)) return;

    callbackRef.current = asyncSelectData.callback;
    filterRef.current = asyncSelectData.callback;

    const callback = asyncSelectData.callback;
    const filter = asyncSelectData.filter;

    const mapOptionSelect = (inputValue) => R.map(({ id, firstName, lastName, maidenName }) => {
      const names = (firstPlaceName, secondPlaceName, thirdPlaceName) => {
        const reg = new RegExp(inputValue, 'i');
        return R.compose(
          R.toUpper,
          R.replace(reg, `**${inputValue}**`),
          R.join(' '),
          R.reject(R.anyPass([R.isNil, R.isEmpty]))
        )([firstPlaceName, secondPlaceName, thirdPlaceName]);
      };
      const formatAdherentId = R.replace(inputValue, `**${inputValue}**`);
      return {
        value: id,
        label: `${names(lastName, maidenName, firstName)} - N°${formatAdherentId(id)}`
      };
    });

    if (callback) {
      const { adherentsWithPagination } = await fetchQuery(environment, QAdherentsWithPaginationQuery, { paginate: { page: 1, limit: 20 }, filter }).toPromise();
      callback(R.compose(
        mapOptionSelect(filter.multiProps),
        R.propOr([], 'data')
      )(adherentsWithPagination));
    }
  }, [asyncSelectData]);

  return {
    setAsyncSelectData
  };
};

export default useAdherentWithpagination;
