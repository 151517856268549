import './index.less';
import React from 'react';
import Footer from './Footer';
import Top from './Top';
import classNames from 'classnames';
const R = require('ramda');

const Layout = ({
  top,
  menu,
  backgroundImage,
  children,
  showMenu,
  footer
}) => {
  const menuClasses = [
    'f-layout-menu',
    (showMenu) ? 'show' : undefined
  ].join(' ');

  const hasFooter = !R.isNil(footer);
  const hasBkgImg = !R.isNil(backgroundImage);

  const layoutWrapperClassName = classNames({
    'f-layout-wrapper': hasFooter,
    'f-layout-wrapper-no-footer': !hasFooter,
    'f-layout-body-background-image': hasBkgImg
  });

  return (
    <div className="f-layout">
      <div className="f-layout-top">
        {top}
        {menu}
      </div>
      <div
        className={layoutWrapperClassName}
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="f-layout-body">
          {children}
        </div>
        {hasFooter && (
          <div className="f-layout-footer">
            {footer}
          </div>
        )}
      </div>
    </div>
  );
};

Layout.defaultProps = {
  backgroundBodyClassName: '',
  footer: undefined
};

export default Layout;

export {
  Footer,
  Top
};
