import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { useKeycloakSession } from '@@hooks';
import Block from './components/Block';
import { adherentLogo, messagingLogo, logoBesse, logoAppa, powerLogo } from '@@images';
import { getStatusTitle } from '@@layout/utils';
import { useFetchQuery } from '@@hooks/useReferences';
import { QGroupsQuery } from '@@queries/QGroups';

const R = require('ramda');

const Top = ({
  location,
  content,
  nbMessagesNotReaded
}) => {
  const { user, onSignOut } = useKeycloakSession();
  const {
    isLoaded: isLoadedListGroups,
    data: dataListGroups
  } = useFetchQuery(QGroupsQuery);
  const groups = isLoadedListGroups
    ? R.propOr([], 'groups', dataListGroups) : [];

  const blocksContent = [
    {
      link: '/mesinformations',
      img: adherentLogo,
      blockName: 'MON PROFIL'
    },
    {
      link: '/messagerie',
      img: messagingLogo,
      blockName: 'MESSAGERIE',
      nbMessagesNotReaded
    }
  ];

  return (
    <div className="f-top justify-between">
      <div className="flex h-4/5">
        <img className="mr-6" src={logoBesse} alt={'logoBesse'} />
        <img src={logoAppa} alt={'logoAppa'} />
      </div>
      <div className="flex items-end">
        <div className="text-right mb-2 text-navy-blue">
          <div className="uppercase font-bold text-sm mb-1">
            {`${user.firstname} ${user.lastname}`}
          </div>
          <div className="text-sm font-light">
            {getStatusTitle(user, groups)}
          </div>
        </div>
        <div className="flex justify-between">
          <div className={'w-full grid grid-cols-2 gap-2'}>
            {
              blocksContent.map(({ link, img, blockName, nbMessagesNotReaded }) => (
                <React.Fragment key={blockName}>
                  {
                    <Block
                      blockClassName={`relative ${R.equals(link, location.pathname) ? 'f-top-block-bg-blue' : 'f-top-block'}`}
                      link={link}
                      img={img}
                      blockName={blockName}
                      nbMessagesNotReaded={nbMessagesNotReaded}
                    />
                  }
                </React.Fragment>
              ))
            }
          </div>
          <div onClick={onSignOut} className="f-top-deconnection flex-col mx-4">
            <div>
              <img src={powerLogo} alt={'powerLogo'}/>
            </div>
            <div className="text-center my-4 text-xs text-steel">{'DECONNEXION'}</div>
          </div>
        </div>
      </div>
    </div >
  );
};

Top.defaultProps = {
  connected: false,
  progressBar: undefined
};

Top.propTypes = {
  progressBar: PropTypes.object,
  nbMessagesNotReaded: PropTypes.number,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }),
  content: PropTypes.shape({
    contact: PropTypes.shape({
      appaPhoneNumber: PropTypes.string.isRequired
    })
  })
};

export default withRouter(Top);
