import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { Loading } from '@@components';
import Field from '@@components/Field';
import { checkLogo } from '@@images';
import { getPhoneNumberType } from '@@pages/adherent/utils';

const EditContact = ({
  phoneNumbers,
  emails,
  onChangePhoneNumbers,
  onChangeEmails
}) => {
  if (R.isNil(phoneNumbers) || R.isNil(emails)) return <Loading/>;

  const onMobileChange = index => event => {
    const value = event.target.value;
    const type = getPhoneNumberType(value);
    const newPhoneNumbers = R.over(
      R.lensIndex(index),
      R.compose(
        R.assoc('value', value),
        R.assoc('type', type)
      )
    )(phoneNumbers);
    onChangePhoneNumbers(newPhoneNumbers);
  };

  const onEmailChange = index => event => {
    const newEmails = R.over(
      R.lensIndex(index),
      R.assoc('value', event.target.value)
    )(emails);
    onChangeEmails(newEmails);
  };

  const mobileToJSX = ({ type, value }, index) => {
    const isCertified = R.equals('mobile_certified', type);
    return (
      <div className={'f-edit-adherent-contact-row'} key={`mobile-${index}`}>
        <input
          className={'f-input f-edit-adherent-text-input'}
          value={value}
          onChange={onMobileChange(index)}
          disabled={isCertified}
        />
        {isCertified && (
          <img src={checkLogo} alt={'check'}/>
        )}
      </div>
    );
  };

  const emailToJSX = ({ type, value }, index) => {
    const isCertified = R.equals('certified', type);
    return (
      <div className={'f-edit-adherent-contact-row'} key={`email-${index}`}>
        <input
          type={'email'}
          className={'f-input f-edit-adherent-text-input'}
          value={value}
          onChange={onEmailChange(index)}
          disabled={isCertified}
        />
        {isCertified && (
          <img src={checkLogo} alt={'check'}/>
        )}
      </div>
    );
  };

  return (
    <div className={'f-edit-adherent-subform-container'}>
      <Field label={'Téléphone'}>
        <div className={'f-edit-adherent-contact-list'}>
          {RA.mapIndexed(mobileToJSX, phoneNumbers)}
        </div>
      </Field>
      <Field label={'Email'}>
        <div className={'f-edit-adherent-contact-list'}>
          {RA.mapIndexed(emailToJSX, emails)}
        </div>
      </Field>
    </div>
  );
};

EditContact.propTypes = {
  phoneNumbers: PropTypes.array,
  emails: PropTypes.array,
  onChangePhoneNumbers: PropTypes.func,
  onChangeEmails: PropTypes.func
};

export default EditContact;
