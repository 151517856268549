import { useEffect, useState } from 'react';
import { QGetMessageNotifiedQuery as query } from '../_graphql/queries/QGetMessageNotified';
import {
  compose,
  filter,
  inc,
  isEmpty,
  length,
  propEq
} from 'ramda';
const { fetchQuery } = require('react-relay');
const { useRelayEnvironment } = require('react-relay');

const useMessageNotified = () => {
  const environment = useRelayEnvironment();
  const [messageNotified, setMessageNotified] = useState([]);

  const [key, setKey] = useState(0);

  let interval;
  useEffect(() => {
    if (interval) { return; }
    interval = setInterval(() => setKey(inc), 10000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    let hasBeenCancelled = false;
    const fetchData = async () => {
      try {
        const { getMessageNotified: data } = await fetchQuery(environment, query, {}).toPromise();

        if (!hasBeenCancelled) {
          setMessageNotified(data || []);
        }
      } catch (e) {
        console.error(e);
        throw e;
      }
    };

    fetchData();

    return () => (hasBeenCancelled = true);
  }, [key]);

  const checkDiscussionNotification = (discussionId) => {
    if (isEmpty(messageNotified)) {
      return false;
    }

    return compose(
      length,
      filter(propEq('discussionId', discussionId))
    )(messageNotified);
  };
  return {
    messageNotified,
    checkDiscussionNotification,
    nbMessagesNotReaded: length(messageNotified)
  };
};

export default useMessageNotified;
