import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Loading } from '@@components';
import Field from '@@components/Field';
import EditDatePicker from '@@pages/adherent/components/EditDatePicker';

const EditIdentity = ({ civilStatus }) => {
  if (R.isNil(civilStatus)) return <Loading/>;
  const maidenName = R.propOr('', 'maidenName', civilStatus);
  const firstName = R.propOr('', 'firstName', civilStatus);
  const birthDate = R.propOr('', 'birthDate', civilStatus);

  return (
    <div className={'f-edit-adherent-subform-container'}>
      <Field label={'Nom'}>
        <input
          className={'f-input f-edit-adherent-text-input'}
          value={maidenName}
          disabled
        />
      </Field>
      <Field label={'Prénom'}>
        <input
          className={'f-input f-edit-adherent-text-input'}
          value={firstName}
          disabled
        />
      </Field>
      <Field label={'Date de naissance'}>
        <div className={'f-datepicker-edit-adherent-container'}>
          <EditDatePicker
            selected={new Date(birthDate)}
            isDisabled={true}
          />
        </div>
      </Field>
    </div>
  );
};

EditIdentity.propTypes = {
  civilStatus: PropTypes.object
};

export default EditIdentity;
