/**
*
* WARNING : fichier généré par forms, ne pas le modifier directement
*
**/

const R = require('ramda');
import {
commitMutation,
graphql
} from 'react-relay';

const mutation = graphql`
mutation AdherentPhoneNumbersMutation($input: AdherentPhoneNumbersInput!) {
  adherentPhoneNumbers(input: $input) {
    ok
    error
    
  }
}
`;

export default async (environment, input, done, { updater }) => {
  const variables = { input };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: (proxyStore, response) => {
        if (R.is(Function, updater)) updater(input, proxyStore, response);
      },
      onCompleted: ({ adherentPhoneNumbers }) => {
        const { ok, error } = adherentPhoneNumbers;
        done(ok, error, R.omit(['ok', 'error'], adherentPhoneNumbers));
      },
      onError: err => console.error(err)
    }
  );
};