import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { iconCalendar } from '@@images';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import fr from 'date-fns/locale/fr';
registerLocale('fr', fr);

const DatePickerCustomInput = forwardRef(({
  value,
  onClick,
  onChange,
  onBlur,
  onFocus,
  onKeyDown,
  hasError,
  customPlaceholder,
  isDisabled
}, ref) => {
  // const classes = hasError ? 'f-input-red' : 'f-input-gray';
  const handleOnChange = (e) => {
    // * Get access to new value
    const v = R.path(['target', 'value'], e);
    // * Handle Empty
    if (R.isEmpty(v) || R.isNil(v)) {
      return null;
    }
    onChange(e);
    return v;
  };
  return (
    <label className={'flex flex-row w-full'} ref={ref}>
      <input
        className={classNames({
          'f-input-red': hasError,
          'f-input-gray': !hasError,
          'f-datepicker-input': true
        })}
        placeholder={customPlaceholder}
        type="text"
        onClick={onClick}
        onChange={handleOnChange}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        value={value}
        disabled={isDisabled}
      />
      <span className={classNames({
        'f-datepicker-icon-calendar-box': true,
        'f-datepicker-disabled': isDisabled,
        invisible: !isDisabled && RA.isNotNilOrEmpty(value)
      })}>
        <img src={iconCalendar} alt={'date'}/>
      </span>
    </label>
  );
});
DatePickerCustomInput.displayName = 'DatePickerCustomInput';
DatePickerCustomInput.propTypes = {
  customPlaceholder: PropTypes.string,
  hasError: PropTypes.bool,
  isDisabled: PropTypes.bool
};

const EditDatePicker = ({
  selected,
  dateFormat,
  onChange,
  hasError,
  placeholder,
  isDisabled
}) => {
  return (
    <ReactDatePicker
      locale={'fr'}
      readOnly={isDisabled}
      dateFormat={dateFormat}
      selected={selected}
      isClearable={!isDisabled}
      onChange={onChange}
      customInput={
        <DatePickerCustomInput
          hasError={hasError}
          customPlaceholder={placeholder}
          isDisabled={isDisabled}
        />
      }
    />
  );
};

EditDatePicker.propTypes = {
  selected: PropTypes.instanceOf(Date),
  dateFormat: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired
};

EditDatePicker.defaultProps = {
  dateFormat: 'dd/MM/yyyy',
  placeholder: 'JJ/MM/AAAA',
  onChange: () => {},
  hasError: false,
  isDisabled: false
};

export default EditDatePicker;
