import React from 'react';
import Select from 'react-select';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as R from 'ramda';

const SelectOneOption = ({
  options,
  value,
  onChange,
  placeholder,
  hasError,
  sortedByLabel
}) => {
  const customStyle = {
    control: (provided) => ({
      ...provided,
      borderColor: hasError ? '#fca5a5' : R.prop('borderColor', provided)
    })
  };

  // Sort options alphabetically (default behavior)
  const sortedOptions = R.when(
    R.always(R.equals(true, sortedByLabel)),
    R.sort(R.ascend(R.prop('label')))
  )(options);

  return (
    <div className={'f-select'}>
      <Select
        className={classNames({ 'border-red-600': hasError })}
        classNamePrefix={'f-select'}
        value={value}
        onChange={onChange}
        options={sortedOptions}
        placeholder={placeholder}
      />
    </div>
  );
};

SelectOneOption.propTypes = {
  hasError: PropTypes.bool.isRequired,
  sortedByLabel: PropTypes.bool.isRequired
};

SelectOneOption.defaultProps = {
  hasError: false,
  sortedByLabel: true
};

export default SelectOneOption;
