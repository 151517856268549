import React from 'react';
import { graphql } from 'react-relay';
import Query from '../Query';
import {
  populateChildren
} from './toolbox';

const QMemberQuery = graphql`
  query QMemberQuery($id: ID) {
    member(id: $id) {
      id
      firstName
      lastName
      groupes {
        id
        name
        completeName
      }
    } 
  }
`;

const QMember = ({
  args,
  children,
  childDataProp
}) => {
  return (
    <Query
      query={QMemberQuery}
      args={args}
      fetchPolicy="network"
    >
      {populateChildren(['member'])(children, childDataProp)}
    </Query>
  );
};

export default QMember;
