import React from 'react';
import Forms from '@@components/forms';
import useForms from '@@formsHook';
import { Link } from 'react-router-dom';

const R = require('ramda');

const Step2 = ({
  id,
  userContacts
}) => {
  const {
    fetching,
    onFormsSubmit,
    formsData
  } = useForms('firstConnectionStep2');

  return (
    <Forms
      formsData={formsData}
      columns={1}
      defaultValues={{
        subscriberId: null
      }}
      options={{
        userContacts
      }}
      onChange={() => fetching.reset()}
      onSubmit={onFormsSubmit}
      submitSection={() => (
        <div className="flex items-center mt-4">
          {fetching.isDoneWithError() && <div className="ml-4 text-purpleNavy">erreur de connexion</div>}
          <button
            className="f-button f-button-blue mr-4"
            disabled={fetching.isFetching()}
            type="submit"
          >
            {'Envoyer'}
          </button>
        </div>
      )}
    />
  );
};

export default Step2;
