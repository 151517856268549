/**
*
* WARNING : fichier généré par forms, ne pas le modifier directement
*
**/



const formData = [
  
  {
    name: 'subscriberId',
    required: false,
    input: 'hidden',
    source: '__UNDEFINED__',
    label: "__UNDEFINED__",
    placeholder: "__UNDEFINED__",
    
    handlers: {
      visible: undefined
    }
  },
  
  {
    name: 'discussionId',
    required: false,
    input: 'hidden',
    source: '__UNDEFINED__',
    label: "__UNDEFINED__",
    placeholder: "__UNDEFINED__",
    
    handlers: {
      visible: undefined
    }
  },
  
  {
    name: 'actionId',
    required: false,
    input: 'hidden',
    source: '__UNDEFINED__',
    label: "__UNDEFINED__",
    placeholder: "__UNDEFINED__",
    
    handlers: {
      visible: undefined
    }
  },
  
  {
    name: 'contract',
    required: true,
    input: 'select',
    source: 'contractOptions',
    label: "Pour quel contrat ?",
    placeholder: "Sélectionnez le contrat...",
    
    handlers: {
      visible: undefined
    }
  },
  
  {
    name: 'text',
    required: true,
    input: 'textarea',
    source: '__UNDEFINED__',
    label: "__UNDEFINED__",
    placeholder: "Votre message...",
    
    handlers: {
      visible: undefined
    }
  },
  
  {
    name: 'attachments',
    required: false,
    input: 'files',
    source: 'documentTypeOptions',
    label: "__UNDEFINED__",
    placeholder: "Ajouter une pièce jointe",
    format: "block" ,
    handlers: {
      visible: undefined
    }
  },
  
  {
    name: 'signRequestAttachments',
    required: false,
    input: 'switch',
    source: '__UNDEFINED__',
    label: "__UNDEFINED__",
    placeholder: "__UNDEFINED__",
    
    handlers: {
      visible: undefined
    }
  },
  
  {
    name: 'discussionStatusClosed',
    required: false,
    input: 'hidden',
    source: '__UNDEFINED__',
    label: "__UNDEFINED__",
    placeholder: "__UNDEFINED__",
    
    handlers: {
      visible: undefined
    }
  },
  
];

export default formData;