import { graphql } from 'react-relay';

const QMessageAttachmentTypesQuery = graphql`
  query QMessageAttachmentTypesQuery {
    references {
      messageAttachmentTypes {
        id
        label
      }
    }
  }
`;

export {
  QMessageAttachmentTypesQuery
};
