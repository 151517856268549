/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ActionFilterInput = {|
  id?: ?string,
  sender?: ?string,
|};
export type PaginateInput = {|
  limit?: ?number,
  page?: ?number,
|};
export type QAllActionsQueryVariables = {|
  actionFilter?: ?ActionFilterInput,
  paginate?: ?PaginateInput,
|};
export type QAllActionsQueryResponse = {|
  +allActions: ?{|
    +data: ?$ReadOnlyArray<?{|
      +id: ?string,
      +status: ?string,
      +type: ?string,
      +label: ?string,
      +text: ?string,
    |}>,
    +pagination: ?{|
      +total: number,
      +limit: number,
      +page: number,
      +pages: number,
    |},
  |}
|};
export type QAllActionsQuery = {|
  variables: QAllActionsQueryVariables,
  response: QAllActionsQueryResponse,
|};
*/


/*
query QAllActionsQuery(
  $actionFilter: ActionFilterInput
  $paginate: PaginateInput
) {
  allActions(filter: $actionFilter, paginate: $paginate) {
    data {
      id
      status
      type
      label
      text
    }
    pagination {
      total
      limit
      page
      pages
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "actionFilter"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "paginate"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "actionFilter"
      },
      {
        "kind": "Variable",
        "name": "paginate",
        "variableName": "paginate"
      }
    ],
    "concreteType": "PaginatedActions",
    "kind": "LinkedField",
    "name": "allActions",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Action",
        "kind": "LinkedField",
        "name": "data",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "label",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "text",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Pagination",
        "kind": "LinkedField",
        "name": "pagination",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "total",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "limit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "page",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pages",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QAllActionsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QAllActionsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7b09944020daf348cddfb02880724bc8",
    "id": null,
    "metadata": {},
    "name": "QAllActionsQuery",
    "operationKind": "query",
    "text": "query QAllActionsQuery(\n  $actionFilter: ActionFilterInput\n  $paginate: PaginateInput\n) {\n  allActions(filter: $actionFilter, paginate: $paginate) {\n    data {\n      id\n      status\n      type\n      label\n      text\n    }\n    pagination {\n      total\n      limit\n      page\n      pages\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4cde869f7282f58aa48093024e187300';

module.exports = node;
