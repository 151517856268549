import React from 'react';
import PropTypes from 'prop-types';
import { Field } from '@@components';
import EditRadioButtons from '@@pages/adherent/components/EditRadioButtons';
import { activityOptions } from '@@data';
import EditRange from '@@pages/adherent/components/EditRange';

const EditExercise = ({ activity, activityRate, isPHHUTypology }) => {
  return (
    <div className={'f-edit-adherent-subform-container'}>
      {isPHHUTypology && (
        <Field label={'Mode de Travail'}>
          <EditRadioButtons
            options={activityOptions}
            selected={activity}
            isDisabled={true}
          />
        </Field>
      )}
      <Field label={'Taux d\'activité'}>
        <EditRange
          value={activityRate}
          isDisabled={true}
        />
      </Field>
    </div>
  );
};

EditExercise.propTypes = {
  activity: PropTypes.string.isRequired,
  isPHHUTypology: PropTypes.bool.isRequired,
  activityRate: PropTypes.number.isRequired
};

export default EditExercise;
