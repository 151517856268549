import React from 'react';
import Forms from '@@components/forms';
import useForms from '@@formsHook';
import { Link } from 'react-router-dom';
import withSession from '@@root/withSession';
import { useKeycloakSession } from '../../hooks';
const R = require('ramda');

const SignIn = ({
  session
}) => {
  const {
    fetching,
    onFormsSubmit,
    formsData
  } = useForms('signIn');
  const { user, onSignIn } = useKeycloakSession();

  React.useEffect(() => {
    if (fetching.isDone()) {
      onSignIn(fetching.getPayload());
    }
  }, [fetching.isDone(), fetching.getPayload()]);

  return (
    <div>
      <Forms
        formsData={formsData}
        columns={1}
        defaultValues={{
          attachment: []
        }}
        onChange={() => fetching.reset()}
        onSubmit={onFormsSubmit}
        submitSection={() => (
          <div className="flex items-center mt-4">
            {fetching.isDoneWithError() && <div className="ml-4 text-blue">erreur de connexion</div>}
            <button
              className="f-button f-button-blue mr-4"
              disabled={fetching.isFetching()}
              type="submit"
            >
              {'CONNEXION'}
            </button>
            <Link className="f-button f-button-blue" to="/account/firstconnection">{'Première connexion'}</Link>
          </div>
        )}
      />
    </div>
  );
};

export default SignIn;
