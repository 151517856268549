import React from 'react';
import Forms from '@@components/forms';
import useForms from '@@formsHook';
import { Link } from 'react-router-dom';

const R = require('ramda');

const Step1 = ({
  id,
  onDone
}) => {
  const {
    fetching,
    onFormsSubmit,
    formsData
  } = useForms('firstConnectionStep1');

  React.useEffect(() => {
    if (fetching.isDone()) {
      onDone({ id, payload: fetching.getPayload().userContacts });
    }
  }, [fetching.isDone(), fetching.getPayload()]);

  return (
    <Forms
      formsData={formsData}
      columns={1}
      defaultValues={{
        attachment: []
      }}
      onChange={() => fetching.reset()}
      onSubmit={onFormsSubmit}
      submitSection={() => (
        <div className="flex items-center mt-4">
          {fetching.isDoneWithError() && <div className="ml-4 text-purpleNavy">erreur de connexion</div>}
          <button
            className="f-button f-button-blue mr-4"
            disabled={fetching.isFetching()}
            type="submit"
          >
            {'CONNEXION'}
          </button>
          <Link className="f-button f-button-blue" to="/account/signin/signin">{'Connexion'}</Link>
        </div>
      )}
    />
  );
};

export default Step1;
