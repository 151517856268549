
import React from 'react';
import Step1 from './firstConnection/Step1';
import Step2 from './firstConnection/Step2';
const R = require('ramda');

const FirstConnection = () => {
  const [step, setStep] = React.useState('step_1');
  const [data, setData] = React.useState();

  const onDone = ({ id, payload }) => {
    if (id === 'step_1') {
      setData(R.over(R.lensProp('userContacts'), R.always(payload)));
      setStep('step_2');
    }
  };

  return (
    <div>
      <h1>Créez votre espace pour gérer vos contrats santé prévoyance</h1>
      {step === 'step_1' && <Step1 id="step_1" onDone={onDone} />}
      {step === 'step_2' && <Step2 id="step_2" userContacts={data.userContacts} onDone={onDone} />}
    </div>
  );
};

export default FirstConnection;
