import React, { useEffect, useState } from 'react';
import { BreadCrumbButtons } from '@@pages/adherent/components/BreadCrumb';
import PropTypes from 'prop-types';
import {
  getAddress,
  getEmails,
  getMaritalStatus,
  getPhoneNumbers,
  getSubscriberIdFromMatch
} from '@@pages/adherent/utils';
import { useAdherent } from '@@hooks';
import UpdatePersonalSituationMutation from '@@mutations/UpdatePersonalSituationMutation';
import useRelayEnvironment from 'react-relay/lib/relay-hooks/useRelayEnvironment';
import { SubThemeBlock } from '@@pages/adherent/components';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import {
  EditAddress,
  EditIdentity,
  EditContact,
  EditFamily,
  EditSocialNumber
} from '@@pages/adherent/components/editInfoPerso';
import { ErrorMessage, Loading } from '@@components';
import { useHistory } from 'react-router-dom';

const TEXT_TITLE = 'Modification de la situation personnelle';

const AdherentEditPerso = ({
  pathConfig,
  match
}) => {
  const environment = useRelayEnvironment();
  const history = useHistory();
  const subscriberId = getSubscriberIdFromMatch(match);
  const { adherent } = useAdherent(subscriberId, [
    'civilStatus',
    'contact'
  ]);

  const [currentAddress, setCurrentAddress] = useState({});
  const [currentPhoneNumbers, setCurrentPhoneNumbers] = useState([]);
  const [currentEmails, setCurrentEmails] = useState([]);
  const [currentMaritalStatus, setCurrentMaritalStatus] = useState('');
  const [isWorking, setIsWorking] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const getMutatedData = () => ({
    contact: {
      emails: currentEmails,
      phoneNumbers: currentPhoneNumbers,
      address: currentAddress
    },
    civilStatus: R.compose(
      R.assoc('maritalStatus', currentMaritalStatus),
      R.propOr({}, 'civilStatus')
    )(adherent)
  });

  useEffect(() => {
    if (RA.isNotNil(adherent.contact)) {
      setCurrentAddress(getAddress(adherent));
      setCurrentPhoneNumbers(getPhoneNumbers(adherent));
      setCurrentEmails(getEmails(adherent));
    }
  }, [adherent.contact]);
  useEffect(() => {
    if (RA.isNotNil(adherent.civilStatus)) {
      setCurrentMaritalStatus(getMaritalStatus(adherent));
    }
  }, [adherent.civilStatus]);

  if (!adherent.civilStatus || !adherent.contact) return <Loading/>;

  const personalSubThemes = [{
    title: 'Identité',
    children: <EditIdentity civilStatus={adherent.civilStatus}/>
  }, {
    title: 'Coordonnées',
    children: <EditContact
      phoneNumbers={currentPhoneNumbers}
      emails={currentEmails}
      onChangePhoneNumbers={setCurrentPhoneNumbers}
      onChangeEmails={setCurrentEmails}/>
  }, {
    title: 'Adresse',
    children: <EditAddress
      address={currentAddress}
      onChangeAddress={setCurrentAddress}/>
  }, {
    title: 'Situation Familiale',
    children: <EditFamily
      maritalStatus={currentMaritalStatus}
      onChangeMaritalStatus={setCurrentMaritalStatus}/>
  }, {
    title: 'N° Sécurité Sociale ',
    children: <EditSocialNumber civilStatus={adherent.civilStatus}/>
  }];

  const subThemeToJSX = ({ title, children }) => (
    <SubThemeBlock key={title} title={title}>
      {children}
    </SubThemeBlock>
  );

  const onCancel = () => history.push(pathConfig.viewPath(subscriberId));

  const onSubmit = () => {
    setIsWorking(true);
    UpdatePersonalSituationMutation(
      environment,
      { id: subscriberId, input: getMutatedData() },
      (ok, error) => {
        setIsWorking(false);
        setErrorMessage(error);
        if (ok) {
          onCancel();
        }
      }
    );
  };

  return (
    <div className={'f-edit-adherent-page'}>
      <div className={'f-breadcrumb-container'}>
        <BreadCrumbButtons elements={[
          { name: 'Adhérents', link: pathConfig.viewPath(subscriberId) },
          { name: TEXT_TITLE }
        ]}/>
      </div>
      <div className={'f-edit-adherent-page-title'}>
        {TEXT_TITLE}
      </div>
      <div className={'f-edit-adherent-form-container'}>
        { R.map(subThemeToJSX, personalSubThemes) }
      </div>
      {RA.isNotNilOrEmpty(errorMessage) && (
        <div className={'my-2'}>
          <ErrorMessage value={errorMessage}/>
        </div>
      )}
      <div className={'f-edit-adherent-buttons'}>
        <button className={'f-button f-button-grey'} onClick={onCancel}>
          {'Annuler'}
        </button>
        <button className={'f-button f-button-coral'} onClick={onSubmit}
          disabled={isWorking}>
          {'Enregistrer'}
        </button>
      </div>
    </div>
  );
};

AdherentEditPerso.propTypes = {
  pathConfig: PropTypes.object.isRequired,
  match: PropTypes.shape({
    isExact: PropTypes.bool.isRequired,
    params: PropTypes.shape({
      subscriberId: PropTypes.string
    }).isRequired,
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }).isRequired
};

export default AdherentEditPerso;
