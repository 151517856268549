import React from 'react';
import PropTypes from 'prop-types';
import { Loading } from '@@components';
import { getField } from '../../utils';
import * as RA from 'ramda-adjunct';

const Address = ({
  contact
}) => {
  const getContactField = getField(contact);
  const addresses = [
    getContactField(['address', 'address1']),
    getContactField(['address', 'address2']),
    getContactField(['address', 'address3'])
  ];

  return <>
    {
      contact
        ? <>
          {
            addresses.map(address => (
              RA.isNotNilOrEmpty(address) && <div key={address}>{address}</div>
            ))
          }
          {`${getContactField(['address', 'zipCode'])} `}
          {`${getContactField(['address', 'city'])}`}
        </>
        : <Loading />
    }
  </>;
};

Address.propTypes = {
  contact: PropTypes.shape({
    phoneNumbers: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      type: PropTypes.string
    })),
    emails: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      type: PropTypes.string
    })),
    address: PropTypes.shape({
      address1: PropTypes.string,
      address2: PropTypes.string,
      address3: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.string,
      pnd: PropTypes.bool,
      zipcode: PropTypes.string
    })
  })
};

export default Address;
