import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

const EditRange = ({ minValue, maxValue, onChange, value, isDisabled }) => {
  const handleChange = event => {
    const newVal = parseFloat(event.target.value);
    onChange(newVal);
  };

  const percent = (value - minValue) / (maxValue - minValue) * 100;
  const bubbleStylePos = R.lt(percent, 50)
    ? { left: `calc(${percent}%)` }
    : { right: `calc(${100 - percent}%)` };
  const sliderStyleBg = {
    background:
      `linear-gradient(to right, #b5bcc4 ${percent}%, #dae3ed ${percent}%)`
  };

  return (
    <div className={'f-range-edit-adherent-container'}>
      <div className={'f-range-edit-adherent-bubble-row'}>
        <div className={'f-range-edit-adherent-bubble'}
          style={bubbleStylePos}
        >
          {`${value} %`}
        </div>
      </div>
      <input className={'f-range-edit-adherent-slider'}
        style={sliderStyleBg}
        min={`${minValue}`}
        max={`${maxValue}`}
        type="range"
        onChange={handleChange}
        value={value}
        disabled={isDisabled}
      />
      <ul className={'f-range-edit-adherent-ticks'}>
        <li>{'0 %'}</li>
        <li>{'50 %'}</li>
        <li>{'100 %'}</li>
      </ul>
    </div>
  );
};

EditRange.propTypes = {
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool.isRequired
};

EditRange.defaultProps = {
  minValue: 0,
  maxValue: 100,
  onChange: () => {},
  value: 75,
  isDisabled: false
};

export default EditRange;
