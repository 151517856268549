import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { typologies } from '@@enum';
import moment from 'moment';

const labels = {
  civility: {
    M: 'M.',
    F: 'Mme',
    FF: 'Mlle',
    Dr: 'Dr.'
  },
  maritalStatus: {
    celibataire: 'Célibataire',
    concubin: 'Concubin(e)',
    divorce: 'Divorcé(e)',
    marie: 'Marié(e)',
    pacse: 'Pacsé(e)',
    veuf: 'Veuf(ve)'
  },
  documentType: {
    DOCUMENT_ADHESION: 'Document d\'adhésion',
    ATTESTATION: 'Attestation',
    ECHEANCIER: 'Échéancier',
    JUSTIFICATIF_DOMICILE: 'Justificatif de domicile'
  }
};

export const getLabel = label => value => R.pathOr('', [label, value], labels);

export const getField = (object) => (path) => R.pathOr('', path, object);

export const getContractField = contract => getField(contract);

export const getPHHUContractIfPresent = R.when(R.any(R.pathEq(['detail', 'typology'], typologies.PHHU)), R.filter(R.pathEq(['detail', 'typology'], typologies.PHHU)));

export const findFunction = (contracts) => R.compose(
  R.head,
  R.map(R.path(['detail', 'function']))
)(getPHHUContractIfPresent(contracts));

export const findActivity = (contracts) => R.compose(
  R.head,
  R.map(R.path(['detail', 'activity'])),
  R.filter(R.pathEq(['detail', 'typology'], typologies.PHHU))
)(getPHHUContractIfPresent(contracts));

export const findEchelon = (contracts) => R.compose(
  R.head,
  R.filter(RA.isNotEmpty),
  R.map(
    contract => R.propOr('', 'index', R.find(R.propEq('type', 'ECHELON'), getContractField(contract)(['pricingOptions'])))
  )
)(getPHHUContractIfPresent(contracts));

export const getActivityRate = (contracts) => R.compose(
  R.head,
  R.map(R.prop('activityRate')),
  R.filter(R.pathSatisfies(R.includes(R.__, [typologies.PHHU, typologies.INTERNE]), ['detail', 'typology']))
)(getPHHUContractIfPresent(contracts));

export const typologyEq = (typology, contracts) => R.any(R.pathEq(['detail', 'typology'], typology), contracts);

export const formatSocialNumber = (socialNumber) => {
  const res = R.match(/(\d)(\d{2})(\d{2})(\d{2})(\d{3})(\d{3})(\d{2})?/, socialNumber);
  return R.compose(R.join(' '), R.reject(R.isNil), R.drop(1))(res);
};

export const getSubscriberIdFromMatch = R.pathOr('', ['params', 'subscriberId']);

export const getHospitalName = (professionalInformation) => {
  const nameAndCity = R.applySpec({
    name: R.pathOr('', ['hospital', 'details', 'name']),
    city: R.pathOr('', ['hospital', 'details', 'city'])
  })(professionalInformation);
  if (R.any(RA.isNilOrEmpty, [nameAndCity.name, nameAndCity.city])) {
    return '';
  }
  return `${nameAndCity.city} - ${nameAndCity.name}`;
};

export const getSpecialty = (professionalInformation) => {
  return R.propOr('', 'specialty')(professionalInformation);
};

export const getAddress = R.pathOr('', ['contact', 'address']);
export const getPhoneNumbers = R.pathOr('', ['contact', 'phoneNumbers']);
export const getEmails = R.pathOr('', ['contact', 'emails']);
export const getMaritalStatus = R.pathOr('', ['civilStatus', 'maritalStatus']);

// mirrors the validation in server/graphql/resolver/AdherentResolverValidator.js
export const getPhoneNumberType = phoneNumber => {
  const prefix = R.take(2, String(phoneNumber));
  if (R.includes(prefix, ['01', '02', '03', '04', '05', '09'])) {
    return 'fix_perso';
  } else {
    return 'mobile_perso';
  }
};

const boolToNameFr = R.cond([
  [R.equals(true), R.always('oui')],
  [R.equals(false), R.always('non')],
  [R.T, R.always('')]
]);

// Reference mapping for the csv export of subscribers. The order is preserved.
export const subscriberKeysToNameFr = [
  { key: 'firstName', label: 'Prénom', transform: R.trim },
  { key: 'lastName', label: 'Nom de famille', transform: R.trim },
  { key: 'id', label: 'N° adhérent' },
  { key: 'maidenName', label: 'Nom de jeune fille', transform: R.trim },
  { key: 'onBoarded', label: 'Onboardé', transform: boolToNameFr },
  { key: 'familyStatus', label: 'Situation familiale' },
  { key: 'birthDate', label: 'Date de naissance' },
  { key: 'insee', label: 'N° Sécurité Sociale' },
  { key: 'contract', label: 'Contrats souscrits' }
];

// Actual CSV headers, in order
export const SUBSCRIBER_CSV_HEADER =
  R.map(R.propOr('', 'label'), subscriberKeysToNameFr);

// Given a subscriber and a reference column, find what to write in the CSV
// file for that column (ex. onBoarded:'true' ==> 'oui')
const getSubscriberData = (subscriber) => (reference) => {
  const transform = R.propOr(R.identity, 'transform')(reference);
  const key = R.propOr('', 'key')(reference);
  return R.compose(transform, R.propOr('', key))(subscriber);
};

// Convert a subscriber object into a flat array of data
export const subscriberToDataArray = (subscriber) =>
  R.map(getSubscriberData(subscriber), subscriberKeysToNameFr);

export const getExportCSVFileName = () => {
  const dateTime = moment(new Date()).format('DD-MM-YYYY_HH-mm-ss');
  return `export-adherents-${dateTime}.csv`;
};
