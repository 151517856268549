import React from 'react';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import ReactMarkdown from 'react-markdown';
import * as R from 'ramda';

const SearchInput = ({
  onChange,
  loadOptions,
  classNamePrefix,
  placeholder,
  logo,
  isMulti,
  dropdownIndicator,
  label,
  singleValue,
  multiValue
}) => {
  const customStyles = {
    valueContainer: (base) => ({
      ...base,
      padding: '0px',
      paddingLeft: '8px'
    })
  };

  const DropdownIndicator = props => {
    return (
      <>
        {
          dropdownIndicator
            ? <components.DropdownIndicator {...props}>
              {props.children}
            </components.DropdownIndicator>
            : null
        }
      </>
    );
  };

  const MultiValueLabel = props => {
    return (
      <components.MultiValueLabel {...props}>
        {R.without('*', props.children)}
      </components.MultiValueLabel>
    );
  };

  const Option = props => {
    return (
      <components.Option {...props}>
        <ReactMarkdown>{props.children}</ReactMarkdown>
      </components.Option>
    );
  };

  const SingleValue = props => {
    return (
      <components.SingleValue {...props}>
        {R.without('*', props.children)}
      </components.SingleValue>
    );
  };

  const LoadingMessage = props => {
    return (
      <components.LoadingMessage className="flex justify-start" {...props}>
        {'Chargement en cours...'}
      </components.LoadingMessage>
    );
  };

  const NoOptionsMessage = props => {
    return (
      <components.NoOptionsMessage className="flex justify-start" {...props}>
        {'Pas de résultat'}
      </components.NoOptionsMessage>
    );
  };

  const IndicatorSeparator = () => null;

  return (
    <>
      {label && <div className={'f-block-field-label'}>{label}</div>}
      <div className={'f-select relative'}>
        {logo && (
          <img className={'absolute pl-3 py-2 z-10'} src={logo} alt={'logo'}/>
        )}
        <AsyncSelect
          isClearable
          styles={customStyles}
          onChange={onChange}
          placeholder={placeholder}
          loadOptions={debounce(loadOptions, 300)}
          classNamePrefix={classNamePrefix}
          value={isMulti ? multiValue : singleValue}
          components={{
            DropdownIndicator,
            IndicatorSeparator,
            Option,
            SingleValue,
            LoadingMessage,
            NoOptionsMessage,
            MultiValueLabel
          }}
          isMulti={isMulti}
        />
      </div>
    </>
  );
};

SearchInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  loadOptions: PropTypes.func,
  classNamePrefix: PropTypes.string,
  placeholder: PropTypes.string,
  logo: PropTypes.node,
  isMulti: PropTypes.bool,
  dropdownIndicator: PropTypes.bool,
  label: PropTypes.string,
  multiValue: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),
  singleValue: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })
};

SearchInput.defaultProps = {
  multiValue: [],
  singleValue: null,
  onChange: () => {}
};

export default SearchInput;
