import React from 'react';
import { useAdherent } from '@@hooks';
import { Link } from 'react-router-dom';
import { useKeycloakSession } from '../../hooks';
const R = require('ramda');

const getField = (object) => (path) => R.pathOr('', R.split('.', path), object);

const Index = ({
  pathConfig
}) => {
  const { user } = useKeycloakSession();
  const { adherent } = useAdherent(user.customerId, ['civilStatus', 'contact', 'professionalInformation']);

  if (R.isNil(adherent.civilStatus) || R.isNil(adherent.contact) || R.isNil(adherent.professionalInformation)) return null;

  const getCivilStatusField = getField(adherent.civilStatus);
  const getContactField = getField(adherent.contact);
  const getProfessionalInformationField = getField(adherent.professionalInformation);

  return (
    <>
      <div className="flex">
        <div className="f-block">
          <ul>
            <li>{`${getCivilStatusField('lastName')} ${getCivilStatusField('firstName')}`}</li>
            <li>{`né(e) le ${getCivilStatusField('birthDate')}`}</li>
            <li>{`N° de sécurité sociale ${getCivilStatusField('nic')}`}</li>
          </ul>
          <Link className="f-button-sm" to={pathConfig.personalInformation.editPath}>Modifier</Link>
        </div>
        <div className="f-block">
          <ul>
            <li>{`${getCivilStatusField('maritalStatus')}`}</li>
            <li>{`${getCivilStatusField('nbChild')} enfant(s)`}</li>
          </ul>
          <Link className="f-button-sm" to={pathConfig.familyStatus.editPath}>Modifier</Link>
        </div>
      </div>
      <div className="flex">
        <div className="f-block">
          <ul>
            {getContactField('phoneNumbers').map((o, i) => (
              <li key={i}>{o.value}</li>
            ))}
          </ul>
          <Link className="f-button-sm" to={pathConfig.phoneNumbers.editPath}>Modifier</Link>
        </div>
        <div className="f-block">
          <ul>
            {getContactField('emails').map((o, i) => (
              <li key={i}>{o.value}</li>
            ))}
          </ul>
          <Link className="f-button-sm" to={pathConfig.emails.editPath}>Modifier</Link>
        </div>
        <div className="f-block">
          <ul>
            <li>{getContactField('address.address1')}</li>
            <li>{getContactField('address.address2')}</li>
            <li>{getContactField('address.address3')}</li>
            <li>{getContactField('address.zipCode')}</li>
            <li>{getContactField('address.city')}</li>
            <li>{getContactField('address.country')}</li>
            <li>{getContactField('address.pnd')}</li>
          </ul>
          <Link className="f-button-sm" to={pathConfig.address.editPath}>Modifier</Link>
        </div>
      </div>
    </>
  );
};

export default Index;
