import * as R from 'ramda';
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

const EditRadioButtons = ({ options, selected, onChange, isDisabled }) => {
  const onClick = (event) => {
    onChange(event.target.value);
  };

  const optionToJSX = ({ value, label }) => {
    const isSelected = R.equals(R.toLower(selected), R.toLower(value));
    return (
      <label
        key={value}
        className={ classNames({
          'f-button-toggle-selected': isSelected,
          'f-button-toggle': !isSelected,
          'f-button-toggle-disabled': isDisabled
        })}>
        <input
          type={'radio'}
          value={value}
          className={'appearance-none'}
          onClick={onClick}
          disabled={isDisabled}
        />
        <span>{label}</span>
      </label>
    );
  };

  return (
    <div className={'f-button-toggle-grid'}>
      {R.map(optionToJSX, options)}
    </div>
  );
};

EditRadioButtons.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })).isRequired,
  selected: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired
};

EditRadioButtons.defaultProps = {
  options: [],
  selected: '',
  onChange: () => {},
  isDisabled: false
};

export default EditRadioButtons;
