/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PaginateInput = {|
  limit?: ?number,
  page?: ?number,
|};
export type QAdherentDocumentInfoQueryVariables = {|
  subscriberId: string,
  paginate?: ?PaginateInput,
|};
export type QAdherentDocumentInfoQueryResponse = {|
  +documentsByAdherent: ?{|
    +data: ?$ReadOnlyArray<?{|
      +fileId: ?string,
      +fileSource: ?string,
      +title: ?string,
      +fileName: ?string,
      +type: ?string,
    |}>,
    +pagination: ?{|
      +total: number,
      +limit: number,
      +page: number,
      +pages: number,
    |},
  |}
|};
export type QAdherentDocumentInfoQuery = {|
  variables: QAdherentDocumentInfoQueryVariables,
  response: QAdherentDocumentInfoQueryResponse,
|};
*/


/*
query QAdherentDocumentInfoQuery(
  $subscriberId: ID!
  $paginate: PaginateInput
) {
  documentsByAdherent(subscriberId: $subscriberId, paginate: $paginate) {
    data {
      fileId
      fileSource
      title
      fileName
      type
    }
    pagination {
      total
      limit
      page
      pages
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "paginate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "subscriberId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "paginate",
        "variableName": "paginate"
      },
      {
        "kind": "Variable",
        "name": "subscriberId",
        "variableName": "subscriberId"
      }
    ],
    "concreteType": "PaginatedDocumentsInfos",
    "kind": "LinkedField",
    "name": "documentsByAdherent",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DocumentInfo",
        "kind": "LinkedField",
        "name": "data",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fileId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fileSource",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fileName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Pagination",
        "kind": "LinkedField",
        "name": "pagination",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "total",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "limit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "page",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pages",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QAdherentDocumentInfoQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "QAdherentDocumentInfoQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "04587c44ac175734cab0d51d27749db6",
    "id": null,
    "metadata": {},
    "name": "QAdherentDocumentInfoQuery",
    "operationKind": "query",
    "text": "query QAdherentDocumentInfoQuery(\n  $subscriberId: ID!\n  $paginate: PaginateInput\n) {\n  documentsByAdherent(subscriberId: $subscriberId, paginate: $paginate) {\n    data {\n      fileId\n      fileSource\n      title\n      fileName\n      type\n    }\n    pagination {\n      total\n      limit\n      page\n      pages\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c4b193a71e8d436ddf12b64affe91019';

module.exports = node;
