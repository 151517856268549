import React from 'react';
import PropTypes from 'prop-types';
import { Field } from '@@components';
import { liberalOptions } from '@@data';
import EditRadioButtons from '@@pages/adherent/components/EditRadioButtons';

const EditLiberal = ({ liberal }) => {
  return (
    <div className={'f-edit-adherent-subform-container'}>
      <Field label={'Activité Libérale'}>
        <EditRadioButtons
          options={liberalOptions}
          selected={`${liberal}`}
          isDisabled={true}
        />
      </Field>
    </div>
  );
};

EditLiberal.propTypes = {
  liberal: PropTypes.bool.isRequired
};

export default EditLiberal;
