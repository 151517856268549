/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QMemberQueryVariables = {|
  id?: ?string
|};
export type QMemberQueryResponse = {|
  +member: ?{|
    +id: ?string,
    +firstName: ?string,
    +lastName: ?string,
    +groupes: ?$ReadOnlyArray<?{|
      +id: ?string,
      +name: ?string,
      +completeName: ?string,
    |}>,
  |}
|};
export type QMemberQuery = {|
  variables: QMemberQueryVariables,
  response: QMemberQueryResponse,
|};
*/


/*
query QMemberQuery(
  $id: ID
) {
  member(id: $id) {
    id
    firstName
    lastName
    groupes {
      id
      name
      completeName
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "Member",
    "kind": "LinkedField",
    "name": "member",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Group",
        "kind": "LinkedField",
        "name": "groupes",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "completeName",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QMemberQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QMemberQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "1ba64d9c46dc84c0955b4515463bf796",
    "id": null,
    "metadata": {},
    "name": "QMemberQuery",
    "operationKind": "query",
    "text": "query QMemberQuery(\n  $id: ID\n) {\n  member(id: $id) {\n    id\n    firstName\n    lastName\n    groupes {\n      id\n      name\n      completeName\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c176e7cc4175beb9bf3a70dcbc3ac216';

module.exports = node;
