import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Loading } from '@@components';
import Field from '@@components/Field';

const EditSocialNumber = ({ civilStatus }) => {
  if (R.isNil(civilStatus)) return <Loading/>;
  const nic = R.propOr('', 'nic', civilStatus);

  return (
    <div className={'f-edit-adherent-subform-container'}>
      <Field label={'Numéro'}>
        <input
          className={'f-input f-edit-adherent-text-input'}
          value={nic}
          disabled
        />
      </Field>
    </div>
  );
};

EditSocialNumber.propTypes = {
  civilStatus: PropTypes.object
};

export default EditSocialNumber;
