import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

import Select, { components } from 'react-select';

const Option = props => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type='checkbox'
          checked={ R.propOr(false, 'isSelected', props) }
          onChange={ () => null }
        />{' '}
        <label>{ R.propOr(false, 'label', props) }</label>
      </components.Option>
    </div>
  );
};

const ValueContainer = ({ children, ...props }) => {
  const { title, lengthSelection, showPill } = props.selectProps;
  return (
    <components.ValueContainer {...props}>
      <div className={'f-select-checkbox-dropdown-value'}>
        <p>{title}</p>
        {
          showPill && (
            <div className={'f-select-pill-notification'}>
              {lengthSelection}
            </div>)
        }
      </div>
      {children}
    </components.ValueContainer>
  );
};

const Placeholder = () => null;

const MultiValue = () => null;

const IndicatorSeparator = () => null;

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <div className={'f-select-arrow-box'}>
        <img src={'/icons/dropdown.svg'} alt={'dropdown'} />
      </div>
    </components.DropdownIndicator>
  );
};

const createOptionsGroup = (groupData, index) => {
  return {
    label: R.gt(index, 0) && (
      <div className={'border border-light-grey'} />
    ),
    options: groupData
  };
};

const sortAndAssocIndex = (options, index) => {
  return R.compose(
    R.map(R.assoc('_index', index)),
    R.sort(R.ascend(R.prop('label')))
  )(options);
};

const CheckboxDropdown = ({ title, inputData, showPill }) => {
  const splitOnChange = R.map(R.prop('onChange'))(inputData);
  const indexedOptions = R.compose(
    RA.mapIndexed(sortAndAssocIndex),
    R.map(R.prop('options'))
  )(inputData);

  const allOptions = R.reduce(R.concat, [], indexedOptions);
  const optionsWithHeader = RA.mapIndexed(createOptionsGroup)(indexedOptions);

  const handleChange = selected => {
    const groupedSelected = R.groupBy(R.prop('_index'))(selected);

    for (let i = 0; i < R.length(inputData); i++) {
      const selection = R.propOr([], i)(groupedSelected);
      handleIndexChange(i, selection);
    }
  };

  // given an index and a list of selected items (presumably with that index),
  // apply the associated change function (for that idx) with the new selection
  const handleIndexChange = (index, selected) => {
    const iOptions = indexedOptions[index];
    const iOnChange = splitOnChange[index];
    // check if any of the selected items matches the passed item value
    const isSelected = item => {
      const itemValue = R.propOr(-1, 'value', item);
      return R.any(R.propEq('value', itemValue))(selected);
    };
    const mapper = item => R.compose(
      R.assoc('active', isSelected(item)),
      R.dissoc('_index')
    )(item);
    const newData = R.map(mapper)(iOptions);

    iOnChange(newData, selected);
  };

  const resetSelected = () => {
    inputData.forEach(({ options, onChange }) => {
      const newThemes = R.map(R.assoc('active', false))(options);
      onChange(newThemes);
    });
  };

  const resetButton = (
    <div
      className={classNames('f-select-button-reset', { invisible: !showPill })}
      onClick={resetSelected}
    >
      <img src={'/icons/reset.svg'} alt={'icon-reset'} />
    </div>
  );

  const currentSelection = R.filter(R.propEq('active', true), allOptions);
  const lengthSelection = R.length(currentSelection);

  return (
    <div className={'f-select-checkbox-dropdown-frame'}>
      <Select
        className={'f-select-container'}
        classNamePrefix={'f-select-checkbox-dropdown'}
        options={ optionsWithHeader }
        onChange={ handleChange }
        styles={{
          dropdownIndicator: () => ({}),
          control: () => ({}),
          option: (styles) => {
            return {
              ...styles,
              backgroundColor: 'white',
              color: 'black',
              ':active': {
                ...styles[':active'],
                backgroundColor: 'white'
              }
            };
          }
        }}
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        isSearchable={false}
        isClearable={false}
        components={{
          Option,
          MultiValue,
          Placeholder,
          ValueContainer,
          IndicatorSeparator,
          DropdownIndicator
        }}
        value={currentSelection}
        lengthSelection={lengthSelection}
        showPill={showPill}
        title={title}
      />
      {resetButton}
    </div>
  );
};

const inputDataOptionsPropTypes = PropTypes.arrayOf(PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired
})).isRequired;

CheckboxDropdown.propTypes = {
  title: PropTypes.string.isRequired,
  inputData: PropTypes.arrayOf(PropTypes.shape({
    options: inputDataOptionsPropTypes,
    onChange: PropTypes.func.isRequired
  })),
  showPill: PropTypes.bool.isRequired
};

CheckboxDropdown.defaultProps = {
  title: '',
  inputData: [{ options: [], onChange: () => {} }],
  showPill: true
};

export default CheckboxDropdown;
export { inputDataOptionsPropTypes };
