import { graphql } from 'react-relay';

const QAdherentProfessionalInformationQuery = graphql`
  query QAdherentProfessionalInformationQuery($id: ID!) {
    adherent(id: $id) {
      id
      professionalInformation {
        specialty
        hospital {
          id
          startDate
          endDate
          details {
            name
            address1
            address2
            address3
            zipCode
            city
            country
            adherent
            subscriber
            payer
          }
        }
      }
    }
  }
`;

export {
  QAdherentProfessionalInformationQuery
};
