import React from 'react';
import PropTypes from 'prop-types';
import { Loading } from '@@components';

const LiberalActivity = ({
  contracts,
  isPHHUTypology,
  isLiberalTypology
}) => {
  return <>
    {
      contracts
        ? <div>{isPHHUTypology && `${isLiberalTypology ? 'Oui' : 'Non'}`}</div>
        : <Loading />
    }
  </>;
};

LiberalActivity.propTypes = {
  isPHHUTypology: PropTypes.bool,
  isLiberalTypology: PropTypes.bool,
  contracts: PropTypes.array
};

export default LiberalActivity;
