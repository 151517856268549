/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AdherentFilterSort = "ASC" | "DESC" | "%future added value";
export type PaginateInput = {|
  limit?: ?number,
  page?: ?number,
|};
export type AdherentFilterInput = {|
  birthDate?: ?string,
  contract?: ?string,
  firstName?: ?string,
  id?: ?string,
  insee?: ?string,
  lastName?: ?string,
  multiProps?: ?string,
  name?: ?string,
  onBoarded?: ?string,
  sort?: ?AdherentFilterSort,
|};
export type QAdherentsWithPaginationQueryVariables = {|
  paginate?: ?PaginateInput,
  filter?: ?AdherentFilterInput,
|};
export type QAdherentsWithPaginationQueryResponse = {|
  +adherentsWithPagination: ?{|
    +data: ?$ReadOnlyArray<?{|
      +id: ?string,
      +firstName: ?string,
      +lastName: ?string,
      +maidenName: ?string,
      +onBoarded: ?boolean,
      +familyStatus: ?string,
      +birthDate: ?string,
      +insee: ?string,
      +contract: ?string,
    |}>,
    +pagination: ?{|
      +total: number,
      +limit: number,
      +page: number,
      +pages: number,
    |},
  |}
|};
export type QAdherentsWithPaginationQuery = {|
  variables: QAdherentsWithPaginationQueryVariables,
  response: QAdherentsWithPaginationQueryResponse,
|};
*/


/*
query QAdherentsWithPaginationQuery(
  $paginate: PaginateInput
  $filter: AdherentFilterInput
) {
  adherentsWithPagination(paginate: $paginate, filter: $filter) {
    data {
      id
      firstName
      lastName
      maidenName
      onBoarded
      familyStatus
      birthDate
      insee
      contract
    }
    pagination {
      total
      limit
      page
      pages
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "paginate"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      },
      {
        "kind": "Variable",
        "name": "paginate",
        "variableName": "paginate"
      }
    ],
    "concreteType": "PaginatedAdherents",
    "kind": "LinkedField",
    "name": "adherentsWithPagination",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Adherent",
        "kind": "LinkedField",
        "name": "data",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "maidenName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "onBoarded",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "familyStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "birthDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "insee",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "contract",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Pagination",
        "kind": "LinkedField",
        "name": "pagination",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "total",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "limit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "page",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pages",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QAdherentsWithPaginationQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "QAdherentsWithPaginationQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "852e24e7440729312cb2d656fcc7c08f",
    "id": null,
    "metadata": {},
    "name": "QAdherentsWithPaginationQuery",
    "operationKind": "query",
    "text": "query QAdherentsWithPaginationQuery(\n  $paginate: PaginateInput\n  $filter: AdherentFilterInput\n) {\n  adherentsWithPagination(paginate: $paginate, filter: $filter) {\n    data {\n      id\n      firstName\n      lastName\n      maidenName\n      onBoarded\n      familyStatus\n      birthDate\n      insee\n      contract\n    }\n    pagination {\n      total\n      limit\n      page\n      pages\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6523b1385a84f06faff31b14c20b29a6';

module.exports = node;
