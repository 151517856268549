/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FirstConnectionStep2Input = {|
  subscriberId: string
|};
export type FirstConnectionStep2MutationVariables = {|
  input: FirstConnectionStep2Input
|};
export type FirstConnectionStep2MutationResponse = {|
  +firstConnectionStep2: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type FirstConnectionStep2Mutation = {|
  variables: FirstConnectionStep2MutationVariables,
  response: FirstConnectionStep2MutationResponse,
|};
*/


/*
mutation FirstConnectionStep2Mutation(
  $input: FirstConnectionStep2Input!
) {
  firstConnectionStep2(input: $input) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FirstConnectionStep2MutationResponse",
    "kind": "LinkedField",
    "name": "firstConnectionStep2",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FirstConnectionStep2Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FirstConnectionStep2Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d70dab5bde8552d630f5bf6006ed7b89",
    "id": null,
    "metadata": {},
    "name": "FirstConnectionStep2Mutation",
    "operationKind": "mutation",
    "text": "mutation FirstConnectionStep2Mutation(\n  $input: FirstConnectionStep2Input!\n) {\n  firstConnectionStep2(input: $input) {\n    ok\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'df200e869d26fac3f19fda03903634f2';

module.exports = node;
