import React from 'react';
import { graphql } from 'react-relay';
import Query from '../Query';
import {
  populateChildren
} from './toolbox';

const QUserSessionQuery = graphql`
  query QUserSessionQuery {
    userSession {
      id
      username
      email
      role
      firstname
      lastname
      customerId
    }
  }
`;

const QUserSession = ({
  args,
  children,
  childDataProp,
  mockData
}) => {
  return (
    <Query
      query={QUserSessionQuery}
      args={args}
      fetchPolicy="network"
      mockKey="QUser"
      mockData={mockData}
    >
      {populateChildren(['userSession'])(children, childDataProp)}
    </Query>
  );
};

export default QUserSession;

export {
  QUserSessionQuery
};
