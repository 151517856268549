import {
  graphql,
  requestSubscription
} from 'react-relay';

const R = require('ramda');
const subscription = graphql`
  subscription DiscussionNewMessageNotificationSubscription {
    discussionNewMessageNotification {
      title
    }
  }
`;

export default (environment, _, notifier) => {
  requestSubscription(
    environment,
    {
      subscription,
      variables: {},
      updater: (store, { discussionNewMessageNotification }) => {
        if (R.is(Function, notifier)) notifier(discussionNewMessageNotification);
      },
      onError: error => {
        console.error('SUBSCRIPTION ERROR:', error);
      }
    }
  );
};
