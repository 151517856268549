const options = [{
  value: 'mobile_pro',
  label: 'Mobile Pro'
}, {
  value: 'mobile_perso',
  label: 'Mobile Perso'
}, {
  value: 'fix_pro',
  label: 'Fixe Pro'
}, {
  value: 'fix_perso',
  label: 'Fixe Perso'
}, {
  value: 'mobile_certified',
  label: 'Mobile certifié'
}];

export default options;
