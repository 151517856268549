import React from 'react';
const R = require('ramda');

const Field = ({ label, value, children, alignedLabel, withBottomFieldMargin }) => (
  <div className='f-block-field'>
    <div className="f-block-field-label">{label}</div>
    {R.isNil(children) && <div>{value}</div>}
    {!R.isNil(children) && children}
    {withBottomFieldMargin && <span className={'ml-2'}>{alignedLabel}</span>}
  </div>
);

export default Field;

Field.defaultProps = {
  withBottomFieldMargin: true
};
