import React from 'react';
import { QCmsContactQuery } from '@@queries/QCmsContact';
import { QCmsHeaderFooterQuery } from '@@queries/QCmsHeaderFooter';
import { QCmsDocumentsQuery } from '@@queries/QCmsDocuments';
import { QCmsPageQuery } from '@@queries/QCmsPage';
const R = require('ramda');
const { fetchQuery } = require('react-relay');
const { useRelayEnvironment } = require('react-relay');

export const useCms = (ids, args) => {
  const environment = useRelayEnvironment();
  const [content, setContent] = React.useState({
    civil: null,
    header: null,
    footer: null,
    contact: null,
    page: null,
    documents: []
  });

  const idsRef = React.useRef();
  React.useEffect(() => {
    if (R.equals(idsRef.current, ids)) return;
    idsRef.current = ids;
    if (R.contains('header', ids) || R.contains('footer', ids)) getHeaderFooter();
    if (R.contains('contact', ids)) getContact();
  }, [ids]);

  const pageIdRef = React.useRef();
  React.useEffect(() => {
    if (!R.contains('page', ids)) return;
    if (R.equals(pageIdRef.current, args.pageId)) return;
    pageIdRef.current = args.pageId;
    getPage({ pageId: args.pageId });
  }, [R.propOr(null, 'pageId', args)]);

  const documentsFilterRef = React.useRef();
  React.useEffect(() => {
    if (!R.contains('documents', ids)) return;
    if (R.equals(documentsFilterRef.current, args.documentsFilter)) return;
    documentsFilterRef.current = args.documentsFilter;
    getDocuments({ filter: R.propOr({}, 'documentsFilter', args) });
  }, [R.propOr(null, 'documentsFilter', args)]);

  const getContact = async () => {
    const { cms } = await fetchQuery(environment, QCmsContactQuery).toPromise();
    setContent(R.compose(
      R.assoc('contact', cms.contact)
    ));
  };

  const getHeaderFooter = async () => {
    const { cms } = await fetchQuery(environment, QCmsHeaderFooterQuery).toPromise();
    setContent(R.compose(
      R.assoc('footer', cms.footer),
      R.assoc('header', cms.header)
    ));
  };

  const getPage = async ({ pageId }) => {
    const { cms } = await fetchQuery(environment, QCmsPageQuery, { pageId }).toPromise();
    setContent(R.compose(
      R.assoc('page', cms.page)
    ));
  };

  const getDocuments = async ({ filter }) => {
    if (R.has('year', filter)) {
      filter = R.assoc('year', parseInt(filter.year), filter);
    }
    const { cms } = await fetchQuery(environment, QCmsDocumentsQuery, { documentsFilter: filter }).toPromise();
    setContent(R.compose(
      R.assoc('documents', cms.documents)
    ));
  };

  return {
    ...content
  };
};

export default useCms;
