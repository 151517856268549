import { values } from 'ramda';
import React from 'react';
const R = require('ramda');
const ExpectedAttachments = ({
  types,
  attachedTypes,
  value
}) => {
  const indexedTypes = R.indexBy(R.prop('id'), types);
  if (R.isNil(value)) return null;
  return (
    <div>
      {value.mandatory.map((items, index) => {
        if (R.length(items) === 1) {
          return <div key={index}>{indexedTypes[items[0]].label}&nbsp;{R.includes(items[0], attachedTypes) ? 'ok' : ''}</div>;
        }
        const documents = R.compose(
          R.join(' ou '),
          R.map(v => indexedTypes[v].label)
        )(items);
        const isIn = R.reduce((acc, v) => acc || R.includes(v, attachedTypes), false)(items);
        return (
          <div key={index}>{documents}&nbsp;{isIn ? 'ok' : ''}</div>
        );
      })}
    </div>
  );
};

ExpectedAttachments.defaultProps = {
  attachedTypes: []
};

export default ExpectedAttachments;
