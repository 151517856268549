import React from 'react';
import PropTypes from 'prop-types';
import Message from './Message';
import * as R from 'ramda';
import { discussionStatus } from '@@enum';

const Messages = ({
  items,
  pagination,
  getMemberNames,
  getAdherentNames,
  status,
  subscriberId
}) => {
  // * Sort messages for having them chronologically
  const cleanItems = R.compose(
    R.sortBy(R.prop('order')),
    R.defaultTo([])
  );

  const [messages, setMessages] = React.useState([]);

  const messageListRef = React.useRef(null);

  const scrollToBottom = () => {
    if (R.isNil(messageListRef.current)) return;
    messageListRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'nearest'
    });
  };

  React.useEffect(async () => {
    await setMessages(cleanItems(items));
    scrollToBottom();
  }, [items]);

  return (
    <ul className="f-messaging-messages">
      {messages.map((item) => <Message
        key={item.id}
        value={item}
        getMemberNames={getMemberNames}
        getAdherentNames={getAdherentNames}
        messageId={item.id}
        subscriberId={subscriberId}
      />)}
      <div ref={messageListRef}></div>
      {
        R.equals(status, discussionStatus.CLOSED) && (
          <div className="grid grid-cols-10">
            <div className="f-messaging-message f-messaging-message-closed-message" >{'Votre conseiller a clos la discussion, vous ne pouvez plus y apporter de modifications. Si vous souhaitez nous communiquer des informations complémentaires, vous avez la possibilité de nous adresser une nouvelle demande.'}</div>
          </div>
        )
      }
    </ul>
  );
};

Messages.propTypes = {
  pagination: PropTypes.shape({
    total: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    pages: PropTypes.number.isRequired
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      attachments: PropTypes.array,
      dates: PropTypes.shape({
        creation: PropTypes.string.isRequired,
        lastUpdate: PropTypes.string.isRequired
      }).isRequired,
      id: PropTypes.string.isRequired,
      order: PropTypes.number.isRequired,
      recipients: PropTypes.arrayOf(
        PropTypes.string
      ).isRequired,
      sender: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  getMemberNames: PropTypes.func.isRequired,
  getAdherentNames: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  subscriberId: PropTypes.string
};

export default Messages;
