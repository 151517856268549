import React from 'react';
import PropTypes from 'prop-types';
import { paperclipIcon } from '@@images';
import { Loading } from '@@components';
import { getLabel, getField } from '../../utils';
import * as R from 'ramda';
import getFormatedAdherentBirthDate from '@@lib/formatBirthDate';

const Identity = ({
  civilStatus
}) => {
  const getCivilStatusField = getField(civilStatus);
  return <>
    {
      civilStatus
        ? <>
          {`${getLabel('civility')(getCivilStatusField(['civility']))} ${R.prop('lastName', civilStatus)} 
        ${R.prop('firstName', civilStatus)} 
        (né(e) ${R.prop('maidenName', civilStatus) || R.prop('firstName', civilStatus)})`} <br/>
          { `Né(e) le ${getFormatedAdherentBirthDate(civilStatus)}` }
          <div className="f-attachment-item-label mt-2">
            <img src={paperclipIcon} alt={'Icône pièce jointe'}/>
            {/* TODO récupérer le nom de la pièce jointe */}
            <div className="px-2">{'carte Nationale d\'identité'}</div>
          </div>
        </>
        : <Loading />
    }
  </>;
};

Identity.propTypes = {
  civilStatus: PropTypes.shape({
    birthDate: PropTypes.string,
    civility: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    maidenName: PropTypes.string,
    managementStartDate: PropTypes.string,
    maritalStatus: PropTypes.string,
    nbChild: PropTypes.number,
    nic: PropTypes.string
  })
};

export default Identity;
