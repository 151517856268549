import React from 'react';
import Select from 'react-select';
import classNames from 'classnames';

const R = require('ramda');

const SelectManyOptions = ({
  options,
  value,
  onChange,
  placeholder,
  hasError,
  className
}) => {
  return (
    <div className={`${className} f-select`}>
      <Select
        isMulti
        className={classNames({ 'border-red-600': hasError })}
        classNamePrefix="f-select"
        value={value}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
      />
    </div>
  );
};

export default SelectManyOptions;
