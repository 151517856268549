import moment from 'moment';
import * as R from 'ramda';

const isActive = contract => {
  const today = moment();
  return R.allPass([
    R.propSatisfies(startDate => moment(startDate).isSameOrBefore(today), 'startDate'),
    R.propSatisfies(endDate => moment(endDate).isSameOrAfter(today), 'endDate')
  ])(contract);
};
export const filterActiveContracts = R.filter(isActive);
