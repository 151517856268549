import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { Roles, collabGroups } from '@@enum';

const groupIdToName = groups => id => R.compose(
  R.propOr('', 'name'),
  R.find(R.propEq('id', id))
)(groups);

const titleManager = R.always('Manager');
const titleOther = R.always('');
// NB(BESSE-412): le groupe Réclamation n'est pas à préciser pour le moment
const titleGestionnaire = groups => R.compose(
  R.concat('Gestionnaire '),
  R.join(', '),
  R.sort(R.comparator(R.lt)), // sort alphabetically
  R.reject(RA.isNilOrEmpty),
  R.map(groupIdToName(groups)),
  R.filter(RA.notEqual(collabGroups.RECLAMATION_GROUP)),
  R.propOr([], 'groups')
);

export const getStatusTitle = (user, groups) => {
  return R.cond([
    [R.propEq('role', Roles.MANAGER), titleManager],
    [R.propEq('role', Roles.GESTIONNAIRE), titleGestionnaire(groups)],
    [R.T, titleOther]
  ])(user);
};
