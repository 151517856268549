import React from 'react';
import PropTypes from 'prop-types';

const SubThemeBlock = ({
  children,
  title
}) => {
  return (
    <div className="my-3">
      <div className="w-full flex items-center justify-between mb-2">
        <div className="f-block-title">{title}</div>
      </div>
      {children}
    </div>
  );
};

SubThemeBlock.propTypes = {
  title: PropTypes.string.isRequired
};

export default SubThemeBlock;
