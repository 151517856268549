import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useAdherentWithpagination } from '@@hooks';
import { SearchInput } from '@@components/input';
import Notifications from './Notifications';
import { whiteSearchLogo } from '@@images';
import PropTypes from 'prop-types';

const items = [{
  text: 'Accueil',
  path: '/'
}, {
  text: 'Adherents',
  path: '/adherent'
}, {
  text: 'Offre & alertes',
  path: '/'
}, {
  text: 'Documents',
  path: '/'
}];

const Menu = ({ notificationsText, nbMessagesNotReaded }) => {
  const { setAsyncSelectData } = useAdherentWithpagination();

  const history = useHistory();

  const loadOptions = (inputValue, callback) => {
    setAsyncSelectData({ callback: callback, filter: { multiProps: inputValue } });
  };

  const onSearchInputChange = val => history.push(`/adherent/${val.value}`);

  return (
    <div className="f-menu">
      <div className="f-menu-body">
        <ul className="f-menu-body-tab">
          {items.map(item => (
            <li className="px-2" key={item.text}>
              <Link to={item.path}>{item.text}</Link>
            </li>
          ))}
        </ul>
        <div className="flex items-center w-1/3">
          <SearchInput
            onChange={onSearchInputChange}
            placeholder={'Rechercher par nom ou n° d\'adhérent'}
            loadOptions={loadOptions}
            classNamePrefix="f-select-menu-search"
            logo={whiteSearchLogo}
          />
          <Notifications
            notificationsText={notificationsText}
            nbMessagesNotReaded={nbMessagesNotReaded}
          />
        </div>
      </div>
    </div>
  );
};

export default Menu;

Menu.propTypes = {
  notificationsText: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      discussionId: PropTypes.string
    })
  ).isRequired,
  nbMessagesNotReaded: PropTypes.number
};
