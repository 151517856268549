/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EmailTypeEnum = "certified" | "perso" | "pro" | "%future added value";
export type GenderEnum = "F" | "M" | "%future added value";
export type MaritalStatusEnum = "celibataire" | "concubin" | "divorce" | "enfant_handicape" | "inconnu" | "majeur_a_charge" | "marie" | "mineur" | "pacse" | "separe" | "undefined" | "veuf" | "%future added value";
export type PhoneNumberTypeEnum = "fix_perso" | "fix_pro" | "mobile_certified" | "mobile_perso" | "mobile_pro" | "%future added value";
export type UpdatePersonalSituationInput = {|
  civilStatus?: ?AdherentCivilStatusInput,
  contact?: ?AdherentContactInput,
|};
export type AdherentCivilStatusInput = {|
  birthDate?: ?string,
  civility?: ?GenderEnum,
  firstName?: ?string,
  lastName?: ?string,
  maidenName?: ?string,
  managementStartDate?: ?string,
  maritalStatus?: ?MaritalStatusEnum,
  nbChild?: ?number,
  nic?: ?string,
|};
export type AdherentContactInput = {|
  address?: ?AdherentAddressInput,
  emails?: ?$ReadOnlyArray<?AdherentEmailInput>,
  phoneNumbers?: ?$ReadOnlyArray<?AdherentPhoneNumberInput>,
|};
export type AdherentAddressInput = {|
  address1: string,
  address2?: ?string,
  address3?: ?string,
  city: string,
  country: string,
  pnd?: ?boolean,
  zipCode: string,
|};
export type AdherentEmailInput = {|
  type?: ?EmailTypeEnum,
  value?: ?string,
|};
export type AdherentPhoneNumberInput = {|
  type?: ?PhoneNumberTypeEnum,
  value?: ?string,
|};
export type UpdatePersonalSituationMutationVariables = {|
  id: string,
  input?: ?UpdatePersonalSituationInput,
|};
export type UpdatePersonalSituationMutationResponse = {|
  +updatePersonalSituation: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type UpdatePersonalSituationMutation = {|
  variables: UpdatePersonalSituationMutationVariables,
  response: UpdatePersonalSituationMutationResponse,
|};
*/


/*
mutation UpdatePersonalSituationMutation(
  $id: ID!
  $input: UpdatePersonalSituationInput
) {
  updatePersonalSituation(id: $id, input: $input) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdatePersonalSituationMutationResponse",
    "kind": "LinkedField",
    "name": "updatePersonalSituation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdatePersonalSituationMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdatePersonalSituationMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ed237fcfeb360006ae05a3b7ad89f88f",
    "id": null,
    "metadata": {},
    "name": "UpdatePersonalSituationMutation",
    "operationKind": "mutation",
    "text": "mutation UpdatePersonalSituationMutation(\n  $id: ID!\n  $input: UpdatePersonalSituationInput\n) {\n  updatePersonalSituation(id: $id, input: $input) {\n    ok\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '25c435e28fae56191fd09b3c71ddc7ad';

module.exports = node;
