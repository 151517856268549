import React from 'react';
import { Block, Page } from '@@components';
import { SampleBlock } from '../containers';

const Accounts = () => {
  const { register, controls } = Block.useBlocks();
  return (
    <Page title="Gestion des comptes">
      <Block name="sample_block" register={register({ canClose: true })} controls={controls} title='Exemple de formulaire' color="blue" titleColor="plum">
        <SampleBlock />
      </Block>
    </Page>
  );
};

export default Accounts;
