import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

const BreadCrumb = ({ rootPath, rootName, location }) => {
  return (
    <nav className={'f-breadcrumb'}>
      <ol className={'f-breadcrumb-list'}>
        <li>
          <span className={'cursor-pointer'}>
            <Link to={`/${rootPath}`}>{rootName}</Link>
          </span>
          <span className={'mx-2'}>{'>'}</span>
        </li>
        <li>{location}</li>
      </ol>
    </nav>
  );
};

BreadCrumb.propTypes = {
  rootPath: PropTypes.string.isRequired,
  rootName: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired
};

export const BreadCrumbButtons = ({ elements }) => {
  const length = R.length(elements);
  const elementToJSX = ({ name, link, onClick }, index) => {
    const className = RA.isNotNil(link) || RA.isNotNil(onClick)
      ? 'cursor-pointer' : '';
    const btn = R.isNil(link)
      ? <a>{name}</a>
      : <Link to={link}>{name}</Link>;
    return (
      <li key={index}>
        <span className={className} onClick={onClick}>{btn}</span>
        {R.lt(index, length - 1) && <span className={'mx-2'}>{'>'}</span>}
      </li>
    );
  };

  return (
    <nav className={'f-breadcrumb'}>
      <ol className={'f-breadcrumb-list'}>
        {R.addIndex(R.map)(elementToJSX, elements)}
      </ol>
    </nav>
  );
};

BreadCrumbButtons.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    link: PropTypes.string,
    onClick: PropTypes.func
  })).isRequired
};

export default BreadCrumb;
