import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import moment from 'moment';

const formatMember = ({ firstName, lastName }) => {
  return R.compose(
    R.join(' '),
    R.reject(RA.isNilOrEmpty)
  )([firstName, lastName]);
};

const formatDate = R.when(
  RA.isNotNilOrEmpty,
  (strDate) => moment(strDate).format('[Le] DD/MM/YYYY - HH:mm')
);

const LastEditedHeader = ({ lastActionData }) => {
  const createdBy = R.compose(
    formatMember,
    R.pathOr({}, ['lastAction', 'createdBy'])
  )(lastActionData);
  const createdAt = R.compose(
    formatDate,
    R.pathOr('', ['lastAction', 'createdAt'])
  )(lastActionData);

  if (R.any(RA.isNilOrEmpty, [createdBy, createdAt])) {
    return null;
  }

  return (
    <div className={'f-last-edited-header'}>
      <p>
        {`Dernière modification: ${createdBy} - Collaborateur BESSÉ`}
      </p>
      <p>
        {createdAt}
      </p>
    </div>
  );
};

LastEditedHeader.propTypes = {
  lastActionData: PropTypes.shape({
    lastAction: PropTypes.shape({
      createdAt: PropTypes.string,
      createdBy: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string
      })
    })
  })
};

export default LastEditedHeader;
