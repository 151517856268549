/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QCmsContactQueryVariables = {||};
export type QCmsContactQueryResponse = {|
  +cms: ?{|
    +contact: ?{|
      +appaPhoneNumber: ?string
    |}
  |}
|};
export type QCmsContactQuery = {|
  variables: QCmsContactQueryVariables,
  response: QCmsContactQueryResponse,
|};
*/


/*
query QCmsContactQuery {
  cms {
    contact {
      appaPhoneNumber
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Cms",
    "kind": "LinkedField",
    "name": "cms",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CmsContact",
        "kind": "LinkedField",
        "name": "contact",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "appaPhoneNumber",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QCmsContactQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QCmsContactQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "b1098415b56e06033df9a6a3d6826fba",
    "id": null,
    "metadata": {},
    "name": "QCmsContactQuery",
    "operationKind": "query",
    "text": "query QCmsContactQuery {\n  cms {\n    contact {\n      appaPhoneNumber\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e8e15ca1b2a211cdbbd41d0b1da2f6ae';

module.exports = node;
