import React from 'react';
import PropTypes from 'prop-types';
import { SelectOneOption } from '@@components/input';
import * as R from 'ramda';

const SubThemeSelect = ({
  backOfficeMessageThemes,
  messageInfo,
  onChangeAction,
  action
}) => {
  const theme = R.find(R.propEq('id', messageInfo.themeId), backOfficeMessageThemes);

  const subOptions = R.map(R.applySpec({
    label: R.prop('title'),
    value: R.prop('id'),
    attachments: R.prop('attachments')
  }))(theme.subThemes);

  const [inputValue, setInputValue] = React.useState(R.find(R.propEq('value', action), subOptions));

  const $onChangeSubTheme = (subTheme) => {
    onChangeAction(subTheme.value);
    setInputValue(subTheme);
  };

  React.useEffect(() => {
    if (R.isNil(messageInfo.subThemeId)) {
      setInputValue('');
    }
  }, [messageInfo.subThemeId]);

  return (
    <SelectOneOption
      onChange={$onChangeSubTheme}
      placeholder={'Sélectionnez le sous thème de votre message...'}
      options={subOptions}
      value={inputValue}
    />
  );
};

SubThemeSelect.propTypes = {
  title: PropTypes.string,
  backOfficeMessageThemes: PropTypes.arrayOf(
    PropTypes.shape({
      actionId: PropTypes.string,
      id: PropTypes.string.isRequired,
      subTheme: PropTypes.shape({
        id: PropTypes.string.isRequired,
        actionId: PropTypes.string,
        attachments: PropTypes.shape({
          mandatory: PropTypes.arrayOf(
            PropTypes.string.isRequired
          ).isRequired
        }).isRequired
      })
    })
  ).isRequired,
  messageInfo: PropTypes.shape({
    themeId: PropTypes.string.isRequired,
    subThemeId: PropTypes.string
  }).isRequired,
  onChangeAction: PropTypes.func.isRequired,
  action: PropTypes.string
};

export default SubThemeSelect;
