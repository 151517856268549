import React from 'react';

const R = require('ramda');
const SessionContext = React.createContext();

const userIsSignedIn = R.compose(R.not, R.isNil, R.prop('username'));

export default function withSession (Component) {
  console.log('withSession2');
  return function SessionComponent (props) {
    console.log('youhou2');
    return (
      <SessionContext.Consumer>
        {({ userSession, onUpdateEmail, onSignIn, onSignedIn, onSignOut }) => <Component
          {...props}
          session={{
            user: userSession,
            userIsSignedIn: userIsSignedIn(userSession),
            onSignIn,
            onSignedIn,
            onSignOut,
            onUpdateEmail
          }}
        />
        }
      </SessionContext.Consumer>
    );
  };
}

export {
  SessionContext
};
