import React from 'react';
import { Link } from 'react-router-dom';
import { logoBesse } from '@@images';

const Footer = () => {
  return (
    <div className="flex flex-col items-center justify-center">
      <img className="mb-5" src={logoBesse} />
      <div className="text-center text-navy-blue mb-8 font-bold">
        <Link to="/mentionslegales">
          {'Mentions légales '}
        </Link>
        {'|'}
        <Link to="/donneespersonnelles">
          {' Données personnelles '}
        </Link>
        {'|'}
        <Link to="/cgu">
          {' CGU '}
        </Link>
        {'|'}
        <Link to="/contacts">
          {' Contact'}
        </Link>
      </div>
    </div>
  );
};

export default Footer;
