import React from 'react';
import PropTypes from 'prop-types';

const R = require('ramda');

const ToggleButtons = ({
  options,
  onClick,
  value,
  itemClassName
}) => {
  const handleChange = e => onClick(e.target.value);
  return (
    <div className={'flex justify-between'}>
      {
        R.map(({ value: optValue, label }) => (
          <label
            key={optValue}
            className={`${itemClassName} ${(R.equals(value, optValue)
              ? 'f-button-toggle-selected' : 'f-button-toggle')}`}
          >
            <input
              type='radio'
              name={label}
              className='appearance-none'
              value={optValue}
              onClick={handleChange}
            />
            <span>{label}</span>
          </label>
        ), options)
      }
    </div>
  );
};

ToggleButtons.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  onClick: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  itemClassName: PropTypes.string
};

export default ToggleButtons;
