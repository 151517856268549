import React from 'react';
import { Controller } from 'react-hook-form';
import { Attachments, UploadFile } from '@@components/input';
import UploadBlock from '../../input/UploadBlock';
const R = require('ramda');
const Input = ({ register, field, control, getValues, options }) => {
  const $options = R.propOr([], field.source, options);

  const onChangeHandler = (onChange, name) => (file) => {
    const files = R.defaultTo([], getValues(name));

    onChange(
      R.compose(
        R.append(R.assoc('documentType', '_UNDEFINED_', file)),
        R.defaultTo([])
      )(files)
    );
  };

  return (
    <Controller
      name={field.name}
      defaultValue={null}
      control={control}
      render={({ name, onChange, value }) => (
        <>
          <Attachments
            files={value || []}
            onChange={onChange}
            documentTypeOptions={$options}
          />

          {R.propEq('format', 'block', field) ? (
            <UploadBlock
              name="file"
              uploadUrl="/upload/file"
              uploadText={field.placeholder}
              onChange={onChangeHandler(onChange, name)}
            />
          ) : (
            <UploadFile
              name="file"
              uploadUrl="/upload/file"
              uploadText={field.placeholder}
              onChange={onChangeHandler(onChange, name)}
            />
          )}
        </>
      )} />
  );
};

export default Input;
