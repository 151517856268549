import * as R from 'ramda';

// return a list of all involved collaborators, with no duplicates, from a
// list of groups. Result shape: [{id:String,fullName:String},...]
const buildCollaboratorList = groupList => {
  // NB: We use R.toLower here because a retrieved id can somehow appear twice,
  // but with a different case. To avoid unwanted duplicates, all ids are thus
  // converted to lowercase.
  const convertCollaborator = ({ id, firstName, lastName }) =>
    ({ id: R.toLower(id), fullName: `${lastName} ${firstName}` });
  const getCollaborators = R.propOr([], 'collaborators');
  const getConvertedCollaborators = R.compose(
    R.map(convertCollaborator),
    getCollaborators
  );
  const listsOfId = R.map(getConvertedCollaborators)(groupList);
  const totalPool = R.reduce(R.concat, [], listsOfId);
  // List of the collaborators targeted by current groups (no duplicates)
  return R.uniqBy(R.propOr('', 'id'))(totalPool);
};

const sortByNameCaseInsensitive = R.sortBy(R.compose(R.toLower, R.prop('label')));

export {
  buildCollaboratorList,
  sortByNameCaseInsensitive
};
