import React from 'react';
import { SelectOneOption } from '@@components/input';
import classNames from 'classnames';
import { binIcon, paperclipIcon } from '@@images';

const RA = require('ramda-adjunct');
const R = require('ramda');

const Attachments = ({
  max,
  files,
  onChange,
  documentTypeOptions
}) => {
  const $onDocumentTypeChange = (uploadId) => ({ value }) => {
    onChange(R.map((attachment) => {
      if (attachment.uploadId === uploadId) {
        return R.assoc('documentType', value, attachment);
      }
      return attachment;
    })(files));
  };

  const onDeleteClick = uploadId => () => {
    onChange(
      R.filter(
        R.compose(R.not, R.propEq('uploadId', uploadId)), files)
    );
  };

  const showSelect = !RA.isNilOrEmpty(documentTypeOptions);

  const labelClasses = classNames('f-attachment-item-label', {
    'col-span-5': showSelect,
    'col-span-10': !showSelect
  });
  return (
    <ul className="f-attachments">
      {files.map(({ fileName, uploadId, documentType }) => (
        <li key={uploadId}>
          <div className={labelClasses}>
            <img src={paperclipIcon} />
            <div className="px-2">{fileName}</div>
          </div>
          {showSelect && (
            <div className="col-span-5">
              <SelectOneOption
                options={documentTypeOptions}
                value={R.find(R.propEq('value', documentType), documentTypeOptions)}
                onChange={$onDocumentTypeChange(uploadId)}
              />
            </div>
          )}
          <div className="f-attachment-item-delete" onClick={onDeleteClick(uploadId)}>
            <img src={binIcon} className="h-4 w-4"/>
          </div>
        </li>
      ))
      }
    </ul >
  );
};

export default Attachments;

Attachments.defaultProps = {
  max: undefined,
  documentTypeOptions: null
};
