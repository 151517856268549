/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FirstConnectionStep1Input = {|
  subscriberId: string
|};
export type FirstConnectionStep1MutationVariables = {|
  input: FirstConnectionStep1Input
|};
export type FirstConnectionStep1MutationResponse = {|
  +firstConnectionStep1: ?{|
    +ok: boolean,
    +error: ?string,
    +userContacts: ?$ReadOnlyArray<?{|
      +id: ?string,
      +text: ?string,
    |}>,
  |}
|};
export type FirstConnectionStep1Mutation = {|
  variables: FirstConnectionStep1MutationVariables,
  response: FirstConnectionStep1MutationResponse,
|};
*/


/*
mutation FirstConnectionStep1Mutation(
  $input: FirstConnectionStep1Input!
) {
  firstConnectionStep1(input: $input) {
    ok
    error
    userContacts {
      id
      text
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FirstConnectionStep1MutationResponse",
    "kind": "LinkedField",
    "name": "firstConnectionStep1",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserContact",
        "kind": "LinkedField",
        "name": "userContacts",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "text",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FirstConnectionStep1Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FirstConnectionStep1Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c3af2ce2c2faff9e6a2dd39358e9959a",
    "id": null,
    "metadata": {},
    "name": "FirstConnectionStep1Mutation",
    "operationKind": "mutation",
    "text": "mutation FirstConnectionStep1Mutation(\n  $input: FirstConnectionStep1Input!\n) {\n  firstConnectionStep1(input: $input) {\n    ok\n    error\n    userContacts {\n      id\n      text\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0fd55a9a19a16badb757cf1a0de5c19f';

module.exports = node;
