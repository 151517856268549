import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda';

const PriorityToggleFilter = ({ priorities, onChange }) => {
  const priorityToggle = value => {
    const newPriorities = R.clone(priorities);
    const foundIndex = R.findIndex(R.propEq('value', value))(newPriorities);
    const target = newPriorities[foundIndex];
    target.active = !target.active;
    onChange(newPriorities);
  };

  const priorityToJSX = ({ value, tag, active }) => {
    return (
      <div key={value}
        className={classNames('f-priority-toggle-filter-card', {
          'f-filter-active': active,
          'f-filter-inactive': !active
        })}
        onClick={ () => priorityToggle(value) }
      >
        {tag}
      </div>
    );
  };

  return (
    <div className='f-priority-toggle-filter-container'>
      <div className='f-priority-toggle-filter-title'>
        {'Priorité'}
      </div>
      <div className='f-priority-toggle-filter-body'>
        { R.map(p => priorityToJSX(p), priorities) }
      </div>
    </div>
  );
};

PriorityToggleFilter.propTypes = {
  priorities: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number.isRequired,
    tag: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired
  })).isRequired,
  onChange: PropTypes.func.isRequired
};

PriorityToggleFilter.defaultProps = {
  priorities: []
};

export default PriorityToggleFilter;
