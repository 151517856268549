import React from 'react';
import ErrorMessage from './helpers/ErrorMessage';
import { Controller } from 'react-hook-form';

const R = require('ramda');

const UserContactList = ({ field, errors, options, control }) => {
  console.log(JSON.stringify(field));
  console.log(JSON.stringify(options));
  const classes = R.keys(errors || {}).includes(field.name) ? 'f-input-red' : 'f-input';
  const $options = R.propOr([], field.source, options);

  return (
    <Controller
      name={field.name}
      control={control}
      rules={{ required: field.required }}
      render={({ onChange, value }) => (
        <>
          <ul>
            {$options.map(({ id, text }) => (
              <li key={id} onClick={() => onChange(id)}>{value === id ? '*' : ' '}{text}</li>
            ))}
          </ul>
          <ErrorMessage field={field} errors={errors} />
        </>
      )}
    />
  );
};

export default UserContactList;
