/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SendDiscussionInput = {|
  actionId?: ?string,
  attachments?: ?$ReadOnlyArray<?AttachmentInput>,
  discussionId?: ?string,
  discussionStatusClosed?: ?boolean,
  signRequestAttachments?: ?boolean,
  subscribersIds?: ?$ReadOnlyArray<?string>,
  text: string,
  title?: ?string,
  typology: string,
|};
export type AttachmentInput = {|
  documentType?: ?string,
  fileName: string,
  uploadId: string,
|};
export type SendDiscussionMutationVariables = {|
  input: SendDiscussionInput
|};
export type SendDiscussionMutationResponse = {|
  +sendDiscussion: ?{|
    +ok: boolean,
    +error: ?string,
    +discussions: ?$ReadOnlyArray<?{|
      +assignedTo: ?$ReadOnlyArray<string>,
      +discussionId: string,
      +messageId: string,
      +message: ?{|
        +recipients: ?$ReadOnlyArray<?string>,
        +title: ?string,
        +text: ?string,
        +sender: ?string,
        +order: ?number,
      |},
      +attachments: ?$ReadOnlyArray<?{|
        +id: ?string,
        +fileName: ?string,
        +documentType: ?string,
        +uploadId: ?string,
      |}>,
      +dates: ?{|
        +creation: ?string,
        +lastUpdate: ?string,
      |},
    |}>,
  |}
|};
export type SendDiscussionMutation = {|
  variables: SendDiscussionMutationVariables,
  response: SendDiscussionMutationResponse,
|};
*/


/*
mutation SendDiscussionMutation(
  $input: SendDiscussionInput!
) {
  sendDiscussion(input: $input) {
    ok
    error
    discussions {
      assignedTo
      discussionId
      messageId
      message {
        recipients
        title
        text
        sender
        order
        id
      }
      attachments {
        id
        fileName
        documentType
        uploadId
      }
      dates {
        creation
        lastUpdate
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ok",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "error",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "assignedTo",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discussionId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "messageId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "recipients",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sender",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "order",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "Attachment",
  "kind": "LinkedField",
  "name": "attachments",
  "plural": true,
  "selections": [
    (v12/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fileName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "documentType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uploadId",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "Dates",
  "kind": "LinkedField",
  "name": "dates",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "creation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastUpdate",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SendDiscussionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SendDiscussionMutationResponse",
        "kind": "LinkedField",
        "name": "sendDiscussion",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SendMessagesResponse",
            "kind": "LinkedField",
            "name": "discussions",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Message",
                "kind": "LinkedField",
                "name": "message",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              (v13/*: any*/),
              (v14/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SendDiscussionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SendDiscussionMutationResponse",
        "kind": "LinkedField",
        "name": "sendDiscussion",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SendMessagesResponse",
            "kind": "LinkedField",
            "name": "discussions",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Message",
                "kind": "LinkedField",
                "name": "message",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              },
              (v13/*: any*/),
              (v14/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7bd6553324fc61589f13ac90d16b4255",
    "id": null,
    "metadata": {},
    "name": "SendDiscussionMutation",
    "operationKind": "mutation",
    "text": "mutation SendDiscussionMutation(\n  $input: SendDiscussionInput!\n) {\n  sendDiscussion(input: $input) {\n    ok\n    error\n    discussions {\n      assignedTo\n      discussionId\n      messageId\n      message {\n        recipients\n        title\n        text\n        sender\n        order\n        id\n      }\n      attachments {\n        id\n        fileName\n        documentType\n        uploadId\n      }\n      dates {\n        creation\n        lastUpdate\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a54f17960ef878cb4cef3b73dab0c892';

module.exports = node;
