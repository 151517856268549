import React from 'react';
import ErrorMessage from './helpers/ErrorMessage';
import { Controller } from 'react-hook-form';

const R = require('ramda');

const Nic = ({ control, register, field, errors }) => {
  const classes = R.keys(errors).includes(field.name) ? 'f-input-red' : 'f-input';
  return (
    <Controller
      name={field.name}
      control={control}
      rules={{ required: field.required }}
      render={({ onChange, value }) => {
        const $onChange = (e) => {
          onChange(parseInt(e.target.value));
        };
        return (
          <>
            <input type="number" className={classes} value={value} onChange={$onChange} placeholder={field.placeholder} />
            <ErrorMessage field={field} errors={errors} />
          </>
        );
      }}
    />
  );
};

export default Nic;
