import { useEffect, useRef, useState } from 'react';
import { QDataSpecialtiesQuery } from '@@queries/QDataSpecialties';

const R = require('ramda');
const { fetchQuery } = require('react-relay');
const { useRelayEnvironment } = require('react-relay');

export const useData = (data) => {
  const environment = useRelayEnvironment();
  const [referential, setReferential] = useState({});

  const dataRef = useRef();
  useEffect(() => {
    if (R.equals(dataRef.current, data)) return;
    dataRef.current = data;
    if (R.includes('specialties', data)) getSpecialties();
  }, [data]);

  const getSpecialties = async () => {
    const { data } = await fetchQuery(environment, QDataSpecialtiesQuery).toPromise();
    setReferential(R.assoc('specialties', R.propOr(null, 'specialties', data)));
  };

  return {
    referential
  };
};

export default useData;
