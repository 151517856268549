import React from 'react';
import PropTypes from 'prop-types';
import { Field } from '@@components';
import EditSelect from '@@pages/adherent/components/EditSelect';
import * as R from 'ramda';

const nameToOption = R.applySpec({
  value: R.propOr('', 'name'),
  label: R.propOr('', 'name')
});

const EditStatus = ({
  specialties,
  specialty,
  onChangeSpecialty,
  echelon,
  isPHHUTypology
}) => {
  const options = R.map(nameToOption, specialties);
  const handleChange = (option) => onChangeSpecialty(option.value);

  return (
    <div className={'f-edit-adherent-subform-container'}>
      <Field label={'Spécialité'}>
        <EditSelect
          options={options}
          selected={{ label: specialty, value: specialty }}
          onChange={handleChange}
        />
      </Field>
      {isPHHUTypology && (
        <Field label={'Échelon'}>
          <EditSelect
            selected={{ label: echelon, value: echelon }}
            isDisabled={true}
          />
        </Field>
      )}
    </div>
  );
};

EditStatus.propTypes = {
  specialties: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })).isRequired,
  specialty: PropTypes.string.isRequired,
  onChangeSpecialty: PropTypes.func.isRequired,
  echelon: PropTypes.string.isRequired,
  isPHHUTypology: PropTypes.bool.isRequired
};

export default EditStatus;
