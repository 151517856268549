import React from 'react';
import PropTypes from 'prop-types';
import { Loading } from '@@components';
import { findActivity, findEchelon } from '../../utils';
import { paperclipIcon } from '@@images';

const Status = ({
  contracts,
  isPHHUTypology
}) => {
  return <>
    {
      contracts
        ? <div>
          {findActivity(contracts) && <div>{findActivity(contracts)}</div>}
          {
            isPHHUTypology && <>
              {findEchelon(contracts)}
            </>
          }
          <div className="f-attachment-item-label mt-2">
            <img src={paperclipIcon} alt={'Icône pièce jointe'}/>
            {/* TODO récupéré le nom de la pièce jointe */}
            <div className="px-2">{'Bulletin de salaire'}</div>
          </div>
        </div>
        : <Loading />
    }
  </>;
};

Status.propTypes = {
  isPHHUTypology: PropTypes.bool,
  contracts: PropTypes.arrayOf(
    PropTypes.shape({
      SEPAMandate: PropTypes.object,
      activityRate: PropTypes.number,
      category: PropTypes.string,
      detail: PropTypes.shape({
        contract: PropTypes.string,
        label: PropTypes.string,
        category: PropTypes.string,
        categoryLabel: PropTypes.string,
        typology: PropTypes.string,
        function: PropTypes.string,
        activity: PropTypes.string,
        privateSector: PropTypes.bool,
        directDebitDate: PropTypes.number,
        health: PropTypes.shape({
          option: PropTypes.string,
          careNetwork: PropTypes.shape({
            label: PropTypes.string,
            url: PropTypes.string
          }),
          contractNumber: PropTypes.string,
          complementaryText: PropTypes.string,
          guarantee: PropTypes.arrayOf(
            PropTypes.shape({
              family: PropTypes.string,
              label: PropTypes.string,
              value: PropTypes.string
            })
          )
        }),
        pension: PropTypes.shape({
          deductible: PropTypes.shape({
            is: PropTypes.bool,
            value: PropTypes.string
          }),
          contractNumber1: PropTypes.string,
          contractNumber2: PropTypes.string,
          complementaryText: PropTypes.string,
          guarantee: PropTypes.arrayOf(
            PropTypes.shape({
              family: PropTypes.string,
              label: PropTypes.string,
              value: PropTypes.string
            })
          )
        }),
        assistance: PropTypes.shape({
          label: PropTypes.string,
          metropole: PropTypes.string,
          DROM: PropTypes.string,
          complementaryText: PropTypes.string,
          guarantee: PropTypes.arrayOf(
            PropTypes.shape({
              family: PropTypes.string,
              label: PropTypes.string,
              value: PropTypes.string
            })
          )
        })
      }),
      directDebit: PropTypes.bool,
      endDate: PropTypes.string,
      id: PropTypes.string,
      periodicity: PropTypes.string,
      pricingOptions: PropTypes.arrayOf(
        PropTypes.shape({
          startDate: PropTypes.string,
          endDate: PropTypes.string,
          type: PropTypes.string,
          index: PropTypes.string,
          valueType: PropTypes.string,
          value: PropTypes.string
        })
      )
    })
  )
};

export default Status;
