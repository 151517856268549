/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QStatusQueryVariables = {||};
export type QStatusQueryResponse = {|
  +status: ?{|
    +ok: ?boolean,
    +error: ?string,
    +resume: ?{|
      +allServicesAvailables: ?boolean,
      +details: ?$ReadOnlyArray<?{|
        +service: ?string,
        +ok: ?boolean,
        +reports: ?$ReadOnlyArray<?string>,
      |}>,
    |},
  |}
|};
export type QStatusQuery = {|
  variables: QStatusQueryVariables,
  response: QStatusQueryResponse,
|};
*/


/*
query QStatusQuery {
  status {
    ok
    error
    resume {
      allServicesAvailables
      details {
        service
        ok
        reports
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ok",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ServicesStatus",
    "kind": "LinkedField",
    "name": "status",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ResumeStatus",
        "kind": "LinkedField",
        "name": "resume",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "allServicesAvailables",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ResumeStatusDetails",
            "kind": "LinkedField",
            "name": "details",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "service",
                "storageKey": null
              },
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "reports",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QStatusQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QStatusQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8bbd77defbae37f3e0b2d6b240e90521",
    "id": null,
    "metadata": {},
    "name": "QStatusQuery",
    "operationKind": "query",
    "text": "query QStatusQuery {\n  status {\n    ok\n    error\n    resume {\n      allServicesAvailables\n      details {\n        service\n        ok\n        reports\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '69b71f0d0c6a6cab3cc5acedbc1bce83';

module.exports = node;
