import React from 'react';
import QAllActions from '@@queries/QAllActions';

const Home = () => {
  return (
    <QAllActions args={{ filter: {}, paginate: { page: 1, limit: 25 } }} >
      {({ allActions }) => {
        console.log(JSON.stringify(allActions));
        return (
          <>
            <ul>
              {allActions.data.map(action => (
                <li key={action.id}>{action.status} - {action.label}</li>
              ))}
            </ul>
          </>
        );
      }}
    </QAllActions>
  );
};

export default Home;
