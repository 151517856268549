import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { QUserSessionQuery } from '@@queries/QUserSession';
import { SignInMutation, SignOutMutation } from '@@mutations/userSession';
const { fetchQuery } = require('react-relay');
const { useRelayEnvironment } = require('react-relay');

const R = require('ramda');

Moment.locale('fr');

const Session = ({
  refreshInterval,
  children
}) => {
  const environment = useRelayEnvironment();
  const [session, setSession] = React.useState();
  const sessionRef = React.useRef();

  const onGetSession = async () => {
    const { userSession } = await fetchQuery(environment, QUserSessionQuery).toPromise();
    if (R.equals(sessionRef.current, { userSession })) return;
    setSession({ userSession });
  };

  React.useEffect(() => {
    onGetSession();
  }, []);

  React.useEffect(() => {
    const interval = setInterval(
      () => {
        onGetSession();
      },
      refreshInterval
    );
    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    if (R.equals(sessionRef.current, session)) return;
    sessionRef.current = session;
  }, [session]);

  const onSignedIn = ({ userSession }) => {
    setSession({ userSession });
  };

  const onSignOut = () => {
    SignOutMutation((ok, error, userSession) => {
      if (error) {
        console.error('Une erreur c\'est produite lors de la déconnexion');
      } else {
        setSession({ userSession });
      }
    });
  };

  if (R.isNil(session)) return null;

  return children({ ...session, onSignedIn, onSignOut });
};

export default Session;
