import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useReferences, useAdherentWithpagination, useAdherent, useQueryUrl } from '@@hooks';
import { SelectOneOption, SearchInput } from '@@components/input';
import useForms from '@@formsHook';
import Forms from '@@components/forms';
import S from 'string';
import SubThemeSelect from './SubThemeSelect';
import { MessageRecord } from '@@graphql/records/MessagesRecord';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { ErrorMessage } from '@@components';
import { useHistory } from 'react-router-dom';
import { documentTypeOptions, typologyOptions } from '@@data';
import SelectAdherentsHeader from '@@pages/message/components/SelectAdherentsHeader';
import { filterActiveContracts } from '@@lib/contracts';

const NewDiscussionFrame = ({
  action,
  onChangeAction,
  inputThemeValue,
  setInputThemeValue,
  isManager, // when true, the Select allows for multiple options
  openInputs,
  setOpenInputs
}) => {
  const history = useHistory();
  const queryParams = useQueryUrl();
  const subscriberIdParam = R.or(R.pathOr('', [0, 'subscriberId'], queryParams), null);
  const fullNameParam = R.or(R.pathOr('', [0, 'fullName'], queryParams), null);
  const noQueryParams = R.and(RA.isNilOrEmpty(fullNameParam), RA.isNilOrEmpty(subscriberIdParam));

  const {
    backOfficeMessageThemes,
    messageAttachmentTypes
  } = useReferences(['backOfficeMessageThemes', 'messageAttachmentTypes']);

  const maybeDefaultValue = {
    label: `${fullNameParam} - N°${subscriberIdParam}`,
    value: subscriberIdParam
  };
  const singleDefaultValue = noQueryParams ? null : maybeDefaultValue;
  const multiDefaultValue = noQueryParams ? [] : [maybeDefaultValue];

  const [singleAdherentValue, setSingleAdherentValue] = useState(singleDefaultValue);
  const [multiAdherentValue, setMultiAdherentValue] = useState(multiDefaultValue);
  const [discussionStatusClosed, setDiscussionStatusClosed] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [subscriberId, setSubscriberId] = useState(subscriberIdParam);
  const [messageInfo, setMessageInfo] = useState({
    themeId: null,
    subThemeId: null
  });

  const { setAsyncSelectData } = useAdherentWithpagination();

  // NB: Cette valeur n'est utilisée que pour les gestionnaires
  const { adherent } = useAdherent(subscriberId, ['contracts']);

  const selectAdherentInputInfos = isManager
    ? {
      placeholder: 'Sélectionnez le ou les adhérent(s)...',
      label: 'Destinataire(s)'
    }
    : {
      placeholder: 'Sélectionnez un adhérent...',
      label: 'Destinataire'
    };

  const {
    fetching,
    onFormsSubmit,
    formsData
  } = isManager
    ? useForms('sendDiscussion', {
      updater: (input, store, response) => {
        if (RA.isNotNil(R.path(['sendDiscussion', 'error'], response))) return;
        if (R.isNil(R.prop('sendDiscussion', response))) return;
        R.forEach((discussion) => {
          const discussionId = R.prop('discussionId', discussion);

          const message = {
            ...R.prop('message', discussion),
            dates: R.prop('dates', discussion),
            assignedTo: R.prop('assignedTo', discussion),
            attachments: R.prop('attachments', discussion)
          };

          const rec = MessageRecord(store);
          rec.createDiscussion(discussionId, message);
        }, R.path(['sendDiscussion', 'discussions'], response));
      }
    })
    : useForms('sendGestDiscussion', {
      updater: (input, store, response) => {
        if (RA.isNotNil(R.path(['sendGestDiscussion', 'error'], response))) return;
        if (R.isNil(R.prop('sendGestDiscussion', response))) return;
        const discussionId = R.path(['sendGestDiscussion', 'discussionId'], response);

        const message = {
          ...R.path(['sendGestDiscussion', 'message'], response),
          dates: R.path(['sendGestDiscussion', 'dates'], response),
          assignedTo: R.path(['sendGestDiscussion', 'assignedTo'], response),
          attachments: input.atttachments
        };
        const rec = MessageRecord(store);
        rec.createDiscussion(discussionId, message);
      }
    });

  React.useEffect(() => {
    if (fetching.getError()) {
      setErrorMessage(fetching.getError());
    }
    if (fetching.isDone() && !isManager) {
      fetching.reset();
      history.push(`/messagerie/${fetching.getPayload().discussionId}`);
    }
    if (fetching.isDone() && isManager) {
      fetching.reset();
      history.push('/messagerie');
    }
  }, [fetching.isDone(), fetching.getError()]);

  const $onChangeThemeId = (data) => {
    onChangeAction(data.value);
    setInputThemeValue(data);
  };

  const onChangeAdherentsIds = (data) => {
    if (isManager) {
      const newMultiAdherentValues = R.compose(
        R.sort(R.ascend(R.prop('label'))),
        R.uniqBy(R.prop('value')),
        R.defaultTo([])
      )(data);
      setMultiAdherentValue(newMultiAdherentValues);
    } else {
      // TODO - this should probably reset then contractOptions form as well
      setSubscriberId(R.propOr('', 'value', data));
      setSingleAdherentValue(data);
    }
  };

  const onImportIDs = newIds => {
    const newMultiAdherentValues = R.compose(
      R.sort(R.ascend(R.prop('label'))),
      R.uniqBy(R.prop('value')),
      R.concat(multiAdherentValue),
      R.defaultTo([])
    )(newIds);
    setMultiAdherentValue(newMultiAdherentValues);
  };

  const contractOptions = R.compose(
    R.map(R.applySpec({
      label: R.path(['detail', 'label']),
      value: {
        contractId: R.path(['detail', 'contract']),
        categoryId: R.path(['detail', 'category'])
      }
    })),
    filterActiveContracts,
    R.propOr([], 'contracts')
  )(adherent);

  const attachmentsRef = React.useRef(null);
  const onFormChange = (v) => {
    if (R.compose(R.is(Array), R.prop('attachments'))(v)) {
      if (!R.equals(attachmentsRef.current, v.attachments)) {
        attachmentsRef.current = v.attachments;
      } else if (!R.isNil(attachmentsRef.current)) {
        attachmentsRef.current = null;
      }
    }
  };

  const onCancel = () => {
    onChangeAction();
  };

  React.useEffect(() => {
    if (fetching.isDone()) {
      fetching.reset();
      onChangeAction('');
    }
  }, [fetching]);

  React.useEffect(() => {
    if (R.isNil(backOfficeMessageThemes)) return;
    if (R.isNil(action) || R.isEmpty(action)) {
      setMessageInfo({ themeId: null, subThemeId: null });
      setOpenInputs({ subTheme: false, reply: false });
    } else if (!S(action).contains('-')) {
      const theme = R.find(R.propEq('id', action), backOfficeMessageThemes);
      if (R.isNil(theme.subThemes)) {
        setOpenInputs({ subTheme: false, reply: true });
      } else {
        setOpenInputs({ subTheme: true, reply: false });
      }
      const options = R.map(R.applySpec({
        label: R.prop('title'),
        value: R.prop('id')
      }))(backOfficeMessageThemes);
      setMessageInfo(R.mergeRight(messageInfo, { themeId: action, subThemeId: null }));
      setInputThemeValue(R.find(R.propEq('value', action), options));
    } else {
      const info = action.split('-');
      const options = R.map(R.applySpec({
        label: R.prop('title'),
        value: R.prop('id')
      }))(backOfficeMessageThemes);
      setMessageInfo({ themeId: info[0], subThemeId: info[1] });
      setInputThemeValue(R.find(R.propEq('value', info[0]), options));
      setOpenInputs({ subTheme: true, reply: true });
    }
  }, [action, backOfficeMessageThemes]);

  if (R.isNil(backOfficeMessageThemes) || R.isNil(messageAttachmentTypes)) return null;

  if (fetching.isDone()) {
    return (
      <div>
        <h3>Votre message a été envoyé</h3>
        <button
          className={'f-button f-button-blue mr-4'}
          onClick={onCancel}
        >
          {'Envoyer une nouvelle demande'}
        </button>
      </div>
    );
  }

  const options = R.map(R.applySpec({
    label: R.prop('title'),
    value: R.prop('id')
  }))(backOfficeMessageThemes);

  const adherentsIds = adherentsOptions => R.map(R.prop('value'), adherentsOptions);

  const onSubmit = (adherentsValues) => (val) => {
    const recipient = R.is(Array, adherentsValues)
      ? { subscribersIds: adherentsIds(adherentsValues) }
      : { subscriberId: adherentsValues.value };
    const valWithStatus = R.mergeRight(val, { discussionStatusClosed });
    onFormsSubmit(R.mergeRight(valWithStatus, recipient));
  };

  const handleCloseButton = () => {
    setDiscussionStatusClosed(true);
  };

  const loadOptions = (inputValue, callback) => {
    setAsyncSelectData({ callback, filter: { multiProps: inputValue } });
  };

  const adherentsValues = isManager ? multiAdherentValue : singleAdherentValue;

  return (
    <div className={'w-2/3'}>
      <h3 className={'p-6 border-b-2'}>
        {'Nouveau Message'}
      </h3>
      <div className={'p-4'}>
        <div className={'f-block-field'}>
          <SelectAdherentsHeader
            label={selectAdherentInputInfos.label}
            isMulti={isManager}
            onImportIDs={onImportIDs}
          />
          <SearchInput
            onChange={onChangeAdherentsIds}
            placeholder={selectAdherentInputInfos.placeholder}
            loadOptions={loadOptions}
            classNamePrefix="f-select"
            isMulti={isManager}
            dropdownIndicator={true}
            multiValue={multiAdherentValue}
            singleValue={singleAdherentValue}
          />
        </div>
        <div className={'f-block-field'}>
          <div className={'f-block-field-label'}>
            {'Sujet de la demande'}
          </div>
          <SelectOneOption
            onChange={$onChangeThemeId}
            placeholder={'Sélectionnez le thème de votre message...'}
            options={options}
            value={inputThemeValue}
          />
        </div>
        {
          openInputs.subTheme && (
            <div className={'f-block-field mt-5'}>
              <div className={'f-block-field-label'}>
                {'Sous thème de la demande'}
              </div>
              <SubThemeSelect
                backOfficeMessageThemes={backOfficeMessageThemes}
                messageInfo={messageInfo}
                onChangeAction={onChangeAction}
                action={action}
              />
            </div>
          )}
        {
          openInputs.reply && (
            <Forms
              formsData={formsData}
              defaultValues={{
                actionId: messageInfo.subThemeId ? `BOACTION-${messageInfo.themeId}-${messageInfo.subThemeId}` : `BOACTION-${messageInfo.themeId}`
              }}
              alignedLabels={{ signRequestAttachments: 'Demander une signature électronique pour les documents envoyés' }}
              columns={1}
              onChange={onFormChange}
              onSubmit={onSubmit(adherentsValues)}
              options={{
                documentTypeOptions,
                contractOptions,
                typologyOptions
              }}
              submitSection={() => (
                <>
                  <div className="flex justify-between">
                    <div className="flex items-center mt-4">
                      <button
                        className="f-button f-button-grey mr-4"
                        disabled={fetching.isFetching()}
                        onClick={onCancel}
                      >
                        {'Annuler'}
                      </button>
                      <button
                        className="f-button f-button-blue mr-4"
                        disabled={fetching.isFetching()}
                        type="submit"
                      >
                        {'Envoyer'}
                      </button>
                    </div>
                    <button
                      className={'f-button-sm f-button-coral-reverse mt-4'}
                      onClick={handleCloseButton}
                      type="submit">
                      {'Clôturer la demande'}
                    </button>
                  </div>
                  {errorMessage && <ErrorMessage value={errorMessage} />}
                </>
              )}
            />
          )}
      </div>
    </div>
  );
};

NewDiscussionFrame.propTypes = {
  setInputThemeValue: PropTypes.func.isRequired,
  inputThemeValue: PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    }),
    PropTypes.string
  ]).isRequired,
  onChangeAction: PropTypes.func.isRequired,
  action: PropTypes.string,
  isManager: PropTypes.bool.isRequired,
  openInputs: PropTypes.shape({
    subTheme: PropTypes.bool.isRequired,
    reply: PropTypes.bool.isRequired
  }),
  setOpenInputs: PropTypes.func.isRequired
};

export default NewDiscussionFrame;
