import React from 'react';
import QStatus from '../../../_graphql/queries/QStatus';
import { Page } from '../../../components';
const Status = () => {
  return (
    <Page title="Affichage des statuts">
      <br/>
      <QStatus args={{}}>
        {
          ({ status: { resume: { details } } }) =>
            <div className="grid grid-cols-3 gap-4">
              {
                details.map(({ service, ok }) =>
                  <div key={service} className={`${ok ? 'bg-green-300' : 'bg-red-300'} h-12 flex items-center justify-center text-white`}>
                    {service}
                  </div>)
              }
            </div>
        }
      </QStatus>

    </Page>
  );
};

export default Status;
