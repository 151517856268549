import { graphql } from 'react-relay';

const QGetMessageNotifiedQuery = graphql`
  query QGetMessageNotifiedQuery {
    getMessageNotified {
        discussionId
        messageId
        _id
        group
        theme
        fullName
        optFullName
        type
    }
  }
`;

export { QGetMessageNotifiedQuery };
