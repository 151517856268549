import React from 'react';
import PropTypes from 'prop-types';

const Loading = ({ value }) => (
  <div>
    {value || 'Chargement en cours ...'}
  </div>
);

Loading.propTypes = {
  value: PropTypes.string
};

export default Loading;
