/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AdherentAddressInput = {|
  address1: string,
  address2?: ?string,
  address3?: ?string,
  city: string,
  country: string,
  pnd?: ?boolean,
  zipCode: string,
|};
export type AdherentAddressMutationVariables = {|
  input: AdherentAddressInput
|};
export type AdherentAddressMutationResponse = {|
  +adherentAddress: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type AdherentAddressMutation = {|
  variables: AdherentAddressMutationVariables,
  response: AdherentAddressMutationResponse,
|};
*/


/*
mutation AdherentAddressMutation(
  $input: AdherentAddressInput!
) {
  adherentAddress(input: $input) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AdherentAddressMutationResponse",
    "kind": "LinkedField",
    "name": "adherentAddress",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AdherentAddressMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AdherentAddressMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "60f5e62f2cbbe3abc7a48c9176378c76",
    "id": null,
    "metadata": {},
    "name": "AdherentAddressMutation",
    "operationKind": "mutation",
    "text": "mutation AdherentAddressMutation(\n  $input: AdherentAddressInput!\n) {\n  adherentAddress(input: $input) {\n    ok\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '315ce6d97801080e4b9417eeddf71d69';

module.exports = node;
