import Keycloak from 'keycloak-js';

import {
  createActions
} from 'redux-actions';
const R = require('ramda');

export const {
  keycloakSessionUpdateState
} = createActions({
  KEYCLOAK_SESSION_UPDATE_STATE: (state) => (state)
});

export const keycloakSessionSignIn = () => {
  return async (dispatch, getState) => {
    const { keycloak } = getState().keycloakSession;
    console.log(JSON.stringify(keycloak));
    if (!keycloak.authenticated) {
      await keycloak.login();
      const user = await keycloak.loadUserProfile();
      console.log('== updateSession = ', user);
      dispatch(keycloakSessionUpdateState, { user });
    }
  };
};

export const keycloakSessionSignOut = () => {
  return async (dispatch, getState) => {
    const { keycloak } = getState().keycloakSession;
    console.log(JSON.stringify(keycloak));
    await keycloak.logout();
    const user = await keycloak.loadUserProfile();
    console.log('== updateSession = ', user);
    dispatch(keycloakSessionUpdateState, { user });
  };
};
