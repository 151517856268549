/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LastActionFilterInput = {|
  customerId?: ?string,
  type?: ?string,
|};
export type QLastActionQueryVariables = {|
  lastActionFilter?: ?LastActionFilterInput
|};
export type QLastActionQueryResponse = {|
  +lastAction: ?{|
    +id: ?string,
    +type: ?string,
    +label: ?string,
    +text: ?string,
    +author: ?string,
    +createdBy: ?{|
      +id: ?string,
      +firstName: ?string,
      +lastName: ?string,
    |},
    +createdAt: ?string,
  |}
|};
export type QLastActionQuery = {|
  variables: QLastActionQueryVariables,
  response: QLastActionQueryResponse,
|};
*/


/*
query QLastActionQuery(
  $lastActionFilter: LastActionFilterInput
) {
  lastAction(filter: $lastActionFilter) {
    id
    type
    label
    text
    author
    createdBy {
      id
      firstName
      lastName
    }
    createdAt
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "lastActionFilter"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "lastActionFilter"
      }
    ],
    "concreteType": "LastAction",
    "kind": "LinkedField",
    "name": "lastAction",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "label",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "text",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "author",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Member",
        "kind": "LinkedField",
        "name": "createdBy",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QLastActionQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QLastActionQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "7910fcc2633c412918a89861a77f8491",
    "id": null,
    "metadata": {},
    "name": "QLastActionQuery",
    "operationKind": "query",
    "text": "query QLastActionQuery(\n  $lastActionFilter: LastActionFilterInput\n) {\n  lastAction(filter: $lastActionFilter) {\n    id\n    type\n    label\n    text\n    author\n    createdBy {\n      id\n      firstName\n      lastName\n    }\n    createdAt\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '83cd727e806cc4ae376cd8660d8b16ba';

module.exports = node;
