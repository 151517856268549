/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ManagerUpdateDiscussionAffectationInput = {|
  collaboratorId?: ?string,
  discussionId: string,
  service: string,
|};
export type ManagerUpdateDiscussionAffectationMutationVariables = {|
  input: ManagerUpdateDiscussionAffectationInput
|};
export type ManagerUpdateDiscussionAffectationMutationResponse = {|
  +managerUpdateDiscussionAffectation: ?{|
    +ok: boolean,
    +error: ?string,
    +discussion: ?{|
      +id: string,
      +assignedTo: ?$ReadOnlyArray<string>,
      +collaborator: ?{|
        +id: ?string,
        +firstName: ?string,
        +lastName: ?string,
      |},
    |},
  |}
|};
export type ManagerUpdateDiscussionAffectationMutation = {|
  variables: ManagerUpdateDiscussionAffectationMutationVariables,
  response: ManagerUpdateDiscussionAffectationMutationResponse,
|};
*/


/*
mutation ManagerUpdateDiscussionAffectationMutation(
  $input: ManagerUpdateDiscussionAffectationInput!
) {
  managerUpdateDiscussionAffectation(input: $input) {
    ok
    error
    discussion {
      id
      assignedTo
      collaborator {
        id
        firstName
        lastName
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ManagerUpdateDiscussionAffectationMutationResponse",
    "kind": "LinkedField",
    "name": "managerUpdateDiscussionAffectation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Discussion",
        "kind": "LinkedField",
        "name": "discussion",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "assignedTo",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Member",
            "kind": "LinkedField",
            "name": "collaborator",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ManagerUpdateDiscussionAffectationMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ManagerUpdateDiscussionAffectationMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "38e6d5b50b9db30f4fa7ac010c6f90c2",
    "id": null,
    "metadata": {},
    "name": "ManagerUpdateDiscussionAffectationMutation",
    "operationKind": "mutation",
    "text": "mutation ManagerUpdateDiscussionAffectationMutation(\n  $input: ManagerUpdateDiscussionAffectationInput!\n) {\n  managerUpdateDiscussionAffectation(input: $input) {\n    ok\n    error\n    discussion {\n      id\n      assignedTo\n      collaborator {\n        id\n        firstName\n        lastName\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8a3017146f59f24049d63480c9b0515f';

module.exports = node;
