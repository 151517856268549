
import React from 'react';
import PropTypes from 'prop-types';
import { paperclipIcon } from '@@images';

const DocumentLink = ({ title, link }) => {
  return <a
    className="f-messaging-message-attachment"
    href={link} target={'_blank'} rel={'noreferrer'}
  >
    <img src={paperclipIcon} alt={'Icône pièce jointe'}/>
    <div className="px-2">{title}</div>
  </a>;
};

DocumentLink.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string
};

export default DocumentLink;
