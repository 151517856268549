import React from 'react';
import PropTypes from 'prop-types';
import * as RA from 'ramda-adjunct';
import { cross } from '@@images';

const SearchInputWithLogo = ({ name, onChange, logo, placeholder, onDelete, stateValue }) => {
  const searchInput = document.querySelector('input[name=' + name + ']');
  const handleDelete = () => {
    searchInput.value = '';
    onDelete();
  };
  return (
    <div className='search-bar'>
      <i className="search-logo" aria-hidden="true"><img src={logo} /></i>
      <input
        onChange={onChange}
        placeholder={placeholder}
        className="f-input"
        name={name}
      />
      {
        onDelete && RA.isNotNilOrEmpty(stateValue) && <i className="delete-button" aria-hidden="true">
          <button onClick={handleDelete}>
            <img src={cross} />
          </button>
        </i>
      }
    </div>
  );
};

SearchInputWithLogo.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  logo: PropTypes.string,
  placeholder: PropTypes.string,
  onDelete: PropTypes.func,
  stateValue: PropTypes.string
};

export default SearchInputWithLogo;
