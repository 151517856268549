import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Select, { components } from 'react-select';
import { iconSelectDropdown } from '@@images';

const NoOptionsMessage = props => {
  return (
    <components.NoOptionsMessage {...props}>
      {'Aucun résultat...'}
    </components.NoOptionsMessage>
  );
};

const Placeholder = () => null;

const IndicatorSeparator = () => null;

const Control = ({ children, ...props }) => {
  const { isDisabled } = props.selectProps;
  return (
    <components.Control
      {...props}
      className={classNames({
        'f-select-edit-adherent-control-disabled': isDisabled,
        'f-select-edit-adherent-control': !isDisabled
      })}
    >
      {children}
    </components.Control>
  );
};

const DropdownIndicator = props => {
  const { isDisabled } = props.selectProps;
  return (
    <components.DropdownIndicator {...props}>
      <div className={classNames({
        'f-select-arrow-box-disabled': isDisabled,
        'f-select-arrow-box': !isDisabled
      })}>
        <img src={iconSelectDropdown} alt={'dropdown'}/>
      </div>
    </components.DropdownIndicator>
  );
};

const EditSelect = ({ options, selected, onChange, isDisabled }) => {
  return (
    <div className={'f-select-edit-adherent-frame'}>
      <Select
        isDisabled={isDisabled}
        className={'f-select-edit-adherent-container'}
        value={ selected }
        onChange={ onChange }
        options={ options }
        styles={{
          dropdownIndicator: () => ({}),
          control: () => ({}),
          menu: provided => ({ ...provided, marginTop: '4px' })
        }}
        components={{
          Placeholder,
          IndicatorSeparator,
          DropdownIndicator,
          Control,
          NoOptionsMessage
        }}
      />
    </div>
  );
};

EditSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })).isRequired,
  selected: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired
};

EditSelect.defaultProps = {
  options: [],
  selected: { label: '', value: '' },
  onChange: () => {},
  isDisabled: false
};

export default EditSelect;
