import React from 'react';
import Select from 'react-select';

const R = require('ramda');

const SelectMessageAction = ({
  defaultValue,
  messageThemes,
  onChange
}) => {
  const [state, setState] = React.useState({
    messageTheme: null,
    messageSubTheme: null
  });

  const [subThemeOptions, setSubThemeOption] = React.useState(null);

  const messageThemesOptions = R.map(R.applySpec({
    label: R.prop('title'),
    value: R.prop('id'),
    actionId: R.prop('actionId'),
    subThemes: R.prop('subThemes')
  }))(messageThemes);

  const $onChange = (value) => {
    if (R.is(Function, onChange)) onChange(value);
  };

  const onChangeMessageTheme = (selectedMessageTheme) => {
    setState(R.compose(
      R.assoc('messageTheme', selectedMessageTheme),
      R.assoc('messageSubTheme', null)
    ));

    if (!R.isNil(R.prop('actionId', selectedMessageTheme))) {
      setSubThemeOption(null);
      $onChange({
        theme: selectedMessageTheme.label,
        subTheme: null,
        actionId: selectedMessageTheme.actionId
      });
    } else {
      setSubThemeOption(R.map(R.applySpec({
        label: R.prop('title'),
        value: R.prop('actionId'),
        attachments: R.prop('attachments')
      }))(selectedMessageTheme.subThemes));
    }
  };

  const onChangeMessageSubTheme = (selectedMessageSubTheme) => {
    setState(R.assoc('messageSubTheme', selectedMessageSubTheme));
    $onChange({
      theme: state.messageTheme.label,
      subTheme: selectedMessageSubTheme.label,
      actionId: selectedMessageSubTheme.value,
      attachments: selectedMessageSubTheme.attachments
    });
  };

  /**
   * si le themeId est passé en query param on le sélectionne par défaut
   */
  React.useEffect(() => {
    const themeId = R.prop('themeId', defaultValue);
    if (R.isNil(themeId)) return;
    const messageTheme = R.find(R.propEq('value', themeId), messageThemesOptions);
    onChangeMessageTheme(messageTheme);
  }, []);


  return (
    <div>
      <Select
        value={state.messageTheme}
        onChange={onChangeMessageTheme}
        placeholder="Votre demande concerne..."
        options={messageThemesOptions}
      />
      {!R.isNil(subThemeOptions) && (
        <Select
          value={state.messageSubTheme}
          onChange={onChangeMessageSubTheme}
          placeholder="Vous souhaitez modifier..."
          options={subThemeOptions}
        />
      )}
    </div>
  );
};

export default SelectMessageAction;
