import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda';
import useKeycloakSession from '../../../hooks/useKeycloakSession';
import dateToHumanFull from '@@lib/dateToHumanFull';
import QAdherent from '@@queries/QAdherent';
import QMember from '@@queries/QMember';

const Message = ({
  value,
  getMemberNames,
  getAdherentNames,
  messageId,
  subscriberId
}) => {
  const {
    dates,
    text,
    attachments,
    sender
  } = value;
  const { user } = useKeycloakSession();
  const { customerId } = user;

  const isFromSubscriber = R.equals(subscriberId, sender);
  const isFromMe = R.equals(customerId, sender);

  const classes = classNames('f-messaging-message col-span-9',
    {
      'col-start-1': isFromSubscriber,
      'col-start-2': !isFromSubscriber
    });

  const downloadAttachmentUrl = (attachment) => {
    return `/documents/download/${messageId}/${R.propOr('', 'id', attachment)}?fileName=${R.propOr('', 'fileName', attachment)}`;
  };

  return (
    <div className="grid grid-cols-10">

      <div className={classes}>
        <div className="f-messaging-message-header">
          {
            isFromSubscriber && <QAdherent args={{ id: sender }}>
              {({ adherentInfo }) => (
                <>
                  <div className="capitalize">{getAdherentNames(adherentInfo.firstName, adherentInfo.lastName)}</div>
                </>
              )}
            </QAdherent>
          }
          {
            !isFromSubscriber &&
            <>
              {isFromMe
                ? <div className="capitalize">{'Moi'}</div>
                : <QMember args={{ id: sender }}>
                  {({ member }) => (
                    <div className="capitalize">{getMemberNames(member)}</div>
                  )}
                </QMember>
              }
            </>
          }
          <div>Le {dateToHumanFull(dates.creation)}</div>
        </div>
        <div className="f-messaging-message-content">{text}</div>
        {R.length(attachments) > 0 && (
          <div className="f-messaging-message-attachments">
            {
              attachments.map((attachment) =>
                <a key={attachment.id}
                  className="f-messaging-message-attachment"
                  href={downloadAttachmentUrl(attachment)}
                  target={'_blank'} rel={'noreferrer'}
                >
                  <img src="/icons/icon-paperclip.svg" alt={'paperclip-icon'}/>
                  <div className="px-2">
                    {attachment.fileName}
                  </div>
                </a>
              )}
          </div>
        )}
      </div>
    </div>
  );
};

Message.propTypes = {
  value: PropTypes.shape({
    attachments: PropTypes.array.isRequired,
    dates: PropTypes.shape({
      creation: PropTypes.string.isRequired,
      lastUpdate: PropTypes.string
    }).isRequired,
    id: PropTypes.string.isRequired,
    order: PropTypes.number.isRequired,
    recipients: PropTypes.arrayOf(
      PropTypes.string
    ).isRequired,
    sender: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired,
  getMemberNames: PropTypes.func.isRequired,
  getAdherentNames: PropTypes.func.isRequired,
  messageId: PropTypes.string.isRequired,
  subscriberId: PropTypes.string
};

export default Message;
