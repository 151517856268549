import React from 'react';
import { useUser } from '@@hooks';

import Forms from '@@components/forms';
import useForms from '@@formsHook';

const SampleBlock = () => {
  const { onSignOut } = useUser();

  const {
    // fetching,
    onFormsSubmit,
    formsData
  } = useForms('sampleForm');

  const onSubmit = (data) => {
    onFormsSubmit({
      email: data.email,
      firstname: data.firstname,
      lastname: data.lastname
    });
    console.log(JSON.stringify(data));
  };

  return <Forms
    formsData={formsData}
    defaultValues={{
      lastname: null,
      firstname: null,
      email: null
    }}
    onSubmit={onSubmit}
    submitSection={() => (
      <>
        <button type="submit" className="f-button f-button-blue">{'Sauvegarder'}</button>
        <button onClick={onSignOut} className="f-button">{'Me déconnecter'}</button>
      </>
    )}
  />;
};

export default SampleBlock;
