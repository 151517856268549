
import moment from 'moment';
import * as R from 'ramda';

const getFormatedAdherentBirthDate = (adherent) => {
  moment.updateLocale(moment.locale(), { invalidDate: 'Date de naissance indisponible' });
  return moment(R.propOr('', 'birthDate', adherent)).format('DD/MM/YYYY');
};

export default getFormatedAdherentBirthDate;
