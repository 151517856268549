import React from 'react';
import { graphql } from 'react-relay';
import Query from '../Query';
import {
  populateChildren
} from './toolbox';

export const QAdherentDocumentInfoQuery = graphql`
  query QAdherentDocumentInfoQuery($subscriberId: ID!, $paginate: PaginateInput){
    documentsByAdherent(subscriberId: $subscriberId, paginate: $paginate) {
      data {
        fileId
        fileSource
        title
        fileName
        type
      }
      pagination {
        total
        limit
        page
        pages      
      }
    } 
  }
`;

const QAdherentDocumentInfo = ({
  args,
  children,
  childDataProp
}) => {
  return (
    <Query
      query={QAdherentDocumentInfoQuery}
      args={args}
      fetchPolicy="network"
    >
      {populateChildren(['documentsByAdherent'])(children, childDataProp)}
    </Query>
  );
};

export default QAdherentDocumentInfo;
