import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

// return true iff the discussion has at least 1 message with an attachment
export const hasAnyAttachment = discussion => {
  const allMessagesData = R.pathOr([], ['allMessages', 'data'])(discussion);
  const hasAttachment = R.propSatisfies(RA.isNotNilOrEmpty, 'attachments');
  return R.any(hasAttachment)(allMessagesData);
};

// discussion properties
export const getDiscussionId = R.prop('id');
export const getAllMessages = R.prop('allMessages');
export const getRecipients = R.prop('recipients');
export const getMessageTitle = R.prop('title');
export const getAssignedMembersIds = R.propOr(['', '', ''], 'assignedTo');
export const getDiscussionStatus = R.prop('status');
export const getDiscussionPriority = R.prop('priority');
export const getCollaborator = R.prop('collaborator');

export const isClosed = discussion => {
  return R.equals('CLOSED', getDiscussionStatus(discussion));
};

export const isOpen = discussion => {
  return R.equals('OPENED', getDiscussionStatus(discussion));
};

export const hasNoAffectedCollaborator = R.compose(
  R.anyPass([RA.isNilOrEmpty, R.equals('none')]),
  R.nth(2),
  getAssignedMembersIds
);

export const getAssignedSubscriberId = R.compose(
  R.head,
  getAssignedMembersIds
);

export const hasAssignedSubscriberId = R.compose(
  RA.isNotNilOrEmpty,
  getAssignedSubscriberId
);
