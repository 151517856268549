import React from 'react';
import PropTypes from 'prop-types';

const SocialNumber = ({
  formatedSocialNumber
}) => {
  return <>
    {formatedSocialNumber}
  </>;
};

SocialNumber.propTypes = {
  formatedSocialNumber: PropTypes.string.isRequired
};

export default SocialNumber;
