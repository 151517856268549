import React from 'react';

const Block = ({
  children,
  title
}) => {
  return (
    <div className="f-block">
      <div className="f-block-title">{title}</div>
      {children}
    </div>
  );
};

export default Block;
