/**
*
* WARNING : fichier généré par forms, ne pas le modifier directement
*
**/
const R = require('ramda');

import useFetching from '@@hooks/useFetching';
import { useRelayEnvironment } from 'react-relay';

import {

  AdherentAddressMutation,

  AdherentEmailsMutation,

  AdherentFamilyStatusMutation,

  AdherentPersonalInformationMutation,

  AdherentPhoneNumbersMutation,

  CloseDiscussionMutation,

  CollabUpdateDiscussionAffectationMutation,

  FirstConnectionStep1Mutation,

  FirstConnectionStep2Mutation,

  ManagerUpdateDiscussionAffectationMutation,

  ManagerUpdateDiscussionPriorityMutation,

  MeBlockMutation,

  OpenDiscussionMutation,

  SampleFormMutation,

  SendDiscussionMutation,

  SendGestDiscussionMutation,

  SendMessageMutation,

  SignInMutation,
} from '@@formsMutations';

import {

  adherentAddressFormData,

  adherentEmailsFormData,

  adherentFamilyStatusFormData,

  adherentPersonalInformationFormData,

  adherentPhoneNumbersFormData,

  closeDiscussionFormData,

  collabUpdateDiscussionAffectationFormData,

  firstConnectionStep1FormData,

  firstConnectionStep2FormData,

  managerUpdateDiscussionAffectationFormData,

  managerUpdateDiscussionPriorityFormData,

  meBlockFormData,

  openDiscussionFormData,

  sampleFormFormData,

  sendDiscussionFormData,

  sendGestDiscussionFormData,

  sendMessageFormData,

  signInFormData,
} from '@@formsData';

const handler = {

  adherentAddress: {
    mutation: AdherentAddressMutation,
    formData: adherentAddressFormData
  },

  adherentEmails: {
    mutation: AdherentEmailsMutation,
    formData: adherentEmailsFormData
  },

  adherentFamilyStatus: {
    mutation: AdherentFamilyStatusMutation,
    formData: adherentFamilyStatusFormData
  },

  adherentPersonalInformation: {
    mutation: AdherentPersonalInformationMutation,
    formData: adherentPersonalInformationFormData
  },

  adherentPhoneNumbers: {
    mutation: AdherentPhoneNumbersMutation,
    formData: adherentPhoneNumbersFormData
  },

  closeDiscussion: {
    mutation: CloseDiscussionMutation,
    formData: closeDiscussionFormData
  },

  collabUpdateDiscussionAffectation: {
    mutation: CollabUpdateDiscussionAffectationMutation,
    formData: collabUpdateDiscussionAffectationFormData
  },

  firstConnectionStep1: {
    mutation: FirstConnectionStep1Mutation,
    formData: firstConnectionStep1FormData
  },

  firstConnectionStep2: {
    mutation: FirstConnectionStep2Mutation,
    formData: firstConnectionStep2FormData
  },

  managerUpdateDiscussionAffectation: {
    mutation: ManagerUpdateDiscussionAffectationMutation,
    formData: managerUpdateDiscussionAffectationFormData
  },

  managerUpdateDiscussionPriority: {
    mutation: ManagerUpdateDiscussionPriorityMutation,
    formData: managerUpdateDiscussionPriorityFormData
  },

  meBlock: {
    mutation: MeBlockMutation,
    formData: meBlockFormData
  },

  openDiscussion: {
    mutation: OpenDiscussionMutation,
    formData: openDiscussionFormData
  },

  sampleForm: {
    mutation: SampleFormMutation,
    formData: sampleFormFormData
  },

  sendDiscussion: {
    mutation: SendDiscussionMutation,
    formData: sendDiscussionFormData
  },

  sendGestDiscussion: {
    mutation: SendGestDiscussionMutation,
    formData: sendGestDiscussionFormData
  },

  sendMessage: {
    mutation: SendMessageMutation,
    formData: sendMessageFormData
  },

  signIn: {
    mutation: SignInMutation,
    formData: signInFormData
  },

}
const useForms = (formName, { updater } = {}) => {
  const environment = useRelayEnvironment();
  const fetching = useFetching();

  const onFormsSubmit = (input) => {
    fetching.start();
    handler[formName].mutation(
      environment,
      input,
      (ok, error, payload) => {
        if (!ok) return fetching.stop(R.isNil(error) ? 'undefined_error' : error);
        fetching.stop(null, payload);
      },
      { updater }
    );
  };

  return {
    fetching,
    onFormsSubmit,
    formsData: handler[formName].formData
  };
};

export default useForms;