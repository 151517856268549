import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import QAllDiscussions from '@@queries/QAllDiscussions';
import { DiscussionFrame, DiscussionList, NewDiscussionFrame } from './components';
import { StringParam, useQueryParam } from 'use-query-params';
import { useKeycloakSession, useAdherentWithpagination, useMessageNotifier } from '@@hooks';
import { Roles, discussionBaseFilter } from '@@enum';
import { priorityOptions, discussionThemesInputValue } from '@@data';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { useFetchQuery } from '@@hooks/useReferences';
import { QBackOfficeMessageThemesQuery } from '@@queries/QBackOfficeMessageThemes';
import { QExtranetMessageThemesQuery } from '@@queries/QExtranetMessageThemes';
import { QGroupsQuery } from '@@queries/QGroups';
import { SearchInput } from '@@components/input';
import { buildCollaboratorList, sortByNameCaseInsensitive } from '@@pages/message/utils';
import HasBeenNotifiedMutation from '../../_graphql/mutations/HasBeenNotifiedMutation';
import { useRelayEnvironment } from 'react-relay';
import { stealSearchLogo } from '@@images';

const Home = ({ match, pathConfig, history }) => {
  const { nbMessagesNotReaded, messageNotified } = useMessageNotifier();
  const environment = useRelayEnvironment();
  const { user } = useKeycloakSession();
  const { setAsyncSelectData } = useAdherentWithpagination();
  const userRole = R.propOr('', 'role')(user);
  const isManager = R.equals(userRole, Roles.MANAGER);
  const isGestionnaire = R.equals(userRole, Roles.GESTIONNAIRE);
  const userId = R.prop('customerId')(user);

  const {
    data: dataBackOfficeMessageThemes
  } = useFetchQuery(QBackOfficeMessageThemesQuery);
  const {
    data: dataExtranetMessageThemes
  } = useFetchQuery(QExtranetMessageThemesQuery);
  const {
    isLoaded: isLoadedListGroups,
    data: dataListGroups
  } = useFetchQuery(QGroupsQuery);

  const extranetMessageThemes = R.pathOr([], ['references', 'messageThemesExtranet'], dataExtranetMessageThemes);
  const backOfficeMessageThemes = R.pathOr([], ['references', 'messageThemesBackOffice'], dataBackOfficeMessageThemes);

  const listGroups = isLoadedListGroups
    ? R.prop('groups', dataListGroups) : [];

  const discussionId = R.path(['params', 'discussionId'], match);
  const [action, setAction] = useQueryParam('action', StringParam);

  // Controls for the filter fields
  const [status, setStatus] = useState(discussionBaseFilter.CLOSED);
  const [priorities, setPriorities] = useState([]);
  const [themes, setThemes] = useState(sortByNameCaseInsensitive(discussionThemesInputValue));
  const [groups, setGroups] = useState([]);
  const [collaborators, setCollaborators] = useState([]);
  const [adherentId, setAdherentId] = useState(null);

  // Filters sent to the messaging service
  const [filter, setFilter] = React.useState({});
  const [page, setPage] = React.useState(1);
  const [filterAdherent, setFilterAdherent] = React.useState();

  const doDisplayNewDiscussion = R.equals(discussionId, 'ouvrir');
  const doDisplayDiscussion = RA.isNotNil(discussionId);

  useEffect(() => {
    if (!doDisplayNewDiscussion && doDisplayDiscussion) {
      HasBeenNotifiedMutation(environment, { discussionId }, () => {});
    }
  }, [discussionId]);

  // ======== Filter: Update when a controlled state updates
  useEffect(() => {
    const filterStatus = R.equals(status, discussionBaseFilter.CLOSED)
      ? ['CLOSED'] : ['OPENED'];
    const filterPriorities = R.compose(
      R.map(R.propOr(-1, 'value')),
      R.filter(R.propEq('active', true))
    )(priorities);

    const filterThemes = R.compose(
      R.reduce(R.concat, []),
      R.map(R.propOr([], 'keys')),
      R.filter(R.propEq('active', true))
    )(themes);

    // Retrieve the checked groups
    const checkedGroups = R.filter(R.propEq('active', true))(groups);
    // When no group is checked, still apply the user groups in our filter
    const activeGroups = R.isEmpty(checkedGroups) ? groups : checkedGroups;
    const filterGroups = R.map(R.propOr('', 'value'))(activeGroups);

    const filterCollaborators = isManager
      ? R.compose(
        R.map(R.propOr('', 'value')),
        R.filter(R.propEq('active', true))
      )(collaborators) : [];
    const isStatusMine = R.equals(status, discussionBaseFilter.MINE);
    const filterAssignedToSubscriber = RA.isNotNilOrEmpty(filterAdherent) ? [filterAdherent] : [];
    const filterAssignedToGroup = isStatusMine ? [] : filterGroups;
    const filterAssignedToCollaborator = isStatusMine ? [userId] : filterCollaborators;

    // NB: when no priorities are toggled, ditch the 'priority' prop. In this
    // case the query retrieves all discussions, including special priority 0
    const composePriority = R.all(R.propEq('active', false))(priorities)
      ? R.dissoc('priority') : R.assoc('priority', filterPriorities);
    const composeActionId = filter => RA.isNilOrEmpty(filterThemes)
      ? R.dissoc('actionId', filter)
      : R.assoc('actionId', filterThemes, filter);

    const newFilter = R.compose(
      R.assoc('status', filterStatus),
      composePriority,
      composeActionId,
      R.assoc('assignedToSubscriber', filterAssignedToSubscriber),
      R.assoc('assignedToGroup', filterAssignedToGroup),
      R.assoc('assignedToCollaborator', filterAssignedToCollaborator)
    )(filter);

    setFilter(newFilter);
  }, [status, priorities, themes, groups, collaborators, isManager, userId, filterAdherent]);

  // ======== Filter: State Initialisations
  useEffect(() => {
    const initStatus = isGestionnaire ? discussionBaseFilter.MINE
      : isManager ? discussionBaseFilter.OPENED
        : discussionBaseFilter.CLOSED;
    setStatus(initStatus);
  }, [isManager, isGestionnaire]);

  // all priorities are toggled off by default
  useEffect(() => {
    const initPriorities = R.map(
      ({ value, tag }) => ({ value, tag, active: false }),
      priorityOptions);
    setPriorities(initPriorities);
  }, []);

  const getCollaboratorList = groupList => {
    const uniquePool = buildCollaboratorList(groupList);
    const collaborators = R.map(({ id, fullName }) => ({
      value: id,
      label: fullName,
      active: false
    }))(uniquePool);
    return R.prepend({
      value: 'none',
      label: 'Non assigné',
      active: false
    }, collaborators);
  };

  // Initialize the groups and collaborators once loaded
  useEffect(() => {
    if (isLoadedListGroups) {
      // Managers can see all groups, others can only see their own groups
      let initGroups = R.map(
        ({ id, name }) => ({ value: id, label: name, active: false })
      )(listGroups);
      if (!isManager) {
        initGroups = R.filter(R.compose(
          R.includes(R.__, user.groups),
          R.propOr('', 'value'))
        )(initGroups);
      }
      setGroups(initGroups);

      const initCollaborators = getCollaboratorList(listGroups);
      setCollaborators(initCollaborators);
    }
  }, [isLoadedListGroups, listGroups, user.groups, isManager]);

  const setGroupsAndTheme = (values, groupsSelected) => {
    const filterThemesByGroup = group => R.filter(R.propEq('group', group), discussionThemesInputValue);

    const reclamationTheme = R.find(R.propEq('value', 'ACTION-07'), discussionThemesInputValue);

    const gestionReclamationThemeKeys = R.compose(
      R.reject(R.equals('ACTION-07-01')),
      R.reject(R.equals('ACTION-07-02')),
      R.prop('keys')
    )(reclamationTheme);

    const setThemeAndReclamationKeys = (group, keys) => R.always({ groupTheme: filterThemesByGroup(group), reclamationKey: keys });

    // On récupère les thèmes associé au groupe sélectionné ainsi que les clefs du sous thème réclamation qui émane de ce groupe
    const getThemeToDisplayByGroup = R.cond([
      [R.propEq('value', 'GroupeSinistres'), setThemeAndReclamationKeys('GroupeSinistres', ['ACTION-07-02'])],
      [R.propEq('value', 'GroupeCotisations'), setThemeAndReclamationKeys('GroupeCotisations', ['ACTION-07-01'])],
      [R.propEq('value', 'GroupeGestion'), setThemeAndReclamationKeys('GroupeGestion', gestionReclamationThemeKeys)],
      [R.propEq('value', 'GroupeReclamations'), setThemeAndReclamationKeys('GroupeReclamations', R.prop('keys', reclamationTheme))]
    ]);

    // On récupère en fonction de la prop passé en paramètre un tableau de thème ou de clefs de sous thème réclamation
    const getThemesOrReclamationKeysBygroup = (groupThemeOrReclamationKey) => R.chain(
      R.compose(
        R.prop(groupThemeOrReclamationKey),
        getThemeToDisplayByGroup
      ));

    // On récupère un tableau de clefs du sous thème réclamation liées à tout les groupe sélectionnés et on retire les doublons
    const reclamationKeys = R.compose(
      R.uniq,
      getThemesOrReclamationKeysBygroup('reclamationKey')
    )(R.defaultTo([], groupsSelected));

    // On récupère les thèmes liés à tout les groupes sélectionnés
    const themesFiltered = getThemesOrReclamationKeysBygroup('groupTheme')(R.defaultTo([], groupsSelected));

    // Si au moins un groupe est sélectionné, on ajoute manuellement le thème réclamation avec le tableau de clefs précedemment récupéré
    // Si non, on affiche tout les thèmes
    const themesTodisplay = R.ifElse(
      RA.isNotNilOrEmpty,
      R.compose(
        R.append({
          value: 'ACTION-07',
          label: 'Réclamations',
          keys: reclamationKeys,
          active: false
        }),
        R.reject(R.propEq('label', 'Réclamations'))
      ),
      R.always(discussionThemesInputValue)
    )(themesFiltered);

    setThemes(sortByNameCaseInsensitive(themesTodisplay));
    setGroups(values);
  };

  const updateFilterByName = (name) => (values, selected) => {
    const setter = R.cond([
      [R.equals('status'), R.always(setStatus)],
      [R.equals('priority'), R.always(setPriorities)],
      [R.equals('themes'), R.always(setThemes)],
      [R.equals('groups'), R.always(setGroupsAndTheme)],
      [R.equals('collaborators'), R.always(setCollaborators)],
      [R.T, () => {}]
    ])(name);
    setter(values, selected);
    setPage(1);
  };

  const [inputThemeValue, setInputThemeValue] = React.useState('');
  const [isOpenFilter, setIsOpenFilter] = React.useState(false);
  const [openInputs, setOpenInputs] = React.useState({ subTheme: false, reply: false });

  const onChangeAction = (action) => {
    setAction(action);
  };

  const onSelectDiscussion = (discussionId) => {
    history.push(pathConfig.viewPath(discussionId));
  };

  const handleNewMessageButton = () => {
    setInputThemeValue('');
    setOpenInputs({ subTheme: false, reply: false });
  };

  const discussionTitle = (actionId) => {
    const actionIdGroups = R.match(/(?:BO)?ACTION-(\d{2})-?(\d{2})?/, actionId);
    const [themeId, subThemeId] = R.drop(1, actionIdGroups);
    const getMessageThemes = R.includes('BO', actionId) ? backOfficeMessageThemes : extranetMessageThemes;
    const themeTitle = R.propOr([], 'title', R.find(R.propEq('id', themeId), getMessageThemes));
    const subThemeTitle = R.ifElse(
      RA.isNotNilOrEmpty,
      R.compose(
        R.propOr('', 'title'),
        R.find(R.propEq('id', `${themeId}-${subThemeId}`)),
        R.propOr([], 'subThemes'),
        R.find(R.propEq('id', themeId))
      ),
      R.always([])
    );
    return R.join(' - ', R.reject(R.anyPass([R.isNil, R.isEmpty]), [themeTitle, subThemeTitle(getMessageThemes)]));
  };

  const getMemberNames = (member) => {
    const firstName = R.propOr('', 'firstName', member);
    const lastName = R.propOr('', 'lastName', member);
    return R.join(' ', R.reject(R.anyPass([R.isNil, R.isEmpty]), [firstName, lastName]));
  };

  const getAdherentNames = (firstPlaceName, secondPlaceName) => R.compose(
    R.join(' '),
    R.reject(R.anyPass([R.isNil, R.isEmpty]))
  )([firstPlaceName, secondPlaceName]);

  const onChangeAdherentId = (val) => {
    setAdherentId(val);
    setFilterAdherent(R.propOr('', 'value', val));
  };

  const loadOptions = (inputValue, callback) => {
    const newAdherentFilter = { multiProps: inputValue };
    setAsyncSelectData({
      callback,
      filter: newAdherentFilter
    });
  };

  return (
    <div className="w-full flex flex-wrap justify-between mt-8">
      <div className="f-block">
        <div className="w-1/3 p-6 border-r-2">
          <div className="flex items-center justify-between">
            <div className="flex">
              <h3>Messages</h3>
              {R.gt(0, nbMessagesNotReaded) && <div className="f-chip ml-2">{nbMessagesNotReaded}</div>}
            </div>
            <Link className="f-button-sm"
              onClick={handleNewMessageButton}
              to={pathConfig.newMessagePath}
            >
              {'Nouveau message'}
            </Link>
          </div>
          <div className="my-4">
            <SearchInput
              onChange={onChangeAdherentId}
              placeholder={'Rechercher par nom ou n° d\'adhérent'}
              loadOptions={loadOptions}
              classNamePrefix="f-select-adherent-search"
              singleValue={adherentId}
              logo={stealSearchLogo}
            />
          </div>
          <QAllDiscussions args={{
            filter: filter,
            paginate: { page, limit: 10 }
          }}>
            {({ allDiscussions }) => (
              <DiscussionList
                status={status}
                priorities={priorities}
                themes={themes}
                groups={groups}
                collaborators={collaborators}
                updateFilterByName={updateFilterByName}
                filter={filter}
                setFilter={setFilter}
                userRole={userRole}
                current={discussionId}
                allDiscussions={allDiscussions}
                items={R.propOr([], 'data', allDiscussions)}
                pagination={R.propOr({}, 'pagination', allDiscussions)}
                onSelect={onSelectDiscussion}
                page={page}
                discussionTitle={discussionTitle}
                setPage={setPage}
                getAdherentNames={getAdherentNames}
                isOpenFilter={isOpenFilter}
                setIsOpenFilter={setIsOpenFilter}
                messageNotified={messageNotified}
              />
            )}
          </QAllDiscussions>
        </div>
        {R.equals(discussionId, 'ouvrir')
          ? <NewDiscussionFrame
            action={action}
            onChangeAction={onChangeAction}
            inputThemeValue={inputThemeValue}
            setInputThemeValue={setInputThemeValue}
            isManager={isManager}
            openInputs={openInputs}
            setOpenInputs={setOpenInputs}
          />
          : <DiscussionFrame
            discussionId={discussionId}
            discussionTitle={discussionTitle}
            getMemberNames={getMemberNames}
            getAdherentNames={getAdherentNames}
          />
        }
      </div>
    </div>
  );
};

Home.propTypes = {
  match: PropTypes.shape({
    isExact: PropTypes.bool.isRequired,
    params: PropTypes.shape({
      discussionId: PropTypes.string
    }).isRequired,
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }).isRequired,
  pathConfig: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default Home;
