import React from 'react';
import { graphql } from 'react-relay';
import Query from '../Query';
import {
  populateChildren
} from './toolbox';

export const QGroupsQuery = graphql`
  query QGroupsQuery {
    groups {
      id
      name
      completeName
      collaborators {
        id
        firstName
        lastName
      }
    } 
  }
`;

const QGroups = ({
  args,
  children,
  childDataProp
}) => {
  return (
    <Query
      query={QGroupsQuery}
      args={args}
      fetchPolicy="network"
    >
      {populateChildren(['groups'])(children, childDataProp)}
    </Query>
  );
};

export default QGroups;
