export const logoBesse = '/images/logo-besse.svg';
export const powerLogo = '/icons/power.svg';
export const messagingLogo = '/images/illu-messagerie.svg';
export const adherentLogo = '/images/illu-adherent.svg';
export const logoAppa = '/images/logo-appa@2x.png';
export const logoMessaging = '/images/messaging.svg';
export const homeBg = '/images/fond-accueil.svg';
export const logoAdherents = '/images/illu-adherents.svg';
export const adherentCheck = '/images/adherent-check.svg';
export const editLogo = '/icons/edit.svg';
export const checkLogo = '/icons/icon-check.svg';
export const paperclipIcon = '/icons/icon-paperclip.svg';
export const binIcon = '/icon/bin.svg';
export const whiteSearchLogo = '/icon/white-search-logo.svg';
export const stealSearchLogo = '/icon/steal-search-logo.svg';
export const welcomeLogo = '/images/bienvenue.svg';
export const cornerTurquoiseDr = '/images/corner-turquoise-dr.svg';
export const cornerYellowUl = '/images/corner-yellow-ul.svg';
export const iconFilterClose = '/icon/icon-filters-open.svg';
export const iconFilterOpen = '/icon/icon-filters-close.svg';
export const iconCalendar = '/icons/calendar-2.svg';
export const arrowUp = 'arrow-up.svg';
export const arrowDown = 'arrow-down.svg';
export const bellIcon = '/icon/bell-icon.png';
export const cross = '/icon/cross.svg';
export const iconSelectDropdown = '/icons/dropdown.svg';
export const iconImportCsv = '/icons/import-csv.svg';
