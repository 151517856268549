import React from 'react';
import {
  Field
} from '@@components';

import { useForm } from 'react-hook-form';
import inputComponents from './inputs';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const R = require('ramda');

const Forms = ({
  formsData,
  defaultValues,
  hiddenFields,
  onChange,
  onSubmit,
  options,
  submitSection,
  columns,
  resetOnSubmit,
  isInline,
  alignedLabels,
  resetOnDefaultValuesChange,
  withBottomFieldMargin
}) => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    watch,
    errors,
    formState: { isSubmitSuccessful }
  } = useForm({ defaultValues });
  const [visible, setVisible] = React.useState({});
  const [hidden, setHidden] = React.useState({});

  const getInput = ({
    field,
    form
  }) => {
    const placeholder = R.equals(field.placeholder, '__UNDEFINED__')
      ? field.label : field.placeholder;
    if (!R.has(field.input, inputComponents)) return null;

    return React.createElement(
      inputComponents[field.input],
      {
        getValues,
        control,
        register,
        field: {
          ...field,
          placeholder
        },
        options,
        errors
      }
    );
  };

  const valuesRef = React.useRef(null);
  const defaultValuesRef = React.useRef(null);
  const formValues = watch();

  React.useEffect(() => {
    if (!resetOnDefaultValuesChange || R.equals(defaultValuesRef.current, defaultValues)) return;
    defaultValuesRef.current = defaultValues;
    reset(R.clone(defaultValues));
  }, [defaultValues]);

  React.useEffect(() => {
    const updateVisible = async (fields, values) => {
      const response = await Promise.all(fields.map(async field => {
        if (R.is(Function, field.handlers.visible)) {
          let value = field.handlers.visible(values);
          if (R.is(Promise, value)) value = await value;
          return { [field.name]: value };
        }
        return { [field.name]: true };
      }));
      setVisible(R.mergeAll(response));
    };

    // * Check if the values changed
    if (R.equals(valuesRef.current, formValues)) return;

    // * Update form visibility
    valuesRef.current = formValues;
    updateVisible(formsData, valuesRef.current);
    if (R.is(Function, onChange)) onChange(valuesRef.current);
  }, [formValues]);

  const hiddenFieldsRef = React.useRef();
  React.useEffect(() => {
    if (R.equals(hiddenFieldsRef.current, hiddenFields)) return;
    hiddenFieldsRef.current = hiddenFields;
    R.forEachObjIndexed((v, k) => setValue(k, v), hiddenFields);
  }, [hiddenFields]);

  React.useEffect(() => {
    if (resetOnSubmit && isSubmitSuccessful) {
      reset(R.clone(defaultValues));
    }
  }, [isSubmitSuccessful, reset]);

  const formClass = classNames({ 'f-forms-inline': isInline });
  const containerClass = isInline
    ? 'f-form-layout-inline'
    : `f-form-layout md:grid-cols-${columns}`;

  const isFieldIgnored = field => !visible[field.name];
  const isFieldHidden = field => R.or(
    R.equals(field.input, 'hidden'),
    R.has(field.name, hiddenFields));

  return (
    <form
      onSubmit={handleSubmit(onSubmit)} className={formClass}>
      <div className={containerClass}>
        {formsData.map((field) => {
          if (isFieldIgnored(field)) {
            return null;
          }
          if (isFieldHidden(field)) {
            return (
              <input key={field.name} name={field.name} type={'hidden'}
                ref={register} />
            );
          }

          const inputJSX = getInput({
            field,
            form: { control, register }
          });
          const alignedLabel = R.prop(field.name, alignedLabels);
          return (
            <Field key={field.name} withBottomFieldMargin={withBottomFieldMargin}
              label={R.when(R.equals('__UNDEFINED__'), R.always(''), field.label)} alignedLabel={alignedLabel}>
              { inputJSX }
            </Field>
          );
        })}
      </div>
      {submitSection({
        reset,
        setValue,
        getValues
      })}
    </form>
  );
};

export default Forms;

Forms.propTypes = {
  formsData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string
    })
  ),
  defaultValues: PropTypes.object,
  hiddenFields: PropTypes.object,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  options: PropTypes.object,
  submitSection: PropTypes.func.isRequired,
  columns: PropTypes.number,
  resetOnSubmit: PropTypes.bool,
  isInline: PropTypes.bool.isRequired,
  resetOnDefaultValuesChange: PropTypes.bool,
  withBottomFieldMargin: PropTypes.bool
};

Forms.defaultProps = {
  columns: 2,
  resetOnSubmit: false,
  isInline: false,
  resetOnDefaultValuesChange: false
};
