/**
*
* WARNING : fichier généré par forms, ne pas le modifier directement
*
**/



const formData = [
  
  {
    name: 'address1',
    required: true,
    input: 'text',
    source: '__UNDEFINED__',
    label: "__UNDEFINED__",
    placeholder: "adresse",
    
    handlers: {
      visible: undefined
    }
  },
  
  {
    name: 'address2',
    required: false,
    input: 'text',
    source: '__UNDEFINED__',
    label: "__UNDEFINED__",
    placeholder: "adresse",
    
    handlers: {
      visible: undefined
    }
  },
  
  {
    name: 'address3',
    required: false,
    input: 'text',
    source: '__UNDEFINED__',
    label: "__UNDEFINED__",
    placeholder: "adresse",
    
    handlers: {
      visible: undefined
    }
  },
  
  {
    name: 'zipCode',
    required: true,
    input: 'text',
    source: '__UNDEFINED__',
    label: "__UNDEFINED__",
    placeholder: "Code postal",
    
    handlers: {
      visible: undefined
    }
  },
  
  {
    name: 'city',
    required: true,
    input: 'text',
    source: '__UNDEFINED__',
    label: "__UNDEFINED__",
    placeholder: "Ville",
    
    handlers: {
      visible: undefined
    }
  },
  
  {
    name: 'country',
    required: true,
    input: 'text',
    source: '__UNDEFINED__',
    label: "__UNDEFINED__",
    placeholder: "Pays",
    
    handlers: {
      visible: undefined
    }
  },
  
];

export default formData;