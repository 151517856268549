import { Page } from '@@components';
import { welcomeLogo } from '@@images';
import withSize from '@@root/withSize';
import React from 'react';
import SearchAdherentBlock from './components/SearchAdherentBlock';

const Welcome = () => {
  return (
    <Page className="flex flex-col" svgLogo={welcomeLogo}>
      <SearchAdherentBlock />
    </Page>
  );
};

export default withSize(Welcome);
