import React from 'react';
import { Page } from '@@components';
import Home from './Home';

import EditAddress from './EditAddress';
import EditEmails from './EditEmails';
import EditPersonalInformation from './EditPersonalInformation';
import EditPhoneNumbers from './EditPhoneNumbers';
import EditFamilyStatus from './EditFamilyStatus';

import { Route, Switch, Redirect } from 'react-router-dom';
const R = require('ramda');

const paths = (root) => (k) => {
  const config = {
    familyStatus: {
      editPath: `${root}/modifier/informationFamiliales`,
      cancelPath: root
    },
    personalInformation: {
      editPath: `${root}/modifier/informationspersonnelles`,
      cancelPath: root
    },
    phoneNumbers: {
      editPath: `${root}/modifier/telephones`,
      cancelPath: root
    },
    emails: {
      editPath: `${root}/modifier/emails`,
      cancelPath: root
    },
    address: {
      editPath: `${root}/modifier/adresse`,
      cancelPath: root
    }
  };
  if (R.isNil(k)) return config;
  return R.prop(k, config);
};

const Index = ({
  match
}) => {
  const path = v => `${match.url}${v}`;
  const pathConfig = paths(match.url);
  return (
    <Page title="Mes informations personnelles">
      <Switch>
        <Route exact path={path('')} render={() => <Home pathConfig={pathConfig()} />} />
        <Route exact path={pathConfig('familyStatus').editPath} render={() => < EditFamilyStatus pathConfig={pathConfig('familyStatus')} />} />
        <Route exact path={pathConfig('personalInformation').editPath} render={() => < EditPersonalInformation pathConfig={pathConfig('personalInformation')} />} />
        <Route exact path={pathConfig('phoneNumbers').editPath} render={() => < EditPhoneNumbers pathConfig={pathConfig('phoneNumbers')} />} />
        <Route exact path={pathConfig('emails').editPath} render={() => < EditEmails pathConfig={pathConfig('emails')} />} />
        <Route exact path={pathConfig('address').editPath} render={() => < EditAddress pathConfig={pathConfig('address')} />} />
        <Redirect to={path('')} />
      </Switch>
    </Page>
  );
};

export default Index;
