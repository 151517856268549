import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
const R = require('ramda');

const Block = ({ blockClassName, link, img, blockName, nbMessagesNotReaded }) => {
  return (
    <div className={blockClassName}>
      <Link to={link}>
        <div className={'f-top-block-img'}>
          <img src={img} />
        </div>
      </Link>
      <div className={'font-semibold text-center'}>
        <div className="text-xs">{blockName}</div>
      </div>
      {R.gt(nbMessagesNotReaded, 0) && <div className="f-chip f-chip-absolute">{nbMessagesNotReaded}</div>}
    </div>
  );
};

Block.propTypes = {
  blockClassName: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  blockName: PropTypes.string.isRequired,
  nbMessagesNotReaded: PropTypes.number
};

export default Block;
