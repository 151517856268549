import PropTypes from 'prop-types';
import React from 'react';
import { iconImportCsv } from '@@images';
import * as Papa from 'papaparse';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

const idToOption = id => ({ label: id, value: id });

const SelectAdherentsHeader = ({ label, isMulti, onImportIDs }) => {
  const handleOnFileChange = (event) => {
    try {
      const file = event.target.files[0];
      Papa.parse(file, {
        complete: (results) => {
          // results.data should have 1 element per csv row (1 row == 1 id)
          const subscriberIds = R.compose(
            R.map(idToOption),
            R.uniq,
            R.reject(RA.isNilOrEmpty),
            R.map(R.propOr('', 0))
          )(results.data);
          onImportIDs(subscriberIds);
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleResetFile = event => {
    event.target.value = null;
  };

  const importFileButton = isMulti && (
    <>
      <input
        id={'filePicker'}
        type={'file'}
        accept='.csv'
        className={'hidden'}
        onClick={handleResetFile}
        onChange={handleOnFileChange}
      />
      <label htmlFor={'filePicker'}>
        <div className={'f-select-adherents-button-container mb-1'}>
          <img src={iconImportCsv} alt={'import-csv'} />
          <div className={'f-select-adherents-button-text'}>
            {'Importer un fichier'}
          </div>
        </div>
      </label>
    </>
  );

  return (
    <div className={'flex flex-row justify-between'}>
      <div className={'f-block-field-label'}>
        {label}
      </div>
      { importFileButton }
    </div>
  );
};

SelectAdherentsHeader.propTypes = {
  isMulti: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onImportIDs: PropTypes.func.isRequired
};

SelectAdherentsHeader.defaultProps = {
  onImportIDs: () => {}
};

export default SelectAdherentsHeader;
