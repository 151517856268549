import {
  commitMutation,
  graphql
} from 'react-relay';

const mutation = graphql`
mutation UpdatePersonalSituationMutation($id: ID!, $input: UpdatePersonalSituationInput) {
  updatePersonalSituation(id: $id, input: $input) {
    ok
    error
  }
}
`;

export default async (environment, { id, input }, done) => {
  const variables = {
    id,
    input
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: (proxyStore) => {},
      onCompleted: ({ updatePersonalSituation }) => {
        const { ok, error } = updatePersonalSituation;
        done(ok, error);
      },
      onError: err => {
        console.error(err);
      }
    }
  );
};
