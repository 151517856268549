import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, Switch } from 'react-router-dom';
import { Page } from '@@components';
import Home from './Home';
import { logoAdherents } from '@@images';
import AdherentEditPerso from '@@pages/adherent/AdherentEditPerso';
import AdherentEditPro from '@@pages/adherent/AdherentEditPro';
const R = require('ramda');
const paths = (root) => (k) => {
  const config = {
    adherent: {
      viewPath: (subscriberId) => {
        if (R.isNil(subscriberId)) return `${root}`;
        return `${root}/${subscriberId}`;
      },
      editPersonalPath: (subscriberId) => {
        if (R.isNil(subscriberId)) return `${root}`;
        return `${root}/${subscriberId}/modifier/informationsPersonnelles`;
      },
      editProfessionalPath: (subscriberId) => {
        if (R.isNil(subscriberId)) return `${root}`;
        return `${root}/${subscriberId}/modifier/informationsProfessionnelles`;
      },
      cancelPath: root
    }
  };
  if (R.isNil(k)) return config;
  return R.prop(k, config);
};

const Index = ({ match, history }) => {
  const path = v => `${match.url}${R.when(R.isNil, R.always(''), v)}`;
  const pathConfig = paths(match.url);

  return (
    <Switch>
      <Route
        exact path={[
          pathConfig('adherent').cancelPath,
          pathConfig('adherent').viewPath(':subscriberId')
        ]}
        render={({ match }) =>
          <Page className="flex flex-col" svgLogo={logoAdherents}>
            <Home
              pathConfig={pathConfig('adherent')}
              match={match}
              history={history}
            />
          </Page>
        }
      />
      <Route
        exact path={pathConfig('adherent').editPersonalPath(':subscriberId')}
        render={({ match }) =>
          <Page withBanner={false}>
            <AdherentEditPerso
              pathConfig={pathConfig('adherent')}
              match={match}
            />
          </Page>
        }
      />
      <Route
        exact path={pathConfig('adherent').editProfessionalPath(':subscriberId')}
        render={({ match }) =>
          <Page withBanner={false}>
            <AdherentEditPro
              pathConfig={pathConfig('adherent')}
              match={match}
            />
          </Page>
        }
      />
      <Redirect to={path()} />
    </Switch>
  );
};

Index.propTypes = {
  match: PropTypes.shape({
    isExact: PropTypes.bool.isRequired,
    params: PropTypes.shape({
      subscriberId: PropTypes.string
    }).isRequired,
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.object.isRequired
};

export default Index;
