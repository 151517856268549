/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserSessionRoleEnum = "ADHERENT" | "ADMIN" | "%future added value";
export type QUserSessionQueryVariables = {||};
export type QUserSessionQueryResponse = {|
  +userSession: ?{|
    +id: ?string,
    +username: ?string,
    +email: ?string,
    +role: ?UserSessionRoleEnum,
    +firstname: ?string,
    +lastname: ?string,
    +customerId: ?string,
  |}
|};
export type QUserSessionQuery = {|
  variables: QUserSessionQueryVariables,
  response: QUserSessionQueryResponse,
|};
*/


/*
query QUserSessionQuery {
  userSession {
    id
    username
    email
    role
    firstname
    lastname
    customerId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "UserSession",
    "kind": "LinkedField",
    "name": "userSession",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "username",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "role",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstname",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastname",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "customerId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QUserSessionQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QUserSessionQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "6f02bb845a5fcf460db98d4478242d86",
    "id": null,
    "metadata": {},
    "name": "QUserSessionQuery",
    "operationKind": "query",
    "text": "query QUserSessionQuery {\n  userSession {\n    id\n    username\n    email\n    role\n    firstname\n    lastname\n    customerId\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '94ee388af1e9b4922b57c488b5414a06';

module.exports = node;
