import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { adherentCheck } from '@@images';
import { Pagination } from '@@components';
import getFormatedAdherentBirthDate from '@@lib/formatBirthDate';
import * as R from 'ramda';

const AdherentList = ({
  adherents,
  onSelect,
  current,
  pagination,
  setPage,
  formatSocialNumber
}) => {
  const $onSelect = (subscriberId) => (e) => {
    e.preventDefault();
    onSelect(subscriberId);
  };

  const classes = (subscriberId) => classNames('f-messaging-discussions-item mb-4', {
    'f-messaging-discussions-item-current': subscriberId === current
  });

  return (
    <div>
      {
        adherents.map((adherent) => (
          <React.Fragment key={R.propOr('', 'id', adherent)} >
            {
              adherent && (
                <div className={classes(R.propOr('', 'id', adherent))} key={R.propOr('', 'id', adherent)} onClick={$onSelect(R.propOr('', 'id', adherent))}>
                  <div className="flex justify-between">
                    <div className="f-messaging-discussions-item-title">
                      {R.propOr('', 'lastName', adherent)} {R.propOr('', 'firstName', adherent)}
                    </div>
                    {R.propOr('', 'onBoarded', adherent) && <img src={adherentCheck} alt="adherent-check" />}
                  </div>
                  <div className="f-messaging-discussions-item-date text-steel">
                    <div>
                      {`${getFormatedAdherentBirthDate(adherent)} - ${formatSocialNumber(R.propOr('', 'insee', adherent))}`}
                    </div>
                    <div className="my-1">
                      {`N°adhérent ${R.propOr('', 'id', adherent)} - Contrat ${R.propOr('', 'contract', adherent)}`}
                    </div>
                  </div>
                </div>
              )}
          </React.Fragment>
        ))
      }
      {/* TODO styliser le composant de pagination dès livraison des assets */}
      <Pagination
        onChange={setPage}
        current={R.propOr(0, 'page', pagination)}
        total={R.propOr(0, 'total', pagination)}
        pageSize={R.propOr(0, 'limit', pagination)}
      />
    </div>
  );
};

AdherentList.propTypes = {
  adherents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      maidenName: PropTypes.string,
      onBoarded: PropTypes.bool,
      familyStatus: PropTypes.string,
      birthDate: PropTypes.string,
      insee: PropTypes.string
    })
  ),
  onSelect: PropTypes.func.isRequired,
  current: PropTypes.string,
  pagination: PropTypes.shape({
    total: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    pages: PropTypes.number.isRequired
  }),
  setPage: PropTypes.func,
  formatSocialNumber: PropTypes.func.isRequired
};

export default AdherentList;
