import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MessageList from './MessageList';
import QDiscussion from '@@queries/QDiscussion';
import { DiscussionCloseNotificationSubscription } from '@@graphql/subscriptions';
import { useRelayEnvironment } from 'react-relay';
import { useKeycloakSession } from '@@hooks';
import { discussionStatus, Roles } from '@@enum';
import ReplyTo from './ReplyTo';
import useForms from '@@formsHook';
import Forms from '@@components/forms';
import QAdherent from '@@queries/QAdherent';
import QGroups from '@@queries/QGroups';
import { MessageRecord } from '@@graphql/records/MessagesRecord';
import { SelectOneOption } from '@@components/input';
import { ErrorMessage } from '@@components';
import { priorityOptions } from '@@data';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import {
  getAllMessages,
  getAssignedMembersIds,
  getCollaborator,
  getDiscussionId,
  getDiscussionPriority,
  getDiscussionStatus,
  getMessageTitle,
  getRecipients,
  hasNoAffectedCollaborator,
  isOpen
} from '@@pages/message/components/utils';

const DiscussionFrame = ({
  discussionId,
  discussionTitle,
  getMemberNames,
  getAdherentNames
}) => {
  const { user } = useKeycloakSession();
  const userRole = R.propOr('', 'role')(user);
  const isManager = R.equals(userRole, Roles.MANAGER);
  const isGestionnaire = R.equals(userRole, Roles.GESTIONNAIRE);
  const userId = R.prop('customerId')(user);

  const [errorMessage, setErrorMessage] = React.useState({
    priority: null,
    openDiscussion: null,
    affectation: null
  });

  const [priorityValue, setPriorityValue] = React.useState({});

  const updater = (formName) => (input, store, response) => {
    const rec = MessageRecord(store);
    const discussion = R.path([formName, 'discussion'], response);
    if (RA.isNilOrEmpty(discussion)) return;
    rec.updateDiscussion(discussion);
  };

  const updateDiscussionFormName = isManager
    ? 'managerUpdateDiscussionAffectation'
    : 'collabUpdateDiscussionAffectation';
  const {
    fetching,
    onFormsSubmit,
    formsData
  } = useForms(
    updateDiscussionFormName,
    { updater: updater(updateDiscussionFormName) }
  );

  const autoUpdateAffectation = useForms(
    'managerUpdateDiscussionAffectation',
    { updater: updater('managerUpdateDiscussionAffectation') }
  );

  const environment = useRelayEnvironment();

  React.useEffect(() => {
    if (fetching.getError()) {
      setErrorMessage({ affectation: fetching.getError() });
    }
  }, [fetching.getError(), fetching.isDone()]);

  React.useEffect(() => {
    if (R.isNil(user.customerId) || R.isNil(discussionId)) return;
    const subscription = DiscussionCloseNotificationSubscription(environment, {
      customerId: user.customerId,
      discussionId: discussionId
    });
    return () => {
      if (!R.isNil(subscription)) subscription.dispose();
    };
  }, [discussionId]);

  const openDiscussion = useForms('openDiscussion');

  React.useEffect(() => {
    if (openDiscussion.fetching.getError()) {
      setErrorMessage({ openDiscussion: openDiscussion.fetching.getError() });
    }
    if (openDiscussion.fetching.isDone()) {
      setErrorMessage({
        openDiscussion: null,
        priority: null
      });
    }
  }, [openDiscussion.fetching.getError(), openDiscussion.fetching.isDone()]);

  if (R.isNil(discussionId)) return null;

  const onSubmit = (val) => {
    const newVal = R.assoc('discussionId', discussionId, val);
    onFormsSubmit(newVal);
  };

  const handleOpenDiscussion = () => {
    openDiscussion.onFormsSubmit({ discussionId });
  };

  const checkCollabId = R.cond([
    [R.equals('none'), R.F],
    [RA.notEqual(userId), R.T]
  ]);

  return (
    <div className={'f-messaging-discussion-frame w-2/3'}>
      <QDiscussion args={{ id: discussionId }}>
        {({ getDiscussion }) => {
          const [subscriberId, currentService, collaboratorId] = getAssignedMembersIds(getDiscussion);
          const actionId = getMessageTitle(getDiscussion);
          const currentDiscussionStatus = getDiscussionStatus(getDiscussion);
          const currentDiscussionId = getDiscussionId(getDiscussion);
          const collaborator = getCollaborator(getDiscussion);
          const priority = getDiscussionPriority(getDiscussion);

          const updatePriority = useForms('managerUpdateDiscussionPriority');
          const handleSelectPriority = (val) => {
            updatePriority.onFormsSubmit({
              discussionId,
              priority: val.value
            });
          };

          // Attempt to auto-affect the discussion on change
          useEffect(() => {
            if (R.allPass([R.always(isGestionnaire), isOpen,
              hasNoAffectedCollaborator]
            )(getDiscussion)) {
              autoUpdateAffectation.onFormsSubmit({
                service: currentService,
                collaboratorId: userId,
                discussionId: currentDiscussionId
              });
            }
          }, [currentDiscussionId]);

          useEffect(() => {
            const selectPriorityDefaultValue = R.find(
              R.propEq('value', priority), priorityOptions);
            setPriorityValue(selectPriorityDefaultValue);
          }, [priority, currentDiscussionStatus]);

          useEffect(() => {
            if (updatePriority.fetching.getError()) {
              setErrorMessage({ priority: updatePriority.fetching.getError() });
            }
            if (updatePriority.fetching.isDone()) {
              const newPriority = R.propOr(priority, 'priority',
                updatePriority.fetching.getPayload());
              const selectPriorityValue = R.find(
                R.propEq('value', newPriority), priorityOptions);
              setPriorityValue(selectPriorityValue);
              setErrorMessage({
                openDiscussion: null,
                priority: null
              });
            }
          }, [updatePriority.fetching.getError(), updatePriority.fetching.isDone()]);

          if (RA.propNotEq('id', discussionId, getDiscussion)) {
            // Waiting for the newly selected discussion to load
            return (<></>);
          }
          return (
            <>
              <div className="f-messaging-discussion-title">
                <div className="flex justify-between mb-4">
                  <div>
                    {RA.notEqual('none', subscriberId) && <QAdherent args={{ id: subscriberId }}>
                      {({ adherentInfo }) => <div className="f-messaging-discussion-title-adherent">
                        {getAdherentNames(R.propOr('', 'firstName', adherentInfo), R.propOr('', 'lastName', adherentInfo))}{' - N°'}{R.propOr('', 'id', adherentInfo)}
                      </div>}
                    </QAdherent>}
                    <div>
                      {discussionTitle(actionId, R.nth(1, R.split('-', actionId)), R.nth(1, R.split('BOACTION-', actionId)))}
                    </div>
                  </div>
                  <div className={'w-3/12'}>
                    {
                      isManager
                        ? <SelectOneOption
                          onChange={ handleSelectPriority }
                          placeholder={'Sélectionnez une note de priorité..'}
                          options={ priorityOptions }
                          value={ priorityValue }
                          sortedByLabel={ false }
                        /> : <div className="text-navy-blue text-lg text-bold text-right">
                          {R.propOr('', 'label', priorityValue)}
                        </div>
                    }
                  </div>
                </div>
                {errorMessage.priority && <ErrorMessage value={errorMessage.priority} />}
                {
                  (!isManager && collaborator) &&
                  <div className="my-2">
                    {'Affecté à'} <span className="font-bold">
                      {`${getMemberNames(collaborator)}`}
                    </span>
                  </div>
                }
                <div className="f-messaging-discussion-affect-selector">
                  <div className="f-messaging-discussion-title-affect mb-1">
                    {'Affecter à'}
                  </div>
                  {
                    checkCollabId(collaboratorId) && isGestionnaire ? <input
                      className="f-input"
                      value={currentService}
                      disabled={true}
                    />
                      : <QGroups>
                        {({ groups }) => {
                          const [affectationService, setAffectationService] = useState();
                          const [defaultValues, setDefaultValues] = useState({ discussionId });
                          useEffect(() => {
                            const safeCollaboratorId = R.compose(
                              R.toLower,
                              R.defaultTo('none')
                            )(collaboratorId);
                            setAffectationService(currentService);
                            setDefaultValues({
                              discussionId,
                              service: currentService,
                              collaboratorId: safeCollaboratorId
                            });
                          }, [currentService, collaboratorId, getDiscussion.id]);
                          const affectationServiceList = R.map(R.applySpec({
                            label: R.prop('name'),
                            value: R.prop('id')
                          }), groups);

                          const mapCollaboratorToOption = ({
                            id,
                            firstName,
                            lastName
                          }) => ({
                            value: R.toLower(id),
                            label: R.join(' ', R.reject(R.anyPass([R.isNil, R.isEmpty]), [firstName, lastName]))
                          });

                          const groupCollaborators = (acc, { collaborators }) => {
                            const currentCollaborators = R.map(mapCollaboratorToOption, collaborators);
                            return R.concat(acc, currentCollaborators);
                          };
                          const collaboratorsOptionByGroup = R.reduceBy(groupCollaborators, [{
                            value: 'none',
                            label: 'Aucun'
                          }], R.prop('id'), groups);

                          const handleOnChange = (change) => {
                            const newServiceId = R.propOr('', 'service', change);
                            const newCollaboratorId = R.propOr('', 'collaboratorId', change);

                            setDefaultValues({
                              discussionId,
                              service: newServiceId,
                              collaboratorId: RA.notEqual(newServiceId, affectationService) ? 'none' : newCollaboratorId
                            });

                            // Set the new service to update collaborators list
                            setAffectationService(newServiceId);
                            fetching.reset();
                          };

                          return <Forms
                            withBottomFieldMargin={false}
                            formsData={formsData}
                            isInline={true}
                            resetOnDefaultValuesChange={true}
                            defaultValues={defaultValues}
                            options={{
                              service: affectationServiceList,
                              collaborator: R.propOr([], affectationService, collaboratorsOptionByGroup)
                            }}
                            onChange={handleOnChange}
                            onSubmit={onSubmit}
                            submitSection={() => <button type="submit"
                              className="f-button-sm f-button-coral-reverse flex-shrink-0">
                              {'Mettre à jour'}
                            </button>
                            }
                          />;
                        }}
                      </QGroups>
                  }
                  {errorMessage.affectation && <ErrorMessage value={errorMessage.affectation} />}
                </div>
              </div>
              {/* <div className="f-messaging-discussion-title">{getMessageTitle(allDiscussions)}</div> */}
              <MessageList
                items={getAllMessages(getDiscussion).data}
                pagination={getAllMessages(getDiscussion).pagination}
                status={currentDiscussionStatus}
                getMemberNames={getMemberNames}
                getAdherentNames={getAdherentNames}
                subscriberId={subscriberId}
              />
              {
                R.equals(currentDiscussionStatus, discussionStatus.CLOSED) && isManager &&
                <div className="mt-4 flex justify-end">
                  <button onClick={handleOpenDiscussion} className="f-button-sm f-button-coral-reverse" type="button">
                    {'Ouvrir la demande'}
                  </button>
                </div>
              }
              <div className="flex justify-end">
                {errorMessage.openDiscussion && <ErrorMessage value={errorMessage.openDiscussion} />}
              </div>
              {
                R.equals(currentDiscussionStatus, discussionStatus.OPENED) && (
                  <ReplyTo
                    discussionId={discussionId}
                    title={getMessageTitle(getDiscussion)}
                    recipients={getRecipients(getDiscussion)}
                    subscriberId={subscriberId}
                    getAdherentNames={getAdherentNames}
                  />
                )
              }
            </>
          );
        }}
      </QDiscussion>
    </div>
  );
};

DiscussionFrame.propTypes = {
  discussionId: PropTypes.string,
  discussionTitle: PropTypes.func.isRequired,
  getMemberNames: PropTypes.func.isRequired,
  getAdherentNames: PropTypes.func.isRequired
};

export default DiscussionFrame;
