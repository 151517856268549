import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Loading } from '@@components';
import Field from '@@components/Field';
import { maritalStatusOptions } from '@@data';

const EditFamily = ({ maritalStatus, onChangeMaritalStatus }) => {
  if (R.isNil(maritalStatus)) return <Loading/>;

  const onClick = (event) => {
    onChangeMaritalStatus(event.target.value);
  };

  const optionToJSX = ({ value, label }) => {
    return (
      <label
        key={value}
        className={ R.equals(maritalStatus, value)
          ? 'f-button-toggle-selected'
          : 'f-button-toggle'}
      >
        <input
          type={'radio'}
          value={value}
          className={'appearance-none'}
          onClick={onClick}
        />
        <span>{label}</span>
      </label>
    );
  };

  return (
    <div className={'f-edit-adherent-subform-container'}>
      <Field label={'Situation'}>
        <div className={'f-button-toggle-grid'}>
          {R.map(optionToJSX, maritalStatusOptions)}
        </div>
      </Field>
    </div>
  );
};

EditFamily.propTypes = {
  maritalStatus: PropTypes.string,
  onChangeMaritalStatus: PropTypes.func
};

export default EditFamily;
