import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { BreadCrumbButtons } from '@@pages/adherent/components/BreadCrumb';
import {
  findActivity,
  findEchelon,
  getActivityRate, getHospitalName,
  getSpecialty,
  getSubscriberIdFromMatch,
  typologyEq
} from '@@pages/adherent/utils';
import useRelayEnvironment from 'react-relay/lib/relay-hooks/useRelayEnvironment';
import { useHistory } from 'react-router-dom';
import { useAdherent, useData } from '@@hooks';
import { SubThemeBlock } from '@@pages/adherent/components';
import { Loading } from '@@components';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import UpdateProfessionalSituationMutation from '@@mutations/UpdateProfessionalSituationMutation';
import { typologies } from '@@enum';
import {
  EditStatus,
  EditHospital,
  EditExercise,
  EditLiberal
} from '@@pages/adherent/components/editInfoPro';
import { filterActiveContracts } from '@@lib/contracts';

const TEXT_TITLE = 'Modification de la situation professionnelle';

const AdherentEditPro = ({
  pathConfig,
  match
}) => {
  const environment = useRelayEnvironment();
  const history = useHistory();
  const subscriberId = getSubscriberIdFromMatch(match);
  const { adherent } = useAdherent(subscriberId, [
    'professionalInformation',
    'contracts'
  ]);
  const activeContracts = R.compose(
    filterActiveContracts,
    R.propOr([], 'contracts')
  )(adherent);
  const { referential } = useData(['specialties']);
  const values = useRef({});
  const [currentSpecialty, setCurrentSpecialty] = useState('');
  const [isWorking, setIsWorking] = useState(false);
  const [loadedSpecialties, setLoadedSpecialties] = useState(false);
  const [loadedProInfo, setLoadedProInfo] = useState(false);
  const [loadedContracts, setLoadedContracts] = useState(false);

  const getMutatedData = () => ({ specialty: currentSpecialty });

  useEffect(() => {
    if (RA.isNotNil(R.prop('specialties', referential))) {
      setLoadedSpecialties(true);
    }
  }, [referential]);

  useEffect(() => {
    if (RA.isNotNil(adherent.professionalInformation)) {
      const specialty = getSpecialty(adherent.professionalInformation);
      const hospitalName = getHospitalName(adherent.professionalInformation);
      values.current = R.compose(
        R.assoc('hospitalName', hospitalName),
        R.assoc('specialty', specialty)
      )(values.current);
      setCurrentSpecialty(specialty);
      setLoadedProInfo(true);
    }
  }, [adherent.professionalInformation]);

  useEffect(() => {
    if (RA.isNotNil(activeContracts)) {
      const isPHHUTypology = typologyEq(typologies.PHHU, activeContracts);
      const isLiberalTypology = typologyEq(typologies.LIBERAL, activeContracts);
      const isInternTypology = typologyEq(typologies.INTERNE, activeContracts);
      const echelon = findEchelon(activeContracts);
      const activity = findActivity(activeContracts);
      const activityRate = getActivityRate(activeContracts);

      values.current = R.compose(
        R.assoc('isPHHUTypology', isPHHUTypology),
        R.assoc('isLiberalTypology', isLiberalTypology),
        R.assoc('isInternTypology', isInternTypology),
        R.assoc('echelon', echelon),
        R.assoc('activity', activity),
        R.assoc('activityRate', activityRate),
        R.assoc('liberal', isLiberalTypology)
      )(values.current);
      setLoadedContracts(true);
    }
  }, [activeContracts]);

  if (R.any(RA.isFalse)([loadedProInfo, loadedContracts, loadedSpecialties])) {
    return <Loading/>;
  }

  const professionalSubThemes = [{
    title: 'Statut',
    enabled: R.or(
      R.propOr(false, 'isPHHUTypology', values.current),
      R.propOr(false, 'isLiberalTypology', values.current)
    ),
    children: <EditStatus
      specialties={R.prop('specialties', referential)}
      specialty={currentSpecialty}
      onChangeSpecialty={setCurrentSpecialty}
      echelon={R.prop('echelon', values.current)}
      isPHHUTypology={R.prop('isPHHUTypology', values.current)} />
  }, {
    title: 'Établissement',
    enabled: R.propOr(false, 'isPHHUTypology', values.current),
    children: <EditHospital
      hospitalName={R.prop('hospitalName', values.current)}/>
  }, {
    title: 'Mode d\'exercice',
    enabled: R.or(
      R.propOr(false, 'isPHHUTypology', values.current),
      R.propOr(false, 'isInterneTypology', values.current)
    ),
    children: <EditExercise
      activity={R.prop('activity', values.current)}
      activityRate={R.prop('activityRate', values.current)}
      isPHHUTypology={R.prop('isPHHUTypology', values.current)} />
  }, {
    title: 'Activité Libérale',
    enabled: R.propOr(false, 'isPHHUTypology', values.current),
    children: <EditLiberal liberal={R.prop('liberal', values.current)}/>
  }];

  const subThemeToJSX = ({ title, enabled, children }) => enabled && (
    <SubThemeBlock key={title} title={title}>
      {children}
    </SubThemeBlock>
  );

  const onCancel = () => history.push(pathConfig.viewPath(subscriberId));

  const onSubmit = () => {
    setIsWorking(true);
    UpdateProfessionalSituationMutation(
      environment,
      {
        id: subscriberId,
        input: getMutatedData()
      },
      () => {
        setIsWorking(false);
        onCancel();
      }
    );
  };

  return (
    <div className={'f-edit-adherent-page'}>
      <div className={'f-breadcrumb-container'}>
        <BreadCrumbButtons elements={[
          { name: 'Adhérents', link: pathConfig.viewPath(subscriberId) },
          { name: TEXT_TITLE }
        ]}/>
      </div>
      <div className={'f-edit-adherent-page-title'}>
        {TEXT_TITLE}
      </div>
      <div className={'f-edit-adherent-form-container'}>
        { R.map(subThemeToJSX, professionalSubThemes) }
      </div>
      <div className={'f-edit-adherent-buttons'}>
        <button className={'f-button f-button-grey'} onClick={onCancel}>
          {'Annuler'}
        </button>
        <button className={'f-button f-button-coral'} onClick={onSubmit}
          disabled={isWorking}>
          {'Enregistrer'}
        </button>
      </div>
    </div>
  );
};

AdherentEditPro.propTypes = {
  pathConfig: PropTypes.object.isRequired,
  match: PropTypes.shape({
    isExact: PropTypes.bool.isRequired,
    params: PropTypes.shape({
      subscriberId: PropTypes.string
    }).isRequired,
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }).isRequired
};

export default AdherentEditPro;
