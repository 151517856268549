import React from 'react';
import PropTypes from 'prop-types';

const Family = ({
  familyStatus
}) => {
  return <>
    {familyStatus}
  </>;
};

Family.propTypes = {
  familyStatus: PropTypes.string.isRequired
};

export default Family;
