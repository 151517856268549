import React from 'react';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { Loading } from '@@components';
import Field from '@@components/Field';

const FIELDS = [{
  name: 'N° et voie',
  label: 'address1'
}, {
  name: 'Complément d\'adresse 1',
  label: 'address2'
}, {
  name: 'Complément d\'adresse 2',
  label: 'address3'
}, {
  name: 'Code postal',
  label: 'zipCode'
}, {
  name: 'Ville',
  label: 'city'
}, {
  name: 'Pays',
  label: 'country'
}];

const EditAddress = ({ address, onChangeAddress }) => {
  if (R.isNil(address)) return <Loading/>;

  const onChange = label => event => {
    const newAddress = R.assoc(label, event.target.value, address);
    onChangeAddress(newAddress);
  };

  const fieldToJSX = (field, index) => (
    <React.Fragment key={index}>
      <Field label={field.name}>
        <input
          className={'f-input f-edit-adherent-text-input'}
          value={R.propOr('', field.label, address)}
          onChange={onChange(field.label)}
        />
      </Field>
    </React.Fragment>
  );

  return (
    <div className={'f-edit-adherent-subform-container'}>
      {RA.mapIndexed(fieldToJSX, FIELDS)}
    </div>
  );
};

export default EditAddress;
