import React from 'react';
import PropTypes from 'prop-types';
import { Loading } from '@@components';
import * as R from 'ramda';

const Specialty = ({
  professionalInformation
}) => {
  return <>
    {
      professionalInformation
        ? <div>
          {`${R.prop('specialty', professionalInformation)}`}
        </div>
        : <Loading />
    }
  </>;
};

Specialty.propTypes = {
  professionalInformation: PropTypes.shape({
    specialty: PropTypes.string.isRequired
  })
};

export default Specialty;
