/**
*
* WARNING : fichier généré par forms, ne pas le modifier directement
*
**/



const formData = [
  
  {
    name: 'service',
    required: true,
    input: 'select',
    source: 'service',
    label: "__UNDEFINED__",
    placeholder: "Sélectionnez votre service...",
    
    handlers: {
      visible: undefined
    }
  },
  
  {
    name: 'discussionId',
    required: true,
    input: 'hidden',
    source: '__UNDEFINED__',
    label: "__UNDEFINED__",
    placeholder: "__UNDEFINED__",
    
    handlers: {
      visible: undefined
    }
  },
  
];

export default formData;