import { graphql } from 'react-relay';

const QCmsContactQuery = graphql`
  query QCmsContactQuery {
    cms {
      contact {
        appaPhoneNumber
      }
    }
  }
`;

export {
  QCmsContactQuery
};
