/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QBackOfficeMessageThemesQueryVariables = {||};
export type QBackOfficeMessageThemesQueryResponse = {|
  +references: ?{|
    +messageThemesBackOffice: ?$ReadOnlyArray<?{|
      +id: ?string,
      +title: ?string,
      +actionId: ?string,
      +isFilterable: ?boolean,
      +subThemes: ?$ReadOnlyArray<?{|
        +id: ?string,
        +actionId: ?string,
        +title: ?string,
        +isFilterable: ?boolean,
      |}>,
    |}>
  |}
|};
export type QBackOfficeMessageThemesQuery = {|
  variables: QBackOfficeMessageThemesQueryVariables,
  response: QBackOfficeMessageThemesQueryResponse,
|};
*/


/*
query QBackOfficeMessageThemesQuery {
  references {
    messageThemesBackOffice {
      id
      title
      actionId
      isFilterable
      subThemes {
        id
        actionId
        title
        isFilterable
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actionId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isFilterable",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "References",
    "kind": "LinkedField",
    "name": "references",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MessageThemeReferences",
        "kind": "LinkedField",
        "name": "messageThemesBackOffice",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MessageSubThemeReferences",
            "kind": "LinkedField",
            "name": "subThemes",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v2/*: any*/),
              (v1/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QBackOfficeMessageThemesQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QBackOfficeMessageThemesQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "858323bbebc01af2a4b108b2cfd60967",
    "id": null,
    "metadata": {},
    "name": "QBackOfficeMessageThemesQuery",
    "operationKind": "query",
    "text": "query QBackOfficeMessageThemesQuery {\n  references {\n    messageThemesBackOffice {\n      id\n      title\n      actionId\n      isFilterable\n      subThemes {\n        id\n        actionId\n        title\n        isFilterable\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a38e540d7b3f25a3a3bfb23b77e15998';

module.exports = node;
